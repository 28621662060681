import './SaleInfo.css';
import arrow_icon from "../../imgs/curved_arrow.png"
import React, { useEffect } from 'react';
import translations from "./translations.js";

export default function SaleInfo({ currentLanguage }) {

  // Translation object with language-specific text


// Function to translate text based on the current language
const translateText = (textKey) => {
  // Split the textKey by '.' to access nested properties
  const keys = textKey.split('.');
  
  // Get the translated text based on the current language
  let translatedText = translations[currentLanguage];
  for (const key of keys) {
      translatedText = translatedText[key];
  }

  return translatedText;
};

// Scroll to the top when this component mounts
useEffect(() => {
  window.scrollTo(0, 0);
}, []);
    
return (
  <div>
      <h2 className="header-info">{translateText("SaleInfoHeader")}</h2>
      <div className="sale-points-container">
        <p className="start-info">  
          {translateText("StartInfo")}          
        </p>
      </div>
      <h4 className="sub-header">
        {translateText("HowItWorks")}
      </h4>
      <div className="sale-points-container">
        <div className="sale-points">
          <p className="sale-point">  
          <h4 className="sale-point-title">{translateText("HowItWorksInfo1.Title")}</h4> 
            {translateText("HowItWorksInfo1.Description")}
          </p>
            <img src={arrow_icon} className="arrow-icon-right" alt="Arrow icon" />
          <p className="sale-point">
            <h4 className="sale-point-title">{translateText("HowItWorksInfo2.Title")}</h4> 
            {translateText("HowItWorksInfo2.Description")}
          </p> 
            <img src={arrow_icon} className="arrow-icon-left" alt="Arrow icon" />
          <p className="sale-point">
            <h4 className="sale-point-title">{translateText("HowItWorksInfo3.Title")}</h4> 
            {translateText("HowItWorksInfo3.Description")}
          </p>
            <img src={arrow_icon} className="arrow-icon-right" alt="Arrow icon" />
          <p className="sale-point">
            <h4 className="sale-point-title">{translateText("HowItWorksInfo4.Title")}</h4>
            {translateText("HowItWorksInfo4.Description")}
          </p>
            <img src={arrow_icon} className="arrow-icon-left" alt="Arrow icon" />
          <p className="sale-point">
            <h4 className="sale-point-title">{translateText("HowItWorksInfo5.Title")}</h4>
            {translateText("HowItWorksInfo5.Description")}
          </p>
            <img src={arrow_icon} className="arrow-icon-right" alt="Arrow icon" />
          <p className="sale-point">
            <h4 className="sale-point-title">{translateText("HowItWorksInfo6.Title")}</h4> 
            {translateText("HowItWorksInfo6.Description")}
          </p>
            <img src={arrow_icon} className="arrow-icon-left" alt="Arrow icon" />
          <p className="sale-point">
            <h4 className="sale-point-title">{translateText("HowItWorksInfo7.Title")}</h4> 
            {translateText("HowItWorksInfo7.Description")}
          </p>
        </div>
      </div>
      <div className="sale-points-container">
        <p className="start-info">
        {translateText("AdditionalInfo")}
        </p>
      </div>
      <p className="call-to-action">
      {translateText("CallToAction")}
      </p>
      <div className="sale-points-container">
        <p className="start-info">
        {translateText("CallToActionInfo")}
        </p>
      </div> 
  </div>
);
};
