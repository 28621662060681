import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Annonser.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import click_icon from '../../imgs/cursor.png';
import fav_icon from '../../imgs/star.png';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

import getWeeksSince from "./getWeeksSince";

const Annonser = ({ currentLanguage, objects, firebaseConfig }) => {
  const location = useLocation();
  const passedObjId = location.state?.ObjId || '';
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to toggle modal visibility
  const handleToggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const [filters, setFilters] = useState({
    active: true,
    soldNotDelivered: true,
    soldDelivered: true,
    expired: true,
    inactive: true,
    searchQuery: passedObjId
  });

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [price, setPrice] = useState("");
  const [eu, setEu] = useState([]);
  const [regData, setRegData] = useState([]);
  const [målVekt, setMålVekt] = useState([]);
  const [utils, setUtils] = useState([]);

  // Handle changes for title, text, and price
  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleTextChange = (e) => setText(e.target.value);
  const handlePriceChange = (e) => setPrice(e.target.value);
  const handleEuChange = (e) => setEu(e.target.value.split('\n'));
  const handleRegDataChange = (e) => setRegData(e.target.value.split('\n'));
  const handleMålVektChange = (e) => setMålVekt(e.target.value.split('\n'));
  const handleUtilsChange = (e) => setUtils(e.target.value.split('\n'));

  useEffect(() => {
    if (location.state?.ObjId) {
      setFilters(prevFilters => ({
        ...prevFilters,
        searchQuery: location.state.ObjId  // Update searchQuery with ObjId
      }));
    }
  }, [location.state]);

  const [objectsDataAdmin, setObjectsDataAdmin] = useState({ Objects: [] });
  const [loading, setLoading] = useState(true);
  const [commentText, setCommentText] = useState('');
  const [imageUrls, setImageUrls] = useState([]);

  const [statusDropdown, setStatusDropdown] = useState({});
  const [infoDropdown, setInfoDropdown] = useState({});

  const statusOptions = ["active", "sold (Not Delivered)", "sold (Delivered)", "expired", "inactive"];

  const [clickFavData, setClickFavData] = useState({});

  useEffect(() => {
    const fetchAllClickFavData = async () => {
      if (objectsDataAdmin.Objects.length > 0) {
        const objIds = objectsDataAdmin.Objects.map(obj => obj.objId);
        const clickFavRefs = objIds.map(id => firebase.firestore().collection('clickFav').doc(id));
        
        try {
          // Fetch all documents in parallel
          const docs = await Promise.all(clickFavRefs.map(ref => ref.get()));
          const data = {};
          
          docs.forEach(doc => {
            if (doc.exists) {
              data[doc.id] = doc.data();
            } else {
              data[doc.id] = { click: 0, fav: 0 };
            }
          });
          
          setClickFavData(data);
        } catch (error) {
          console.error('Error fetching click/fav data:', error);
        }
      }
    };

    fetchAllClickFavData();
  }, [objectsDataAdmin.Objects]);

  const handleSubmitEditAdd = async (obj) => {
    try {
      const db = firebase.firestore();
      // Access the Firestore collection and document
      const docRef = db.collection('saleObjects').doc(obj.ObjId);
  
      // Update the document with the new values
      await docRef.update({
        title: title,
        text: text,
        price: price,
        eu: eu,               
        regData: regData,     
        målVekt: målVekt,     
        utils: utils 
      });
  
      console.log('Document successfully updated!');
      // Optionally, you can close the modal or reset the state here
      handleToggleModal(); // Assuming this function closes the modal
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const handleAddComment = async (ObjId, commentText) => {
    if (commentText.trim() === '') {
      // Don't add empty comments
      return;
    }

    try {
      const db = firebase.firestore();
      const docRef = db.collection('saleObjectsAdmin').doc(ObjId);

      // Get the existing data
      const doc = await docRef.get();

      if (doc.exists) {
        // Create a Firestore timestamp
        const currentDateTime = new Date();
        const day = currentDateTime.getDate().toString().padStart(2, '0');
        const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = currentDateTime.getFullYear();
        const hours = currentDateTime.getHours().toString().padStart(2, '0');
        const minutes = currentDateTime.getMinutes().toString().padStart(2, '0');

        const timestamp = `${day}/${month}/${year} ${hours}:${minutes}`;

        // Update the Comments array with the new timestamp
        const updatedComments = [
          ...doc.data().Comments,
          `${timestamp}`,
        ];

        // Update the Comments array with the new comment
        updatedComments.push(commentText);

        // Update the document
        await docRef.update({ Comments: updatedComments });

        // Clear the textarea
        setCommentText('');
      } else {
        console.error(`Document with ID ${ObjId} does not exist.`);
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  useEffect(() => {
    // Initialize Firebase with your Firebase configuration
    firebase.initializeApp(firebaseConfig); // Replace firebaseConfig with your Firebase configuration
    const db = firebase.firestore();

    const fetchObjectsDataAdmin = async () => {
      try {
        const objectsDataAdminRef = db.collection('saleObjectsAdmin');
        const snapshot = await objectsDataAdminRef.get();
  
        const objects = snapshot.docs.map((doc) => {
          const data = doc.data();
          const objId = doc.id;
          return { objId, ...data };
        });
  
        //console.log("objects:", objects);
        setObjectsDataAdmin({ Objects: objects });
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching objects data:', error.message);
        setLoading(false);
      }
    };

    // Call the fetchObjectsDataAdmin function
    fetchObjectsDataAdmin();
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storage = getStorage();
        const rootRef = ref(storage, '/'); // Reference to the root directory

        // Step 1: List all folders in the root directory
        const folderList = await listAll(rootRef);

        const promises = folderList.prefixes.map(async (folderRef) => {
          // Step 2: List all files in each folder
          const files = await listAll(folderRef);

          // Filter for the first image named "<folderNumber>_1.jpeg"
          const firstImage = files.items[0];

          if (firstImage) {
            const imageUrl = await getDownloadURL(firstImage);
            return { folder: folderRef.name, url: imageUrl };
          }
          return null;
        });

        const results = await Promise.all(promises);
        // Convert results to an object with folder names as keys
        const imageUrlObject = results.reduce((acc, result) => {
          if (result) {
            acc[result.folder] = result.url;
          }
          return acc;
        }, {});

        setImageUrls(imageUrlObject);
      
      }catch (error) {
        console.error('Error fetching images from Firebase Storage:', error);
      }
    };
      fetchImages();
    }, []);

  const handleShowMoreInfo = (obj) => {
    setPrice(obj.price)
    setTitle(obj.title)
    setText(obj.text)
    setEu([]);
    if (obj.eu) {
      setEu(obj.eu);
    }
    setRegData([]);
    if (obj.regData) {
      setRegData(obj.regData);
    }
    setMålVekt([]);
    if (obj.målVekt) {
      setMålVekt(obj.målVekt);
    }
    setUtils([]);
    if (obj.utils) {
      setUtils(obj.utils);
    }

    setInfoDropdown((prevState) => ({
      ...prevState,
      [obj.ObjId]: !prevState[obj.ObjId] || false,
    }));
  };

  // Function to toggle the status dropdown for a specific object
  const toggleStatusDropdown = (objId) => {
    setStatusDropdown((prevState) => ({
      ...prevState,
      [objId]: !prevState[objId] || false,
    }));
  };

  const handleStatusOptionClick = async (option, objId) => {
    try {
      const db = firebase.firestore();
      // Reference to the specific document in the Firestore collection
      const objectRef = db.collection('saleObjects').doc(objId);
  
      // Update the status field with the selected option
      await objectRef.update({
        status: option,
      });
  
      console.log(`Selected status ${option} for object with ID ${objId}`);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const toggleFilter = (filter) => {
    setFilters({
      ...filters,
      [filter]: !filters[filter],
    });
  };

  const filteredObjects = objects.Objects.filter((obj) => {
    if (filters.searchQuery) {
      // If search query is provided, filter based on ObjId
      return obj.ObjId.includes(filters.searchQuery);
    } else {
      // If search query is empty, apply other filters
      if (filters.active && obj.status === 'active') return true;
      if (filters.soldNotDelivered && obj.status === 'sold (Not Delivered)') return true;
      if (filters.soldDelivered && obj.status === 'sold (Delivered)') return true;
      if (filters.expired && obj.status === 'expired') return true;
      if (filters.inactive && obj.status === 'inactive') return true;
      return false;
    }
  });

  return (
    <div>
      <h1 className="header">Alle annonser</h1>
      <p className="test-epost">TEST EPOST</p>
      <div className="main-container-ads">
        <div className="filter-container-ads">
          <p>Filter meny</p>
          <div>
            <input
              type="text"
              placeholder="søk på refNr."
              value={filters.searchQuery}
              onChange={(e) => {
                const { value } = e.target;
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  searchQuery: value,
                }));
              }}
            />
          </div>
          <label>
            <input
              type="checkbox"
              checked={filters.active}
              onChange={() => toggleFilter('active')}
            />
            Active
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.soldNotDelivered}
              onChange={() => toggleFilter('soldNotDelivered')}
            />
            Sold (Not Delivered)
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.soldDelivered}
              onChange={() => toggleFilter('soldDelivered')}
            />
            Sold (Delivered)
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.expired}
              onChange={() => toggleFilter('expired')}
            />
            Expired
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.inactive}
              onChange={() => toggleFilter('inactive')}
            />
            Inactive
          </label>
        </div>
        <div className="ads-container">
          <p>Antall treff: {filteredObjects.length}</p>
          {filteredObjects.slice().reverse().map((obj) => (
            <div
            key={obj.ObjId}
            style={{ backgroundColor: getWeeksSince(obj.created) > 30 ? 'rgb(200,80,80)' : '' }}
            className={"object-div"}
            >
              {/* Render the object properties here */}
              <div className="ad-header-container">
              <img src={imageUrls[obj.ObjId]} alt={`Image for ${obj.ObjId}`} />
                <h4 className="object-title" >{obj.title} [{obj.ObjId}]</h4>
              </div>
              <div className="object-info-div">
                <p>{obj.price} NOK</p>
                <p>{obj.county}</p>
                <p>Lagt ut: {obj.created} ({getWeeksSince(obj.created)} uker)</p>
                <p onClick={() => toggleStatusDropdown(obj.ObjId)} className="object-status">Status: {obj.status}</p>
                {statusDropdown[obj.ObjId] && (
                  <div className="status-options">
                    {statusOptions.map((option) => (
                      <p
                        className="status-option"
                        key={option}
                        onClick={() => handleStatusOptionClick(option, obj.ObjId)}
                      >
                        {option}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <p onClick={() => handleShowMoreInfo(obj)} className="more-info-btn">
                  More Info
                </p>
                {infoDropdown[obj.ObjId] && (
                  <div>
                    {objectsDataAdmin.Objects
                      .filter(adminObj => adminObj.objId === obj.ObjId)
                      .map( AdminObj => (
                        <div className="status-info">
                          <div className="generell-info">
                            <h4>{AdminObj.Seller}</h4>
                            <p>OrgNr: {AdminObj.OrgNr}</p>
                            <p>Tlf: {AdminObj.Phone}</p>
                            <p>Addr: {AdminObj.Address}, {obj.place}</p>
                            {AdminObj.RegNr && <p>Reg Nr: {AdminObj.RegNr}</p>}
                            <div className="click-fav-info-container">
                              <div className="click-fav-info">
                                <img src={click_icon}/>
                                <p>{clickFavData[AdminObj.objId]?.click || 0}</p>
                              </div>
                              <div className="click-fav-info">
                                <img src={fav_icon}/>
                                <p>{clickFavData[AdminObj.objId]?.fav || 0}</p>
                              </div>
                            </div>
                            
                            <p>RefNr: {obj.ObjId}</p>
                            <p>{obj.RegNr}</p>
                            <Link to={`/for-sale/${obj.ObjId}`}>Til Annonse</Link>
                            <div className="comment-container">
                              <textarea
                                rows="4"
                                cols="40"
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                              ></textarea>
                              <p
                                className="more-info-btn"
                                onClick={() => handleAddComment(obj.ObjId, commentText)}
                              >
                                Legg inn kommentar
                              </p>
                            </div>
                            <div>
                              <p className="edit-add-btn" onClick={handleToggleModal}>Endre Annonse</p>
                              {isModalOpen && (
                                <div className="modal-annonser">
                                  <div>
                                    <h4>Tittel: </h4>
                                    <input
                                      type="text"
                                      value={title}
                                      onChange={handleTitleChange}
                                    />
                                  </div>
                                  <div>
                                    <h4>Tekst: </h4>
                                    <textarea
                                      value={text}
                                      rows="10"
                                      cols="40"
                                      onChange={handleTextChange}
                                    />
                                  </div>
                                  <div>
                                    <h4>Pris: </h4>
                                    <input
                                      type="text"
                                      value={price}
                                      onChange={handlePriceChange}
                                    />
                                  </div>
                                  <div>
                                    <h4>Eu: </h4>
                                    <textarea
                                      value={eu.join('\n')}
                                      rows="10"
                                      cols="40"
                                      onChange={handleEuChange}
                                    />
                                  </div>
                                  <div>
                                    <h4>Reg. Data: </h4>
                                    <textarea
                                      value={regData.join('\n')}
                                      rows="10"
                                      cols="40"
                                      onChange={handleRegDataChange}
                                    />
                                  </div>
                                  <div>
                                    <h4>Mål/Vekt: </h4>
                                    <textarea
                                      value={målVekt.join('\n')}
                                      rows="10"
                                      cols="40"
                                      onChange={handleMålVektChange}
                                    />
                                  </div>
                                  <div>
                                    <h4>Tags: </h4>
                                    <textarea
                                      value={utils.join('\n')}
                                      rows="10"
                                      cols="40"
                                      onChange={handleUtilsChange}
                                    />
                                  </div>
                                  
                                  
                                  <button onClick={() => handleSubmitEditAdd(obj)} className="save-edit-btn">Lagre</button>
                                  <button onClick={handleToggleModal}>Lukk</button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="konfidensiell-info">
                            <p className="konfideensiell-info-titel">Konfidensielle kommentarer</p>
                                <div>
                                <p>
                                  {Array.isArray(AdminObj.Comments)
                                    ? AdminObj.Comments.map((item, index) => {
                                        if (index % 2 === 0) {
                                          // Even index, it's a timestamp
                                          return <p className="comment-time" key={`${AdminObj}-timestamp-${index}`}>{item}</p>;
                                        } else {
                                          // Odd index, it's a comment
                                          return <p className="comment-info" key={`${AdminObj}-comment-${index}`}>{item}</p>;
                                        }
                                      })
                                    : AdminObj.Comments}
                                </p>
                                </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Annonser;
