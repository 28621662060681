import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const SearchForm = ({ translateText, objects }) => {
  const [searchInput, setSearchInput] = useState('');
  const [filteredObjects, setFilteredObjects] = useState([]);
  const navigate = useNavigate();

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Navigate to "/ForSale" and pass the filteredObjects as state
    navigate('/for-sale', { state: { searchInput } });
  };

  // state = {{ type: object.type }} 

  const handleSearchInputChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchInput(searchText);
    if (searchText === '') {
      setFilteredObjects([]);
    } else {
      const filtered = objects.Objects.filter(
        (obj) =>
          obj.title &&
          obj.title.toLowerCase().includes(searchText) &&
          obj.status &&
          obj.status.toLowerCase() === 'active'
      );
      setFilteredObjects(filtered);
    }
  };

  return (
    <div>
      <h4>{translateText('headtext')}</h4>
      <form onSubmit={handleSearchSubmit}>
        <label>
          <input
            autoComplete="off"
            type="text"
            name="search"
            className="search-field"
            placeholder={translateText('searchPlaceholder')}
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </label>
        {/* 
        <input
          type="submit"
          className="search-submit"
          value={translateText('search')}
        />
        */}
        <Link
          to="../for-sale"
          className="search-submit"
          state = {{ searchInput: searchInput }} 
        >
          {translateText('search')}
        </Link>
      </form>

      {filteredObjects.length > 0 && (
        <div className="search-box-suggestions">
          {filteredObjects.slice(0, 3).map((obj) => (
            <Link
              className="search-box-link"
              to={`/for-sale/${obj.ObjId}`}
              key={obj.ObjId}
            >
              <div className="search-box-suggestion">
                <p>{obj.title}</p>
                <p className="search-box-suggestion-county">{obj.county}</p>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchForm;