import React, { useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import './Settings.css';

import {useNavigate } from 'react-router-dom';

import translations from "./translations";

  const Settings = () => {
    const [originalUserData, setOriginalUserData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [editing, setEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState (false);
    const [showOkToast, setshowOkToast] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      const user = firebase.auth().currentUser;
      if (user) {
        const userUID = user.uid;
        const userRef = firebase.firestore().collection('users').doc(userUID);
  
        userRef.get().then((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            setUserData(userData);
            setOriginalUserData(userData);
          }
        });
      }
    }, []);

    const handleEditButtonClick = () => {
      setEditing(true);
    }

    const handleDeleteButtonClick = () => {
      setShowDeleteModal(true);
    }

    const handleBackButtonClick = () => {
      setUserData(originalUserData)
      setEditing(false);
    }

    const handleDeleteModalAbort = () => {
      setShowDeleteModal(false);
    }

    const checkValidInputData = () => {
      // If privatperson is not checked, validate Org.Nr
      if (userData.Privatperson !== "privatperson") {
        if (userData.OrgNr.length !== 9 || !/^\d+$/.test(userData.OrgNr)) {
          alert("Org.Nr must be 9 digits.");
          return false;
        }
      }
      return true; // Validation passed
    }

    const handleUserEdit = async () => {
      if (checkValidInputData()) {
        try {
          // Update data in Firebase
          const user = firebase.auth().currentUser;
          if (user) {
            const userUID = user.uid;
            const userRef = firebase.firestore().collection('users').doc(userUID);
      
            await userRef.update(userData);
            console.log("Data updated successfully.");
            showOkAlert();
          }
        } catch (error) {
          console.error("Error updating data:", error);
        }
      }
    }

    const handleDeleteUser = async () => {
      try {
        const user = firebase.auth().currentUser;
        if (user) {
          const userUID = user.uid;
          const userRef = firebase.firestore().collection('users').doc(userUID);
    
          // Delete the user document from Firebase
          await userRef.delete();
          console.log("User deleted successfully.");
          
          // Sign out the user after deletion
          await firebase.auth().signOut();
          
          navigate('/');
          window.location.reload();
        }
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
    

    const showOkAlert = () => {
      setshowOkToast(true);
      setTimeout(() => {
        setshowOkToast(false);
      }, 1000); // Adjust the duration as needed (in milliseconds)
    };

    const toastStyle = {
      position: 'fixed',
      top: '90%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#4CAF50',
      color: 'white',
      padding: '15px',
      borderRadius: '5px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    };
  
  
    return (
        <div>
          <h1 className="header">Rediger bruker</h1>
          {showOkToast && (
            <div style={toastStyle}>
              <p>Bruker oppdatert </p>
            </div>
          )}
          {editing ? (
            <div className="user-settings">
              <div className="user-settings-edit">
                <p>Navn</p>
                <input type="text" value={userData.Name} onChange={(e) => console.log(e.target.value)} />
              </div>
              <div className="user-settings-edit">
                <p>E-post</p>
                <input type="text" value={userData.email} onChange={(e) => console.log(e.target.value)} />
              </div>
              <label>
                <input
                  type="checkbox"
                  checked={userData.Privatperson === "privatperson"}
                  onChange={(e) => setUserData({ ...userData, Privatperson: e.target.checked ? "privatperson" : "" })}
                  />
                Privatperson
              </label>
              <div className="user-settings-edit">
                <p>Org.Nr</p>
                <input 
                  type="text" 
                  value={userData.OrgNr} 
                  placeholder = "123456789"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) || value === "") { // Only allow numbers or empty string
                      setUserData({ ...userData, OrgNr: value });
                    }
                  }}
                  disabled={userData.Privatperson === "privatperson"}
                />
              </div>
              <div className="user-settings-edit">
                <p>Rolle</p>
                <input 
                  type="text" 
                  value={userData.Role}
                  placeholder="f.eks Daglig Leder" 
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value) || value === "") { // Only allow letters or empty string
                      setUserData({ ...userData, Role: value });
                    }
                  }}
                  disabled={userData.Privatperson === "privatperson"}
                />
              </div>
              <div className="user-settings-edit-buttons">
                <button className="edit-settings-btn" onClick = {handleUserEdit}>Lagre</button>
                <button className="delete-settings-btn" onClick={handleBackButtonClick}>Tilbake</button>
              </div>
            </div>
          ) : (
            <>
              
              {userData && (
                <div className="user-settings">
                  <p><span>Navn</span> {userData.Name}</p>
                  <p><span>E-post</span>  {userData.email}</p>
                  {userData.Privatperson === "privatperson" ? (
                    <h4 className="user-org-setting-title">Jeg selger som privatperson</h4>
                  ) : (
                    <div className="user-settings-organisasjon">
                      <h4 className="user-org-setting-title">Tilknyttet bedrift</h4>
                      <p><span>Org.Nr</span>  {userData.OrgNr}</p>
                      <p><span>Rolle</span> {userData.Role}</p>
                    </div>
                  )}
                </div>
              )}
              
            </>
          )}
          <button className="edit-settings-btn" onClick={handleEditButtonClick}>Endre</button>
          <button className="delete-settings-btn" onClick={handleDeleteButtonClick}>Slett bruker</button>
          {showDeleteModal && (
            <div className="delete-modal">
              <p>Er du sikker på at du vil slette denne brukeren?</p>
              <button onClick={handleDeleteUser}>Slett</button>
              <button onClick={handleDeleteModalAbort}>Avbryt</button>
            </div>
          )}
        </div>
      );
    };
  
  export default Settings;