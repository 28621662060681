export const checkIfFavorites = (ObjId, favorites) => {
    if (favorites.includes(ObjId)) {
      return true;
    } else {
      return false;
    }
  };

  export const handleFavoriteToggle = async (objId, firebase, setFavorites) => {
    // Retrieve loggedInUser UID from local storage
    const loggedInUserId = localStorage.getItem('loggedInId');
  
    if (loggedInUserId) {
      console.log(loggedInUserId);
      const userFavoritesRef = firebase.firestore().collection('users').doc(loggedInUserId);
      const clickFavRef = firebase.firestore().collection('clickFav').doc(objId);
      // Get the user's favorites
      const userDoc = await userFavoritesRef.get();
      let favorites = [];

      if (userDoc.exists) {
        const userData = userDoc.data();
        favorites = userData.favorites || [];
      }

      let isFavorite = favorites.includes(objId);
  
      try {
        
  
        // Toggle the favorite state
        if (isFavorite) {
          favorites = favorites.filter(favorite => favorite !== objId); // Remove the objId from favorites array
          await clickFavRef.update({
            fav: firebase.firestore.FieldValue.increment(-1)
          }); // Decrement the fav count in clickFav collection
        } else {
          favorites.push(objId); // Add the objId to favorites array
          await clickFavRef.update({
            fav: firebase.firestore.FieldValue.increment(1)
          }); // Increment the fav count in clickFav collection
        }
  
        // Update the favorites in Firestore
        await userFavoritesRef.update({ favorites });
  
        // Update the favorites state in your component
        setFavorites(favorites);
  
      } catch (error) {
        if (error.code === 'not-found') {
          
          await userFavoritesRef.update({ favorites });
          setFavorites(favorites);
  
          // Also initialize the fav count for this objId if it doesn't exist
          await clickFavRef.set({ fav: 1, click: 0 }); // Start with fav = 1 since this is a new favorite
        } else {
          console.log('Error toggling favorite:', error);
        }
      }
    } else {
      // Redirect the user to "/LogIn" if the loggedInUserId is not found
      window.location.href = "/LogIn";
    }
  };
  