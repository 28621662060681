const toastStyle = {
    position: 'fixed',
    top: '90%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#4CAF50',
    color: 'white',
    padding: '15px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  };

export default toastStyle;