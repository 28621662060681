const getFylkeFromKommunenummer = (number) => {
    let fylke = '';
    
    // Extract the first two digits from the number
    if (number === undefined){
      number = "0000";
    }
    if(number == "Unknown"){
      number = "0000";
    }
    const prefix = number.substring(0, 2);
    // Use a switch statement to map cases
    switch (prefix) {
      case "30":
        fylke = 'Viken';
        break;
      case "31":
        fylke = 'Østfold';
        break;
      case "32":
        fylke = 'Akershus';
        break;
      case "33":
        fylke = 'Buskerud';
        break;
      case "34":
        fylke = 'Innlandet';
        break;
      case "38":
        fylke = 'Vestfold og Telemark';
        break;
      case "39":
        fylke = 'Vestfold';
        break;
      case "40":
        fylke = 'Telemark';
        break;
      case "41":
        fylke = 'Agder';
        break;
      case "46":
        fylke = 'Vestland';
        break;
      case "50":
        fylke = 'Trøndelag';
        break;
      case "54":
        fylke = 'Troms og Finnmark';
        break;
      case "55":
        fylke = 'Troms';
        break;
      case "56":
        fylke = 'Finnmark';
        break;
      case "18":
        fylke = 'Nordland';
        break;
      case "15":
        fylke = 'Møre og Romsdal';
        break;
      case "11":
        fylke = 'Rogaland';
        break;
      case "03":
        fylke = 'Oslo';
        break;
      case "00":
        fylke = "unknown"
        break;
      default:
        // Handle cases not explicitly listed
        break;
    }
    return fylke;
  };

  export default getFylkeFromKommunenummer;