import React, { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import './NewUser.css';
import complianceText_EN from './Compliance/compliance_EN.js';
import complianceText_NO from './Compliance/compliance_NO.js';
import complianceText_GR from './Compliance/compliance_GR.js';
import complianceText_UK from './Compliance/compliance_UK.js';
import complianceText_PL from './Compliance/compliance_PL.js';

export default function NewUser({ currentLanguage, firebaseConfig }) {
  const [email, setEmail] = useState('');
  const [orgnr, setOrgnr] = useState('');
  const [role, setRole] = useState("");
  const [privatperson, setPrivatperson] = useState("");
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [privatCheck, setPrivatCheck] = useState(false);

  const [showCompliance, setShowCompliance] = useState(false);
  const [compliance, setCompliance] = useState('');

  const navigate = useNavigate();

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  // Translation object with language-specific text
  const translations = {
    no: {
      CreateNewUser: 'Lag ny bruker',
      info: "Fyll ut skjemaet for å opprette en ny bruker. Du vil motta en bekreftelses E-post.",
      name: "Navn",
      email: "E-post",
      privat: "Jeg er ikke tilknyttet et firma ",
      accept: "Jeg godtar at denne informasjonen lagres",
      close: "Lukk",
      NoMatch: "Passordene er ikke like",
      Org: "Org.nr",
      Role: "Rolle",
      Pwd: "Passord",
      ConfirmPwd: "Bekreft Passord",
      NewUser: "Lag bruker",
      Terms: "Vilkår"
    },
    en: {
      CreateNewUser: 'Create new user',
      info: "Fill out the form to create a new user. You will receive a confirmation email.",
      name: "Name",
      email: "Email",
      privat: "I am not affiliated with a company ",
      accept: "I accept that this information is stored",
      close: "Close",
      NoMatch: "Passwords do not match",
      Org: "Org. no",
      Role: "Role",
      Pwd: "Password",
      ConfirmPwd: "Confirm Password",
      NewUser: "Create User",
      Terms: "Terms"
    },
    pl: {
      CreateNewUser: 'Utwórz nowego użytkownika',
      info: "Wypełnij formularz, aby utworzyć nowego użytkownika. Otrzymasz potwierdzenie drogą mailową.",
      name: "Imię",
      email: "E-mail",
      privat: "Nie jestem związany z firmą ",
      accept: "Akceptuję przechowywanie tych informacji",
      close: "Zamknij",
      NoMatch: "Hasła nie pasują do siebie",
      Org: "Nr. org.",
      Role: "Rola",
      Pwd: "Hasło",
      ConfirmPwd: "Potwierdź hasło",
      NewUser: "Utwórz użytkownika",
      Terms: "Warunki"
    },
    uk: {
      CreateNewUser: 'Створити нового користувача',
      info: "Заповніть форму, щоб створити нового користувача. Ви отримаєте підтверджувальний лист.",
      name: "Ім'я",
      email: "Електронна пошта",
      privat: "Я не пов'язаний з компанією ",
      accept: "Я згоден на збереження цієї інформації",
      close: "Закрити",
      NoMatch: "Паролі не співпадають",
      Org: "Орг. номер",
      Role: "Роль",
      Pwd: "Пароль",
      ConfirmPwd: "Підтвердити пароль",
      NewUser: "Створити користувача",
      Terms: "Умови"
    },
    gr: {
      CreateNewUser: 'Benutzer erstellen',
      info: "Füllen Sie das Formular aus, um einen neuen Benutzer zu erstellen. Sie erhalten eine Bestätigungs-E-Mail.",
      name: "Name",
      email: "E-Mail",
      privat: "Ich bin nicht mit einem Unternehmen verbunden ",
      accept: "Ich akzeptiere, dass diese Informationen gespeichert werden",
      close: "Schließen",
      NoMatch: "Die Passwörter stimmen nicht überein",
      Org: "Org. Nr.",
      Role: "Rolle",
      Pwd: "Passwort",
      ConfirmPwd: "Passwort bestätigen",
      NewUser: "Benutzer erstellen",
      Terms: "Bedingungen"
    }
  };
  

  const translateText = (textKey) => {
    // Get the translated text based on the current language
    return translations[currentLanguage][textKey];
  };

  useEffect(() => {
    let complianceText = '';
    if (currentLanguage === "no") {
      complianceText = complianceText_NO;
    } else if (currentLanguage === "en") {
      complianceText = complianceText_EN;
    } else if (currentLanguage === "gr") {
      complianceText = complianceText_GR;
    } else if (currentLanguage === "pl") {
      complianceText = complianceText_PL;
    } else if (currentLanguage === "uk") {
      complianceText = complianceText_UK;
    } 

    setCompliance(complianceText);
  }, [currentLanguage]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    try {
      // Create a new user with email and password
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);

    // Update the user's display name
    await userCredential.user.updateProfile({
        displayName: userName
      });

    // Send email verification
    await userCredential.user.sendEmailVerification();

    // Add user data to Firestore after email is verified
    const db = firebase.firestore();
    const uid = userCredential.user.uid;

    // Create a new document with user data in the "users" collection
    await db.collection("users").doc(uid).set({
        favourites: [],
        savedSearches:[],
        myAds: [],
        email: email,
        isAdmin: false,
        OrgNr: orgnr,
        Name: userName,
        Role: role,
        Privatperson: privatperson
        });

      // Clear form fields and show success message
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setPasswordsMatch(true);
      alert('User created successfully! Please check your email for verification.');


    } catch (error) {
      // Handle error during user creation
      console.log('Error creating user:', error);
      // Show error message to the user
      alert('Error creating user. Please try again later.');
      navigate("/");
    }
  };


  const handleShowTerms = () => {
    //setComplianceText(currentLanguage);
    setShowCompliance(true);
  }
  
  const closeShowTerms = () => {
    setShowCompliance(false);
  }

  return (
    <div>
      <div class="header-new-user">
        <h3>{translateText('CreateNewUser')}</h3>
      </div>
      <div className="full-form-container">
        
        <p className="new-user-info">
          {translateText('info')}
        </p>
        <div className="newUser-form-container">
          <form className="new-user-form" onSubmit={handleSubmit}>

            <div className="form-element">
              <p>{translateText('name')}</p>
              <input
                placeholder="Ola Nordmann"
                className="input-new-user"
                value={userName}
                onChange={(e) => setuserName(e.target.value)}
                required
              />
            </div>

            <div className="form-element">
              <p>{translateText('email')}</p>
              <input
                type="email"
                placeholder="email@example.com"
                className="input-new-user"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-element">
              <div>
                <input 
                    value={privatperson} 
                    id="privatperson"
                    type="checkbox"
                    onChange={(e) => setPrivatCheck(!privatCheck)}
                />
                <label htmlFor="privatperson">{translateText('privat')}</label>
              </div>
            </div>

            <div className="form-element">
              <p>{translateText('Org')}</p>
              <input
                className="input-new-user"
                value={orgnr}
                placeholder="xxx xxx xxx"
                onChange={(e) => setOrgnr(e.target.value)}
                disabled = {privatCheck}
              />
            </div>

            <div className="form-element">
              <p>{translateText('Role')}</p>
              <input
                className="input-new-user"
                value={role}
                placeholder="Daglig leder, Selger ..."
                onChange={(e) => setRole(e.target.value)}
                disabled = {privatCheck}
              />
            </div>

            <div className="form-element">
              <p>{translateText('Pwd')}</p>
              <input
                type="password"
                className="input-new-user"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="form-element">
              <p>{translateText('ConfirmPwd')}</p>
              <input
                type="password"
                className="input-new-user"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            <div className="form-element">
              <input 
                required 
                type="checkbox">
              </input>
              <label>
                {translateText('accept')}{' '}
                <span onClick={handleShowTerms}>{translateText('Terms')}</span>
              </label>
              {showCompliance && (
                <div className="modal-container-terms">
                  <div dangerouslySetInnerHTML={{ __html: compliance }} />
                  <p className="modal-close-btn" onClick={closeShowTerms}>{translateText('close')}</p>
                </div>
              )}
            </div>
            {!passwordsMatch && <p className="password-match-error">{translateText('NoMatch')}</p>}
            <input className="logIn-btn" type="submit" value={translateText('NewUser')} />
          </form>
        </div>
      </div>
    </div>
  );
}
