import './NyAnnonse.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom';
import sample_img_1 from "../../../imgs_forsale/Sample_0001.png";
import sample_img_2 from "../../../imgs_forsale/Sample_0002.png";
import sample_img_3 from "../../../imgs_forsale/Sample_0003.png";
import sample_img_4 from "../../../imgs_forsale/Sample_0004.png";
import sample_img_5 from "../../../imgs_forsale/Sample_0005.png";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default function NyAnnonse({currentLanguage, firebaseConfig}) {
    firebase.initializeApp(firebaseConfig);
    const storage = firebase.storage();

    const [showFirstDiv, setShowFirstDiv] = useState(true);
    const [showSecondDiv, setShowSecondDiv] = useState(false);
    const [capturedImage, setCapturedImage] = useState("");
    const [imgCounter, setImgCounter] = useState(1);
    const [paddedCountState, setPaddedCountState] = useState("");

    const [isSubmited, setIsSubmited] = useState(false);
    
    const videoRef = useRef("");
    const mediaStreamRef = useRef("");
    const canvasRef = useRef("");

    const [regnrValue, setRegnrValue] = useState('');

    // ####### CATEGORY INPUT #######

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    const handleCategoryChange = (event) => {
      setSelectedCategory(event.target.value);
    };

    const handleSubCategoryChange = (event) =>{
      setSelectedSubCategory(event.target.value);
    }
    // #### END CATEGORY INPUT #######
  
    // #### KEYWORD INPUT ########
    const [inputValue, setInputValue] = useState('');
    const [wordList, setWordList] = useState([]);

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleAddWord = () => {
      setWordList((prevWordList) => [...prevWordList, inputValue]);
      setInputValue('');
    };
    // ### END KEYWORD INPUT #####

    // #### SAVE WITHOUT IMAGES #####
    const handleSaveWithoutImages = async () => {
      try {
        // Find new document number
        const db = firebase.firestore();
        
        const saleObjectsCollection = await db.collection('saleObjects').get();
        const count = saleObjectsCollection.size;
        const paddedCount = (count + 1).toString().padStart(4, '0');
        setPaddedCountState(paddedCount)
        console.log(paddedCount);
        const newDocumentRef = await db.collection('saleObjects').doc(paddedCount);
    
        // Get the array of keywords
        const keywordArray = wordList;
        console.log('Keywords:', keywordArray);

        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const year = String(currentDate.getFullYear()).slice(-2); // Get the last two digits of the year

        const formattedDate = `${day}.${month}.${year}`;
    
        // Get all form inputs
        const formInputs = document.querySelectorAll('form input, form textarea, form select');
        const formInputsArray = Array.from(formInputs);
        console.log(formInputsArray)
        // Create an object to store form input values
        const formData = {};

        // Set specific form input values in formData
        formData["ObjID"] = paddedCount;
        formData["title"] = formInputsArray.find(input => input.name === "title").value;
        formData["brand"] = formInputsArray.find(input => input.name === "brand").value;
        formData["fuel"] = formInputsArray.find(input => input.name === "fuel").value;
        formData["text"] = formInputsArray.find(input => input.name === "freeText").value;
        formData["type"] = formInputsArray.find(input => input.name === "MainCategory").value;
        formData["subType"] = formInputsArray.find(input => input.name === "SubCategory").value;
        formData["price"] = formInputsArray.find(input => input.name === "price").value;
        const mvaInput = formInputsArray.find(input => input.name === "Mva");
        if (mvaInput && mvaInput.checked) {
            formData["Mva"] = "eks.mva";
        }
        formData["totalvekt"] = formInputsArray.find(input => input.name === "totalVekt").value;
        formData["year"] = formInputsArray.find(input => input.name === "year").value;
        formData["country"] = formInputsArray.find(input => input.name === "country").value;
        formData["county"] = formInputsArray.find(input => input.name === "county").value;
        formData["place"] = formInputsArray.find(input => input.name === "place").value;
        formData["kmtimer"] = formInputsArray.find(input => input.name ==="km-brukstimer-enhet").value;
        formData["kmtimerValue"] = formInputsArray.find(input => input.name ==="km-brukstimer").value;
        formData["gear"] = formInputsArray.find(input => input.name ==="gearing").value;
        formData["utils"] = keywordArray;
        formData["status"] = "inactive";
        formData["created"] = formattedDate;

        if (selectedCategory === 'heavyVehicles' || selectedCategory === 'lightTruck') {
          const euText = document.getElementById('EuText').value;
          const regDataText = document.getElementById('RegDataText').value;
          const malVektText = document.getElementById('MålVektText').value;
    
          // Convert text area values to arrays (split by new lines)
          formData["eu"] = euText.trim().split('\n').filter(line => line.trim() !== '');
          formData["regData"] = regDataText.trim().split('\n').filter(line => line.trim() !== '');
          formData["målVekt"] = malVektText.trim().split('\n').filter(line => line.trim() !== '');
        }

        // Write the form data to the new document
        await newDocumentRef.set(formData);

        // Make empty folder in storage
        const storageRef = storage.ref().child(`${paddedCount}/.dummy`);
        await storageRef.put(null);

        setIsSubmited(true);

        console.log('Document created and form data written to Firestore.');
        saveAdminData(formInputsArray, paddedCount, db)
    
      } catch (error) {
        console.error('Error creating document in saleObjects collection:', error);
      }
    };
    
    const saveAdminData = async (formInputsArray, paddedCount, db) => {
      const formAdminData = {};
      formAdminData["OrgNr"] = formInputsArray.find(input => input.name ==="org-number").value;

      const regNumberInput = formInputsArray.find(input => input.name === "reg-number").value;

      if (regNumberInput !== "") {
        formAdminData["RegNr"] = regNumberInput;
      }

      formAdminData["Address"] = formInputsArray.find(input => input.name ==="address").value;
      formAdminData["Seller"] = formInputsArray.find(input => input.name ==="contact-name").value;
      formAdminData["Phone"] = formInputsArray.find(input => input.name ==="contact-number").value;
      formAdminData["Comments"] = [];
      const newDocumentRefAdmin = await db.collection('saleObjectsAdmin').doc(paddedCount);
      await newDocumentRefAdmin.set(formAdminData);
      console.log('Document created and form data written to Firestore (Admin).');
    }

    // ########## IMAGE UPLOAD SECTION #########
    
    const getSampleImage = () => {
      if (imgCounter == 1){
        return sample_img_1
      }
      if (imgCounter == 2){
        return sample_img_2
      }
      if (imgCounter == 3){
        return sample_img_3
      }
      if (imgCounter == 4){
        return sample_img_4
      }
      if (imgCounter == 5){
        return sample_img_5
      }
    };

    const handleImageUpload = (event) => {
      const selectedImage = event.target.files[0];
      const imageUrl = URL.createObjectURL(selectedImage);
      setCapturedImage(imageUrl);
    };

    const startCamera = async () => {
        try {
          const constraints = { video: true, audio: false };
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          videoRef.current.srcObject = stream;
          mediaStreamRef.current = stream;
        } catch (error) {
          console.error('Error accessing camera:', error);
        }
      };
    
    const stopCamera = () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };

    const RetakeImage = () => {
      setCapturedImage("");
      startCamera();
    }

    const captureFrame = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const frameDataURL = canvas.toDataURL('image/png');
        setCapturedImage(frameDataURL);
  
        // Stop the camera after capturing the frame
        stopCamera();
      }
    };

    const handleNextImage = async () => {
      console.log(imgCounter);
      console.log(capturedImage);

      // Set the image name based on imgCounter
      const imageName = imgCounter.toString().padStart(4, '0');
    
      // Reference to the storage location
      const storageRef = storage.ref().child(`${paddedCountState}/${imageName}.jpg`);
    
      // Convert the capturedImage to a Blob
      const blob = await fetch(capturedImage).then((response) => response.blob());
    
      // Upload the Blob to Firebase Storage
      await storageRef.put(blob, { contentType: 'image/jpeg' });

      // Increment imgCounter
      setImgCounter(imgCounter + 1);
    
      // Clear the capturedImage
      RetakeImage();
    };

    useEffect(() => {
      startCamera();
    }
    );
  // ########### END CAMERA SECTION #########

    const goBack = (event) => {
        event.preventDefault();
        setShowFirstDiv(true);
        setShowSecondDiv(false);      
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(isSubmited)
      if(!isSubmited){
        handleSaveWithoutImages();
      };
      setTimeout(() => {
        setShowFirstDiv(false);
        setShowSecondDiv(true);
      }, 4000)
    };


  const handleRegnrSearch = async (event) => {
    console.log(regnrValue);

    const urlToFetch = `https://www.vegvesen.no/ws/no/vegvesen/kjoretoy/felles/datautlevering/enkeltoppslag/kjoretoydata?kjennemerke=${regnrValue}`;
    
    try {
        const response = await axios.get(urlToFetch, {
            headers: {
                "SVV-Authorization": `Apikey 5f53c952-1f1e-4aa1-af23-16db4ed10822`,
                "X-Client-Identifier": "my-app",
            },
        });

        console.log('http status: ' + response.status);

        if (response.status === 200) {
            const kjoretoy = response.data;
            console.log(kjoretoy);
        }
    } catch (error) {
        console.error('Error:', error);
    }
   
  };
    
    return (
        <div>
            <h1 className="header">Ny Annonse</h1>
            {showFirstDiv && (
            <div className="render-first"> 
                <form className="new-booking-container" onSubmit={handleSubmit}>
                    <input type="text" id="address" name="address" placeholder="Adresse for objektet"   className="new-booking-element"/>
                    <input type="text" id="contact-name" name="contact-name" placeholder="Navn selger/firma"   className="new-booking-element"/>
                    <input type="text" id="contact-number" name="contact-number" placeholder="Telefonnummer kontaktperson"   className="new-booking-element"/>
                    <input type="text" id="org-number" name="org-number" placeholder="Org.nr"   className="new-booking-element"/>
                    <input type="text" id="reg-number" name="reg-number" placeholder="Reg.nr"   className="new-booking-element"/>

                    <p>------</p>

                    <div className="regnr-search">
                    <input 
                      type="text" 
                      placeholder="AB23456" 
                      value={regnrValue}
                      onChange={e => setRegnrValue(e.target.value)}
                    />
                      <p onClick={handleRegnrSearch}>SØK</p>
                    </div>

                    <div className="regnr-info">

                    </div>

                    <div className="category-select-container">
                      <select
                        id="country"
                        name="country"
                      >
                        <option value="">Velg land</option>
                        <option value="NOR">Norge</option>
                        <option value="SWE">Sverge</option>
                        <option value="DMK">Danmark</option>
                      </select>
                      <select
                        id="county"
                        name="county"
                      >
                        <option value="">Velg fylke</option>
                        <option value="Akershus">Akershus</option>
                        <option value="Oslo">Oslo</option>
                        <option value="Vestland">Vestland</option>
                        <option value="Rogaland">Rogaland</option>
                        <option value="Trøndelag">Trøndelag</option>
                        <option value="Innlandet">Innlandet</option>
                        <option value="Agder">Agder</option>
                        <option value="Østfold">Østfold</option>
                        <option value="Møre og Romsdal">Møre Og Romsdal</option>
                        <option value="Buskerud">Buskerud</option>
                        <option value="Vestfold">Vestfold</option>
                        <option value="Nordland">Nordland</option>
                        <option value="Telemark">Telemark</option>
                        <option value="Troms">Troms</option>
                        <option value="Finnmark">Finnmark</option>
                      </select>
                      <input id="place" name="place" placeholder="kommune"/>
                      <select 
                        id="MainCategory" 
                        name="MainCategory" 
                        onChange={handleCategoryChange} 
                        value={selectedCategory}
                      >
                        <option value="">Select Category</option>
                        <option value="construction">Anlegg</option>
                        <option value="heavyVehicles">Tunge Kjøretøy</option>
                        <option value="lightTruck">Buss</option>
                        <option value="agricultural">Landbruk</option>
                      </select>

                      {selectedCategory && (
                        <select 
                          id="SubCategory" 
                          name="SubCategory"
                          onChange={handleSubCategoryChange}
                          value={selectedSubCategory} 
                        >
                          {/* Options based on the selected category */}
                          {selectedCategory === 'construction' && (
                            <>
                              <option value="Dumper">Dumper</option>
                              <option value="Gravemaskin">Gravemaskin</option>
                              <option value="Hjullaster">Hjullaster</option>
                              <option value="KnuseOgSiktemaskin">Knuse og Sikteutstyr</option>
                              <option value="Minigraver">Minigraver</option>
                              <option value="Truck">Truck</option>
                              <option value="KontainereOgArbeidsbrakker">Kontainere og arbeidsbrakker</option>
                              <option value="Annet">Annet</option>
                            </>
                          )}

                          {selectedCategory === 'heavyVehicles' && (
                            <>
                              <option value="Betongbil">Betongbil</option>
                              <option value="Chassie">Chassie</option>
                              <option value="Henger">Henger</option>
                              <option value="Kranbil">Kranbil</option>
                              <option value="Krokbil">Krokløft</option>
                              <option value="Planbil">Planbil</option>
                              <option value="Skapbil">Skapbil</option>
                              <option value="Spesialkjøretøy">Spesialkjøretøy</option>
                              <option value="Tankbil">Tankbil</option>
                              <option value="Tippbil">Tippbil</option>
                              <option value="Trekvogn">Trekvogn</option>
                              <option value="Tømmerbil">Tømmerbil</option>
                              <option value="Buss">Buss</option>
                              <option value="Annet">Annet</option>
                            </>                           
                          )}

                          {selectedCategory === 'lightTruck' && (
                            <>
                              <option value="Minibuss">Minibuss</option>
                              <option value="Turbuss">Turbuss</option>
                            </>
                          )}

                          {selectedCategory === 'agricultural' && (
                            <>
                              <option value="TraktorOgRedskap">Traktor og redskap</option>
                              <option value="Transportutstyr">Transportutstyr</option>
                              <option value="Annet">Annet</option>
                            </>
                          )}
                        </select>
                      )}
                    </div>
                    {(selectedCategory === 'heavyVehicles' || selectedCategory === 'lightTruck') && (
                      <div>
                        <p>Lim inn data ifra states vegvesen</p>
                        <textarea id="EuText" name="EuText" placeholder="EU-kontroll" className="new-booking-element-free-text"></textarea>
                        <textarea id="RegDataText" name="RegDataText" placeholder="RegistreringsData " className="new-booking-element-free-text"></textarea>
                        <textarea id="MålVektText" name="MålVektText" placeholder="Mål og vekt" className="new-booking-element-free-text"></textarea>

                      </div>
                    )}
                    <input type="text" id="title" name="title" placeholder="Tittel for annonse"   className="new-booking-element"/>
                    <input type="text" id="price" name="price" placeholder="Pris (NOK)"   className="new-booking-element"/>
                      <div>
                      <input type="checkbox" id="Mva" name="Mva" value="eks.mva"/>
                      <label for="Mva">Eks.mva</label>
                    </div>
                    <input type="number" id="year" name="year" placeholder="Årsmodell"   className="new-booking-element"/>
                    <div className="category-select-container">
                      <select
                        name="brand"
                        id="brand"
                      >
                        <option value="">Select Brand</option>
                        <option value="Volvo"> Volvo </option>
                        <option value="Scania">Scania</option>
                        <option value="CAT">CAT</option>
                        <option value="Toyota">Toyota</option>
                        <option value="Annet">Annet</option>
                      </select>
                    </div>
                    <input type="number" id="horsepower" name="horsepower" placeholder="Hestekrefter"   className="new-booking-element"/>
                    <div className="category-select-container">
                      <select id="gearing" name="gearing" className="category-select-container">
                        <option value="">Select Gearing if applicable</option>
                        <option value="Manuell">Manuell</option>
                        <option value="Automat">Automat</option>
                      </select>
                    </div>

                    <div>
                        <input type="number" id="lengde" name="lengde" placeholder="Lengde [cm]"   className="new-booking-element"/>
                        <input type="number" id="bredde" name="bredde" placeholder="Bredde [cm]"   className="new-booking-element"/>
                    </div>
                    <input type="text" id="fuel" name="fuel" placeholder="Drivstoff"   className="new-booking-element"/>
                    <input type="number" id="totalVekt" name="totalVekt" placeholder="Totalvekt [kg]"   className="new-booking-element"/>
                    <div className="km-brukstimer-selector-container">
                    <input type ="number" id="km-brukstimer" name="km-brukstimer" placeholder="Km / Brukstimer" className="new-booking-element"/>
                      <select 
                        className="km-brukstimer-selector"
                        id="km-brukstimer-enhet"
                        name="km-brukstimer-enhet"
                      >
                        <option value="">N/A</option>
                        <option value="km">Km</option>
                        <option value="brukstimer">Brukstimer</option>
                      </select>
                    </div>
                    <textarea id="freeText" name="freeText" placeholder="Annonsetekst..." className="new-booking-element-free-text"></textarea>
                    <div className="add-utils-container">
                        <input
                            type="text"
                            placeholder="Nøkkelord"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                        <p className="add-util" onClick={handleAddWord}>Add</p>
                    </div>
                    <div className="utils">
                            {wordList.map((word, index) => (
                            <p key={index}>{word}</p>
                            ))}
                    </div>

                    <button id="submitAddImgs" type="submit" onClick={handleSubmit} className="new-booking-submit-btn">Upload Images</button>
                    <button id="submittNoImgs" type="submit" onClick={handleSaveWithoutImages} className="save-without-images">Lagre uten bilder</button>
                </form>
            </div>
            // ##### ADD IMAGE SECTION #######
            )}
            {showSecondDiv && (
            <div className="render-second">
                <div className="upload_and_sample_container">
                    <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*" // Allow only image files
                        capture="camera" 
                        onChange={handleImageUpload}
                        className="upload-image"
                    />
                    <img className="sample-img" src={getSampleImage()} alt={`sample_img_${imgCounter}`} />
                </div>
                {capturedImage ? (
                <div>
                    <img src={capturedImage} className="captured-image" alt="Captured frame" />
                    <button onClick={RetakeImage} className = "retake-img-btn">Ta på nytt</button>
                    <button 
                      className="next-img-btn"
                      onClick={handleNextImage}
                    >
                      Neste
                    </button>
                </div>
                ) : (
                <>
                    <canvas ref={canvasRef}></canvas>
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        className="camera-preview"
                    ></video>
                    <button onClick={captureFrame} className = "next-img-btn">Ta bilde</button>
                </>
                )}
                <button onClick={goBack} className="back-btn">Gå tilbake til annonsetekst</button>
                <Link to="/" >
                  <p className="done-btn">Ferdig</p>
                </Link>
            </div>
            )}
        </div>
)
}