const Terms_EN = `
    <div>
    <h2>Sales and Delivery Terms</h2>

 

    <h4>1. Introduction</h4>
    <p>
    These general sales and delivery terms apply to all offers, sales, and deliveries from Vamu AS (hereinafter "Vamu"). This applies regardless of whether Vamu itself acts as the seller/owner, broker for the seller/owner, or supplier for the seller/owner.
    </p>
     
    <p>
    The term "goods" includes both the product being sold and associated equipment and accessories.
    </p>
     
    <p>
    Any deviations from these terms must be agreed upon in writing between the parties. The agreement includes these terms, the order confirmation, and the purchase contract with any attachments. In the event of a conflict between an individual order and these terms, the order confirmation and/or the purchase contract shall take precedence. Unless otherwise specified in these terms, the Sale of Goods Act of May 13, 1988, No. 27, shall apply. In the event of a discrepancy between the Sale of Goods Act and these terms, these terms shall prevail where the law is not imperative.
    </p>
     
    <p>
    All offers, sales, and deliveries from Vamu are subject to these terms, unless otherwise agreed in writing. The buyer's own general or specific purchase conditions do not apply unless expressly approved in writing by Vamu.
    </p>
     
    
    <h4>2. Offer, Acceptance, Order Confirmation, Purchase Contract</h4>
    <p>
    Vamu's offers are valid within the specified deadline if accepted by the buyer. An agreement between the parties is concluded when the buyer accepts Vamu's offer or when Vamu accepts the buyer's offer. Oral offers from Vamu's representatives become binding when they are confirmed in writing by Vamu, for example, through offer letters, email, or order confirmation.
    </p>
     
    <p>
    If the buyer participates in a bidding round, the buyer's price bid is binding for 2 weeks unless otherwise agreed.
    </p>
     
    <p>
    The agreement for purchase/sale is usually confirmed through an order confirmation confirming the conclusion of the agreement. Vamu will normally convey this and at the same time prepare a purchase contract that includes these terms.
    </p>
     
    <p>
    Any order received by Vamu is confirmed in writing via email, letter, or telephone. The order confirmation is final and binding. These terms form part of the confirmed order and the agreement between the parties. Vamu will correct or rectify errors that occur in written or electronic orders due to circumstances at Vamu. Vamu's general terms and conditions of sale apply to any order or purchase regardless of whether the customer refers to its own conditions in connection with the order.
    </p>
     
    
    <h4>3. Sales Price - Costs</h4>
    <p>
    The price and payment terms are stated in the order confirmation and/or the purchase contract. All prices are quoted inclusive or exclusive of value-added tax, environmental fees, and freight. All costs associated with the takeover shall be borne by the buyer, including taxes, fees, insurance, and transport.
    </p>
     
    <p>
    Vamu may require compensation/adjustment of the purchase price if the costs of implementing the agreement change before the payment date, for example, due to changes in public taxes and duties, environmental fees, or exchange rates. In the event of changes to the agreement, Vamu may require a revised purchase price and possibly a revised delivery time.
    </p>
     
    <p>
    The buyer is responsible for customs clearance and all necessary measures in connection with the import of goods, including all costs associated therewith.
    </p>
     
    
    <h4>4. Delivery - Transfer of Risk</h4>
    <p>
    Delivery takes place in accordance with the agreement. Delivery is deemed to have taken place when the goods are handed over to an independent carrier or made available to the buyer.
    </p>
     
    <p>
    When Vamu itself is responsible for delivery, delivery takes place upon handover to the customer.
    </p>
     
    <p>
    Upon delivery, the risk of accidental loss of the item passes to the buyer. This includes liability for further transport, insurance, etc. All shipments after delivery are at the buyer's risk.
    </p>
     
    
    <h4>5. Delivery Time</h4>
    <p>
    Delivery of goods that Vamu is able to deliver from its own inventory shall normally take place immediately. Vamu reserves the right to extend the delivery time to a reasonable extent in order to fulfill any separately agreed transport obligations or if the scope, other deliveries, or similar circumstances make immediate delivery difficult.
    </p>
     
    <p>
    The specified delivery time in the offer, order confirmation, or contract for goods located with a third party is to be considered indicative, and Vamu may freely deliver such goods up to 1 week before or after the specified delivery time.
    </p>
     
    
    <h4>6. Delay - Delivery Obstruction</h4>
    <p>
    Vamu is not responsible for failure to fulfill agreed obligations to the extent that agreed performance is prevented or unreasonably impeded by circumstances beyond Vamu's control. This includes circumstances such as war, mobilization, acts of terrorism, requisitioning, seizure, significant changes in the currency market, export or import bans, labor disputes, inadequate supply of raw materials, lack of transport, fire, factory accidents, defects in deliveries from subcontractors, or delays from such.
    </p>
     
    <p>
    Delivery shall be in accordance with the order, and the delivery obligation is not considered fulfilled until all order lines in the order have been delivered. Partial deliveries are fully accepted, and Vamu has the right to ensure full delivery within reasonable deadlines. However, Vamu is obliged to notify the buyer if there is reason to believe that the agreed delivery time cannot be met. If the new delivery time is not acceptable to the buyer, this must be immediately communicated in writing to Vamu.
    </p>
     
    <p>
    Goods specially ordered for the customer cannot be canceled. Except for the customer's right to cancel due to significant delayed delivery, any claim by the buyer due to Vamu's delayed delivery is excluded.
    </p>
     
    <p>
    Neither party shall be deemed to have defaulted on an obligation under the order to the extent that performance of the agreement has been prevented by force majeure.
    </p>
     
    
    <h4>7. Return Agreement - Termination Fee</h4>
    <p>
    Return of goods can only take place by written agreement with Vamu. If the customer does not fulfill the agreement or does not wish to fulfill the agreement after ordering and before delivery, Vamu is entitled to a termination fee that may be charged to the customer. The termination fee shall cover compensation/lost profits and is set at 10% of the invoice amount excluding VAT and expenses. If the item needs to be returned, return shipping shall be at the customer's expense. Freight in connection with ordering/delivery is not credited. The reason for the return must be clarified in the return agreement. All returned goods must be undamaged/in the same condition as before the contract was entered into/delivery.
    </p>
     
    
    <h4>8. Payment Terms</h4>
    <p>
    Unless otherwise stated in the purchase contract, the buyer shall pay the entire purchase price to Vamu's specified account before delivery. The purchase price will then be transferred to the supplier after deductions for commission, costs, and expenses have been made.
    </p>
     
    <p>
    Payment security can only be provided if accepted by Vamu. Unless otherwise agreed, the security shall be provided as a joint surety from a bank, insurance company, or other credit institution.
    </p>
     
    <p>
    In the event of delayed payment, the buyer shall pay default interest at the prevailing rate under the Default Interest Act of December 17, 1976, No. 100.
    </p>
     
    <p>
    The buyer is not exempt from paying or providing security even if he complains of errors or defects. The buyer is also not entitled to set off from other contractual relationships if the set-off is disputed. However, the buyer does not lose the right to claim a price adjustment after paying what corresponds to the error or defect that may exist, cf. clause 9.
    </p>
     
    <p>
    If a different payment arrangement has been agreed than that stated in this section, the seller has a lien on the delivered goods until the purchase price plus interest and costs has been paid in full. The buyer has no right to pledge the goods until the purchase price plus interest and costs has been paid in full.
    </p>
     
    
    <h4>9. Complaints</h4>
   <p>
   The delivery shall meet the order specifications and comply with the specified requirements for performance and quality. The buyer shall inspect the goods immediately upon receipt and make a complaint without undue delay if the shipment does not conform to the order confirmation/purchase contract.
   </p>
   <p>
   A complaint that is not justified will not be credited.
   </p>
   <p>
   The buyer loses their right to claim a defect if they do not notify the seller within one week after discovering or should have discovered the defect, specifying the nature of the defect.
   </p>
   <p>
   The buyer is aware of and accepts the following:
   </p>
   <p>
   a) Vamu AS is not financially responsible for any complaints. Any complaints should be directed to the owner of the object, and all claims arising from complaints will be a matter between the owner of the object and the buyer.
   </p>
   <p>
   b) Vamu AS will, however, assist in facilitating communication between the buyer and the owner of the object, with the aim of finding the best possible solution for all parties.
   </p>
   <p>
   c) The item is used/worn, and the agreement concerns a second-hand purchase.
   </p>
   <p>
   d) The item is sold as is ("as is").
   </p>
   <p>
   e) Vamu AS is an intermediary between the previous owner and the buyer, or potentially a temporary new owner to facilitate resale.
   </p>
   <p>
   f) Vamu AS has no knowledge of the item beyond what has been communicated by the previous owner.
   </p>
   <p>
   g) The buyer has been given the opportunity and encouraged to:
   </p>
   <ul>
   <li>
      inspect the item before entering into the agreement.
   </li>
   <li>
      seek professional assistance to inspect the item.
   </li>
   <li>
      raise questions about the item directly to the seller's supplier and obtain the necessary information from them.
   </li>
   </ul>
   <p>
   h) Vamu AS is not responsible for transport damage. The buyer has been offered and encouraged to take out separate transport insurance. In the event of transport damage, a complaint must be made to the carrier immediately.
   </p>
   <p>
   i) The buyer shall cooperate in the sale at every stage of the sales and delivery process and provide information required by the seller/supplier.
   </p>
   <p>
   j) The purchase price has been accepted by the buyer and reflects what the buyer believes is the correct value of the item.
   </p>
   <p>
   If the delivery suffers from a defect, the seller has the right to remedy the defect within a reasonable time, including by making a replacement delivery if necessary.
   </p>
   <p>
   The buyer loses all rights to claim the defect if they do not make a complaint in accordance with section 9. The complaint must be made in writing.
   </p>
   <p>
   Beyond the above, the seller is not responsible for defects. This applies to any loss the defects may cause, such as operational loss, lost profits, and economic consequential losses.
   </p>
   <p>
   The buyer is aware of and accepts that they cannot make a claim against the seller to a greater extent than the seller can make a claim against their supplier/previous owner/legal predecessor.
   </p>

     
    
    <h4>10. Dispute Resolution</h4>
    <p>
    The agreement is governed by Norwegian law. Any disputes between the parties regarding an order or sale shall be settled by the ordinary courts. Sør-Trøndelag District Court is agreed upon as the exclusive legal venue.
    </p>
     
    </div>
`;

export default Terms_EN;
