import './Ansatte.css';
import React, {useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import data from "./Ansatte.json";
import EmployeeElement from "./ansatte-element.js";
import employee_1_img from './imgs_ansatte/employee_01_v2.jpeg';
import employee_2_img from './imgs_ansatte/employee_02_v2.jpeg';
import employee_3_img from './imgs_ansatte/employee_03_v2.jpeg';

const employeeImages = {
  1: employee_1_img,
  2: employee_2_img,
  3: employee_3_img
};

const translations = {
  no: {
    ourEmployees: 'Våre Ansatte',
    helloMessage: "Hils på våre ansatte",
    "Daglig Leder": "Daglig Leder",
    "Selger": "Selger",
    "Salgsrådgiver": "Salgsrådgiver",
    "IT" : "IT"

  },
  en: {
    ourEmployees: 'Our Employees',
    helloMessage: "Say hello to our employees",
    "Daglig Leder": "Managing director",
    "Selger": "Sales",
    "Salgsrådgiver": "Sales advisor",
    "IT" : "IT"
  },
  pl: {
    ourEmployees: 'Nasi pracownicy',
    helloMessage: "Przywitaj się z naszymi pracownikami",
    "Daglig Leder": "Dyrektor generalny",
    "Selger": "Sprzedawca",
    "Salgsrådgiver": "Doradca sprzedaży",
    "IT" : "IT"
  },
  uk: {
    ourEmployees: 'Наші працівники',
    helloMessage: "Передай привіт нашим працівникам",
    "Daglig Leder": "генеральний директор",
    "Selger": "продавець",
    "Salgsrådgiver": "продавець-консультант",
    "IT" : "IT"
  },
  gr: {
    ourEmployees: 'Unsere Angestellte',
    helloMessage: "Begrüßen Sie unsere Mitarbeiter",
    "Daglig Leder": "CEO",
    "Selger": "Verkäufer",
    "Salgsrådgiver": "Verkaufsberater",
    "IT" : "IT"
  }
};

const Ansatte = ({currentLanguage, firebaseConfig}) => {
  const [employees, setEmployees] = useState([]);
  const translateText = (textKey) => {
    // Get the translated text based on the current language
    return translations[currentLanguage][textKey];
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when this component mounts
  }, []);

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    const db = firebase.firestore();
    const fetchEmployees = async () => {
      try{
        const employeesCollection = await db.collection('employees').get();
        const employeesData = employeesCollection.docs.map(doc => ({
          userId: doc.id,
          ...doc.data()
        }));
        setEmployees(employeesData);
      } catch (error){
        console.log("Error fetching employees: ", error);
      }
    };
    
    fetchEmployees();

    return () => {

    };
    
  }, [firebaseConfig]);

  return (
    <div>
      <h1 className="header-ansatte">{translateText("ourEmployees")}</h1>
      {/* <p>{translateText('helloMessage')}</p> */}
      <div className="employees-container">
      {employees.map((employee) => {
        const employeeImg = employeeImages[employee.userId];
        return (
          <EmployeeElement 
            key={employee.userId}
            employee={employee}
            translateText={translateText}
            employeeImg = {employeeImg}
          />
        );
      })}
    </div>
    </div>
  );
}

export default Ansatte;