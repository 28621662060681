import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage';
import './myFavourites.css';

import translations from "./translations.js";

const MyFavourites = ({ currentLanguage, loggedInUser }) => {
    const [favorites, setFavorites] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);


    const translateText = (textKey) => {
        // Get the translated text based on the current language
        return translations[currentLanguage][textKey];
      };

      useEffect(() => {
        // Reference to the GCS folder
        const storageRef = firebase.storage().ref();

        // Fetch the first image for each favorite
        const fetchImagesForFavorites = async () => {
            const promises = favorites.map(async (favorite) => {
                const folderRef = storageRef.child(favorite.ObjID);
                try {
                    const result = await folderRef.listAll();
                    if (result.items.length > 0) {
                        // Get the download URL for the first image in the folder
                        const imageUrl = await result.items[0].getDownloadURL();
                        return imageUrl;
                    } else {
                        return null; // No images found in this folder
                    }
                } catch (error) {
                    console.error(`Error fetching images for ${favorite.ObjID}:`, error);
                    return null;
                }
            });

            // Resolve all promises
            const urls = await Promise.all(promises);
            setImageUrls(urls.filter(url => url !== null));
        };

        if (favorites.length > 0) {
            fetchImagesForFavorites();
        }
    }, [favorites]);

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                if (!loggedInUser) return;

                const userRef = firebase.firestore().collection('users').doc(loggedInUser.uid);
                const doc = await userRef.get();

                if (doc.exists) {
                    const userData = doc.data();
                    const userFavorites = userData.favorites || [];
                    const promises = userFavorites.map(async (favorite) => {
                        const favoriteRef = firebase.firestore().collection('saleObjects').doc(favorite);
                        const favoriteDoc = await favoriteRef.get();
                        return favoriteDoc.exists ? favoriteDoc.data() : null;
                    });

                    const favoriteData = await Promise.all(promises);
                    const validFavorites = favoriteData.filter((data) => data !== null);
                    console.log(validFavorites)
                    setFavorites(validFavorites);
                }
            } catch (error) {
                console.log('Error retrieving favorites:', error);
            }
        };

        fetchFavorites();
    }, [loggedInUser]);

    const showHoursOrKm = (favorite) => {
        if (favorite.kmtimer === "") {
          return "";
        } else if (favorite.kmtimer === "km") {
          return `${favorite.kmtimerValue} km`;
        } else if (favorite.kmtimer === "brukstimer") {
          return `${favorite.kmtimerValue} t`;
        } else {
          // Handle other cases if needed
          return "Invalid value for kmtimer";
        }
      };


    return (
        <div>
            <h1 className="header">{translateText('MineFavs')}</h1>
            <div className="favorite-result-container">
                {favorites.length > 0 ? (
                    favorites.map((favorite, index) => (
                      <Link to={`../ForSale/${favorite.ObjID}`}
                            className="favorite-result" 
                            key={index}>
                        {imageUrls[index] && (
                            <img className="img-for-sale-favorite" src={imageUrls[index]} alt={`Image ${index}`} />
                        )}
                        <div className="favorite-info-container">
                        <h3 className="favorite-title">{favorite.title} [{favorite.status}]</h3>
                            <p className="search-place">
                                <i>{favorite.place}, {favorite.county}, {favorite.country}</i>
                            </p>
                            <div className="favorite-sub-info-container">
                                <p>{favorite.year}</p>
                                <p>{showHoursOrKm(favorite)}</p>
                                {favorite.status !== "sold (Not Delivered)" && (
                                <p>{favorite.price},- NOK</p>
                                )}
                            </div>
                        </div>

                      </Link>
                    ))
                ) : (
                    <p>{translateText('NoFavs')}</p>
                )}
            </div>
        </div>
    );
};

export default MyFavourites;