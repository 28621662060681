const translations = 
{
  no: {

    Header:
    "Om Oss",
    
    TopInfo:
    "VAMU er et selskap som tar hele prosessen av vidreformidling av maskiner og utstyr. Firmaet ble grunnlagt i 2023, men våre ansatte har lang erfaring ifra bransjen.",
    
    InfoBox1Title:
    "Vi tar ansvar for dine behov",
    
    InfoBox1Description:
    "Vamu er en bedrift som er dedikert til å tilby tjenester innen salg og kjøp av anleggsmaskiner, lastebiler, busser og tilbehør. Vi er et pålitelig team som brenner for å sikre at våre kunder opplever enestående service og resultater. Med vår ekspertise og lidenskap for bransjen, kan du være trygg på at vi tar ansvar for dine behov.",
    
    InfoBox2Title:
    "Bygging av globale forbindelser",
    
    InfoBox2Description:
    "Siden etableringen av Vamu har vi arbeidet aktivt med å etablere sterke forbindelser over hele verden. Dette gjør oss i stand til å finne det utstyret du trenger eller den rette kjøperen du søker etter. Vi har et omfattende nettverk, og det gir deg tilgang til en verden av muligheter.",
    
    InfoBox3Title:
    "Økonomisk trygghet",
    
    InfoBox3Description:
    "Våre erfarne meglere sikrer at alle økonomiske transaksjoner mellom kjøper og selger går riktig for seg. Vi er dedikerte til å opprettholde integritet og sikre at både kjøper og selger oppnår en rettferdig avtale. Med Vamu kan du ha full tillit til at den økonomiske delen av transaksjonen blir håndtert på en profesjonell og pålitelig måte.",

    InfoBox4Title:
    "Enkelhet og trygghet",
    
    InfoBox4Description:
    "Vamu forstår at det å selge utstyr kan være tidkrevende og distraherende fra din daglige drift. Ved å la oss håndtere salgsprosessen for deg, kan vi garantere en enkel og problemfri opplevelse uten risiko. Vi tar oss av alt det praktiske og holder deg oppdatert på alt du trenger å vite. Vårt erfarne team jobber hardt for å sikre at dine objekter blir solgt til avtalt pris. Og hvis vi av en eller annen grunn ikke lykkes med å selge objektet ditt, belastes du heller ingen kostnader.",
    
    InfoBox5Title:
    "Vi selger for deg",
    
    InfoBox5Description:
    "Vamu selger ulike typer utstyr. Enten det dreier seg om anleggsmaskiner, lastebiler, hengere, busser eller traktorer. Vi har dyktige salgsrådgivere som dekker hele landet, og vi er tilgjengelige for å hjelpe deg uansett hvor du befinner deg.",
    
    InfoBox6Title:
    "Ta kontakt med oss",
    
    InfoBox6Description:
    "Vi ønsker å høre fra deg! Besøk vår kontaktside for å finne våre telefonnummer og e-postadresse. Vi er klare til å svare på spørsmålene dine og diskutere hvordan Vamu kan hjelpe deg med dine behov innen anleggsmaskiner, lastebiler, busser og tilbehør. Velg oss som din pålitelige partner, og opplev enestående service og resultater."
    
    },
    "en": {
      Header: "About Us",
      TopInfo: "VAMU is a company that handles the entire process of forwarding machines and equipment. The company was founded in 2023, but our employees have extensive experience in the industry.",
      InfoBox1Title: "We Take Responsibility for Your Needs",
      InfoBox1Description: "Vamu is a company dedicated to providing services in the sale and purchase of construction machinery, trucks, buses, and accessories. We are a reliable team dedicated to ensuring that our customers experience outstanding service and results. With our expertise and passion for the industry, you can be confident that we take responsibility for your needs.",
      InfoBox2Title: "Building Global Connections",
      InfoBox2Description: "Since the establishment of Vamu, we have actively worked on establishing strong connections worldwide. This enables us to find the equipment you need or the right buyer you are looking for. We have an extensive network, giving you access to a world of opportunities.",
      InfoBox3Title: "Financial Security",
      InfoBox3Description: "Our experienced brokers ensure that all financial transactions between buyer and seller proceed smoothly. We are dedicated to maintaining integrity and ensuring that both buyer and seller achieve a fair deal. With Vamu, you can have full confidence that the financial aspect of the transaction is handled professionally and reliably.",
      InfoBox4Title: "Simplicity and Security",
      InfoBox4Description: "Vamu understands that selling equipment can be time-consuming and distracting from your daily operations. By letting us handle the sales process for you, we can guarantee a simple and hassle-free experience without risk. We take care of all the practicalities and keep you updated on everything you need to know. Our experienced team works hard to ensure that your items are sold at the agreed price. And if for any reason we fail to sell your item, you will not incur any costs.",
      InfoBox5Title: "We Sell for You",
      InfoBox5Description: "Vamu sells various types of equipment. Whether it's construction machinery, trucks, trailers, buses, or tractors. We have skilled sales advisors covering the entire country, and we are available to assist you no matter where you are.",
      InfoBox6Title: "Contact Us",
      InfoBox6Description: "We want to hear from you! Visit our contact page to find our phone number and email address. We are ready to answer your questions and discuss how Vamu can help you with your needs for construction machinery, trucks, buses, and accessories. Choose us as your reliable partner and experience outstanding service and results."
    },
    "pl": {
      Header: "O nas",
      TopInfo: "VAMU to firma, która zajmuje się całościowym procesem przekazywania maszyn i urządzeń. Firma została założona w 2023 roku, ale nasi pracownicy posiadają bogate doświadczenie w branży.",
      InfoBox1Title: "Bierzemy odpowiedzialność za Twoje potrzeby",
      InfoBox1Description: "Vamu to firma dedykowana świadczeniu usług w zakresie sprzedaży i zakupu maszyn budowlanych, samochodów ciężarowych, autobusów i akcesoriów. Jesteśmy niezawodnym zespołem, którego celem jest zapewnienie naszym klientom doskonałej obsługi i wyników. Dzięki naszemu doświadczeniu i pasji do branży, możesz być pewien, że podejmiemy się odpowiedzialności za Twoje potrzeby.",
      InfoBox2Title: "Budowanie globalnych połączeń",
      InfoBox2Description: "Od momentu powstania Vamu aktywnie pracowaliśmy nad nawiązaniem silnych połączeń na całym świecie. Dzięki temu jesteśmy w stanie znaleźć potrzebny Ci sprzęt lub właściwego nabywcę, którego szukasz. Posiadamy szeroką sieć, która daje Ci dostęp do świata możliwości.",
      InfoBox3Title: "Bezpieczeństwo finansowe",
      InfoBox3Description: "Nasi doświadczeni brokerzy zapewniają, że wszystkie transakcje finansowe między kupującym a sprzedającym przebiegają sprawnie. Jest dla nas ważne utrzymanie integralności i zapewnienie, aby zarówno kupujący, jak i sprzedający osiągnęli uczciwą umowę. Dzięki Vamu możesz mieć pełne zaufanie, że aspekt finansowy transakcji jest profesjonalnie i rzetelnie obsługiwany.",
      InfoBox4Title: "Prostota i bezpieczeństwo",
      InfoBox4Description: "Vamu rozumie, że sprzedaż sprzętu może być czasochłonna i odciągająca od codziennych operacji. Pozwalając nam zająć się procesem sprzedaży za Ciebie, możemy zagwarantować prostą i bezproblemową obsługę bez ryzyka. Zajmujemy się wszystkimi praktycznymi sprawami i informujemy Cię o wszystkim, co musisz wiedzieć. Nasz doświadczony zespół pracuje ciężko, aby Twoje przedmioty zostały sprzedane za uzgodnioną cenę. A jeśli z jakiegoś powodu nie uda nam się sprzedać Twojego przedmiotu, nie poniesiesz żadnych kosztów.",
      InfoBox5Title: "Sprzedajemy za Ciebie",
      InfoBox5Description: "Vamu sprzedaje różne rodzaje sprzętu. Czy to maszyny budowlane, samochody ciężarowe, przyczepy, autobusy czy traktory. Mamy wykwalifikowanych doradców ds. sprzedaży, którzy obejmują cały kraj, i jesteśmy dostępni, aby Ci pomóc, bez względu na to, gdzie się znajdujesz.",
      InfoBox6Title: "Skontaktuj się z nami",
      InfoBox6Description: "Chcemy usłyszeć od Ciebie! Odwiedź naszą stronę kontaktową, aby znaleźć nasz numer telefonu i adres e-mail. Jesteśmy gotowi odpowiedzieć na Twoje pytania i omówić, jak Vamu może Ci pomóc w potrzebach dotyczących maszyn budowlanych, samochodów ciężarowych, autobusów i akcesoriów. Wybierz nas jako swojego wiarygodnego partnera i doświadcz doskonałej obsługi i wyników."
    },
    "uk": {
      Header: "Про нас",
      TopInfo: "VAMU - це компанія, яка бере на себе весь процес передачі машин та обладнання. Компанія була заснована в 2023 році, але наші співробітники мають великий досвід у галузі.",
      InfoBox1Title: "Ми беремо на себе відповідальність за ваші потреби",
      InfoBox1Description: "Vamu - це компанія, присвячена наданню послуг у сфері продажу та покупки будівельної техніки, вантажівок, автобусів та аксесуарів. Ми - надійна команда, яка працює на забезпечення того, щоб наші клієнти отримували винятковий сервіс та результати. З нашим досвідом і пристрастю до галузі ви можете бути впевнені, що ми беремо на себе відповідальність за ваші потреби.",
      InfoBox2Title: "Побудова глобальних зв'язків",
      InfoBox2Description: "З часу заснування Vamu ми активно працюємо над створенням міцних зв'язків по всьому світу. Це дозволяє нам знаходити необхідне вам обладнання або потрібного вам покупця. У нас є широка мережа, яка відкриває перед вами світ можливостей.",
      InfoBox3Title: "Фінансова безпека",
      InfoBox3Description: "Наші досвідчені брокери забезпечують, що всі фінансові транзакції між покупцем та продавцем відбуваються безперебійно. Ми присвячені збереженню інтегритету та забезпеченню того, щоб як покупець, так і продавець досягли справедливої угоди. З Vamu ви можете повністю довіряти, що фінансовий аспект транзакції обробляється професійно та надійно.",
      InfoBox4Title: "Простота і безпека",
      InfoBox4Description: "Vamu розуміє, що продаж обладнання може бути витратним часом і відволікати вас від щоденних операцій. Дозволяючи нам займатися процесом продажу за вас, ми можемо гарантувати простий і безпечний досвід без ризику. Ми піклуємося про всі практичні питання і тримаємо вас в курсі всього, що вам потрібно знати. Наш досвідчений колектив працює наполегливо, щоб ваші товари були продані за узгодженою ціною. І якщо з якоїсь причини нам не вдасться продати ваш товар, ви не понесете жодних витрат.",
      InfoBox5Title: "Ми продаємо за вас",
      InfoBox5Description: "Vamu продає різні типи обладнання. Чи то будівельна техніка, вантажівки, причепи, автобуси чи трактори. У нас є кваліфіковані консультанти з продажу, що охоплюють всю країну, і ми готові вам допомогти, незалежно від того, де ви знаходитеся.",
      InfoBox6Title: "Зв'яжіться з нами",
      InfoBox6Description: "Ми хочемо почути від вас! Відвідайте нашу сторінку контактів, щоб знайти наш номер телефону та електронну адресу. Ми готові відповісти на ваші запитання та обговорити, як Vamu може допомогти вам у питаннях, що стосуються будівельної техніки, вантажівок, автобусів та аксесуарів. Оберіть нас як свого надійного партнера та отримайте винятковий сервіс та результати."
    },
    "gr": {
      Header: "Über uns",
      TopInfo: "VAMU ist ein Unternehmen, das den gesamten Prozess der Weiterleitung von Maschinen und Ausrüstungen übernimmt. Das Unternehmen wurde 2023 gegründet, aber unsere Mitarbeiter haben langjährige Erfahrung in der Branche.",
      InfoBox1Title: "Wir übernehmen Verantwortung für Ihre Bedürfnisse",
      InfoBox1Description: "Vamu ist ein Unternehmen, das sich auf den Verkauf und Kauf von Baumaschinen, Lastwagen, Bussen und Zubehör spezialisiert hat. Wir sind ein zuverlässiges Team, das sich dafür einsetzt, dass unsere Kunden herausragenden Service und Ergebnisse erhalten. Mit unserem Fachwissen und unserer Leidenschaft für die Branche können Sie sicher sein, dass wir Verantwortung für Ihre Bedürfnisse übernehmen.",
      InfoBox2Title: "Aufbau globaler Verbindungen",
      InfoBox2Description: "Seit der Gründung von Vamu arbeiten wir aktiv daran, weltweit starke Verbindungen aufzubauen. Dadurch sind wir in der Lage, die von Ihnen benötigte Ausrüstung oder den richtigen Käufer zu finden, den Sie suchen. Wir verfügen über ein umfangreiches Netzwerk, das Ihnen Zugang zu einer Welt voller Möglichkeiten bietet.",
      InfoBox3Title: "Finanzielle Sicherheit",
      InfoBox3Description: "Unsere erfahrenen Broker stellen sicher, dass alle finanziellen Transaktionen zwischen Käufer und Verkäufer reibungslos ablaufen. Wir sind bestrebt, Integrität zu wahren und sicherzustellen, dass sowohl Käufer als auch Verkäufer eine faire Vereinbarung erzielen. Mit Vamu können Sie vollstes Vertrauen haben, dass der finanzielle Aspekt der Transaktion professionell und zuverlässig abgewickelt wird.",
      InfoBox4Title: "Einfachheit und Sicherheit",
      InfoBox4Description: "Vamu versteht, dass der Verkauf von Ausrüstung zeitaufwändig sein kann und Sie von Ihrem täglichen Betrieb ablenken kann. Indem Sie uns den Verkaufsprozess für Sie überlassen, können wir Ihnen ein einfaches und problemloses Erlebnis ohne Risiko garantieren. Wir kümmern uns um alle praktischen Details und halten Sie über alles auf dem Laufenden, was Sie wissen müssen. Unser erfahrenes Team arbeitet hart daran, dass Ihre Artikel zum vereinbarten Preis verkauft werden. Und wenn aus irgendeinem Grund der Verkauf Ihres Artikels nicht gelingt, entstehen Ihnen keine Kosten.",
      InfoBox5Title: "Wir verkaufen für Sie",
      InfoBox5Description: "Vamu verkauft verschiedene Arten von Ausrüstung. Ob Baumaschinen, Lastwagen, Anhänger, Busse oder Traktoren. Wir haben erfahrene Verkaufsberater, die das gesamte Land abdecken, und wir stehen Ihnen zur Verfügung, egal wo Sie sich befinden.",
      InfoBox6Title: "Kontaktieren Sie uns",
      InfoBox6Description: "Wir möchten von Ihnen hören! Besuchen Sie unsere Kontaktseite, um unsere Telefonnummer und E-Mail-Adresse zu finden. Wir sind bereit, Ihre Fragen zu beantworten und zu besprechen, wie Vamu Ihnen bei Ihren Bedürfnissen in Bezug auf Baumaschinen, Lastwagen, Busse und Zubehör helfen kann. Wählen Sie uns als Ihren verlässlichen Partner und erleben Sie herausragenden Service und Ergebnisse."
    }
};

export default translations;