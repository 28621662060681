import React, {useRef} from 'react';
import './Ansatte.css';
import PropTypes from 'prop-types';
import phone_icon from "../../imgs/phone-solid.svg";
import mail_icon from "../../imgs/envelope-solid.svg";
import useInView from './use-in-view.js';

const EmployeeElement = ({ employee, translateText, employeeImg}) => {

  const ref = useRef(null);
  const hasBeenViewed = useInView(ref);

  return (
    <div 
        className={`employee-item ${hasBeenViewed ? 'visibleEmployee' : ''}`}
        ref={ref} 
        key={employee.userId}
    >
      <img
        src={employeeImg}
        className="employee-img"
        alt={`${employee.firstName} ${employee.lastName}`}
      />
      <div className="employee-text">
        <h3>{`${employee.firstName} ${employee.lastName}`}</h3>
        <div className="employee-sub-text">
          <p className="title-region-text">
            {translateText(employee.jobTitleName)}, {employee.region}
          </p>
          <div className="employee-phone-container">
            <img src={phone_icon} alt="Phone icon" />
            <p>{employee.phoneNumber}</p>
          </div>
          <div className="employee-phone-container">
            <img src={mail_icon} alt="Mail icon" />
            <p>
              <a href={`mailto:${employee.emailAddress}`}>
                {employee.emailAddress}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// PropTypes for type-checking props
EmployeeElement.propTypes = {
  employee: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    jobTitleName: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    emailAddress: PropTypes.string.isRequired,
  }).isRequired,
  translateText: PropTypes.func.isRequired,
};

export default EmployeeElement;