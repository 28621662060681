import React from 'react';
import './Arkiv.css';

{/* IKKE I BRUK */}

const Arkiv = ({ currentLanguage, accessToken, loggedInUser, objects }) => {
    // Filter the objects with status "Sold"
    const soldObjects = objects.Objects.filter((obj) => obj.status === 'sold (Delivered)');
  
    return (
      <div>
        <h1 className="header">Alle annonser</h1>
        <div className="main-container-ads">
          <div className="filter-container">
            <p>filter meny</p>
          </div>
          <div className="ads-container-archive">
            {soldObjects.length === 0 ? (
                <p>Ingen solgte objecter enda :)</p>
            ) : (
                soldObjects.map((obj) => (
                <div key={obj.ObjId} className="object-div">
                    {/* Render the object properties here */}
                    <h4>{obj.title}</h4>
                    <div className="object-info-div-archive">
                    <p>{obj.price} NOK</p>
                    <p>{obj.county}</p>
                    <p>Lagt ut: {obj.created}</p>
                    <p>Status: {obj.status}</p>
                    </div>
                    {/* Add more properties as needed */}
                </div>
                ))
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default Arkiv;