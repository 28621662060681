const translations = {
    no: {
        Annonser : "Annonser",
        agricultural: "Landbruk",
        construction: "Anlegg",
        lightTruck: "Buss",
        heavyVehicles: "Tunge Kjøretøy",
        Back:"Tilbake til søk",
        Question: "Lurer du på noe?",
        ContactUs: 'Kontakt oss!',
        Terms: "Vilkår",
        Year: "Årsmodell",
        Weight: "Totalvekt",
        Fuel: "Drivstoff",
        Gear: "Gir",
        Category: "Kategori",
        Link1: "Lenke 1",
        Link2: "Lenke 2",
        Betongbil: "Betongbil",
        Chassie: "Chassie",
        Henger: "Henger",
        Kranbil: "Kranbil",
        Krokløft: "Krokbil",
        Planbil: "Planbil",
        Skapbil: "Skapbil",
        Spesialkjøretøy: "Spesialkjøretøy",
        Tankbil: "Tankbil",
        Tippbil: "Tippbil",
        Trekvogn: "Trekvogn",
        Tømmerbil: "Tømmerbil",
        Buss: "Buss",
        Annet: "Annet",
        Dumper: "Dumper",
        Gravemaskin: "Gravemaskin",
        Hjullaster: "Hjullaster",
        KnuseOgSiktemaskin: "Knuse og Sikteutstyr",
        Minigraver: "Minigraver",
        Truck: "Truck",
        KontainereOgArbeidsbrakker: "Kontainere og arbeidsbrakker",
        Minibuss: "Minibuss",
        Turbuss: "Turbuss",
        TraktorOgRedskap: "Traktor og redskap",
        Transportutstyr: "Transportutstyr"
    },
    en: {
        Annonser : "Ads",
        agricultural: "Agricultural",
        construction: "Construction",
        lightTruck: "Bus",
        heavyVehicles: "Heavy Vehicles",
        Back:"Back to search",
        Question: "Do you have any questions?",
        ContactUs: "Contact Us!",
        Terms: "Terms",
        Year: "Year",
        Weight: "Weight",
        Fuel: "Fuel",
        Gear: "Gear",
        Category: "Category",
        Link1: "Link 1",
        Link2: "Link 2",
        Betongbil: "Concrete Truck",
        Chassie: "Chassis",
        Henger: "Trailer",
        Kranbil: "Crane Truck",
        Krokløft: "Hook Lift",
        Planbil: "Flatbed Truck",
        Skapbil: "Box Truck",
        Spesialkjøretøy: "Special Vehicle",
        Tankbil: "Tank Truck",
        Tippbil: "Dump Truck",
        Trekvogn: "Tractor Unit",
        Tømmerbil: "Timber Truck",
        Buss: "Bus",
        Annet: "Other",
        Dumper: "Dumper",
        Gravemaskin: "Excavator",
        Hjullaster: "Wheel Loader",
        KnuseOgSiktemaskin: "Crushing and Screening Equipment",
        Minigraver: "Mini Excavator",
        Truck: "Forklift",
        KontainereOgArbeidsbrakker: "Containers and Site Huts",
        Minibuss: "Minibus",
        Turbuss: "Coach",
        TraktorOgRedskap: "Tractor and Equipment",
        Transportutstyr: "Transport Equipment"
    },
    pl: {
        Annonser : "Reklamy",
        agricultural: "Rolnicze",
        construction: "Budowlane",
        lightTruck: "Autobus",
        heavyVehicles: "Ciężkie Pojazdy",
        Back:"Powrót do ogłoszeń",
        Question: "Masz jakieś pytania?",
        ContactUs: "Skontaktuj się z nami!",
        Terms: "Warunki",
        Year: "Rok",
        Weight: "Waga całkowita",
        Fuel: "Paliwo",
        Gear: "Bieg",
        Category: "Kategoria",
        Link1: "Link 1",
        Link2: "Link 2",
        Betongbil: "Betonomieszarka",
        Chassie: "Podwozie",
        Henger: "Przyczepa",
        Kranbil: "Samochód z dźwigiem",
        Krokløft: "Haki",
        Planbil: "Samochód platformowy",
        Skapbil: "Samochód skrzyniowy",
        Spesialkjøretøy: "Pojazd specjalny",
        Tankbil: "Cysterna",
        Tippbil: "Wywrotka",
        Trekvogn: "Ciągnik siodłowy",
        Tømmerbil: "Samochód do przewozu drewna",
        Buss: "Autobus",
        Annet: "Inne",
        Dumper: "Wozidło",
        Gravemaskin: "Koparka",
        Hjullaster: "Ładowarka kołowa",
        KnuseOgSiktemaskin: "Urządzenia krusząco-sortujące",
        Minigraver: "Mini Koparka",
        Truck: "Wózek widłowy",
        KontainereOgArbeidsbrakker: "Kontenery i baraki",
        Minibuss: "Minibus",
        Turbuss: "Autokar",
        TraktorOgRedskap: "Traktor i osprzęt",
        Transportutstyr: "Sprzęt transportowy"
    },
    uk: {
        Annonser : "рекламні оголошення",
        agricultural: "Сільськогосподарські",
        construction: "Будівельні",
        lightTruck: "автобус",
        heavyVehicles: "Важкі Транспортні Засоби",
        Back:"Повернутися до оголошень",
        Question: "У вас є які-небудь питання?",
        ContactUs: "Зв'яжіться з нами!",
        Terms: "Умови",
        Year: "Рік",
        Weight: "Загальна вага",
        Fuel: "Пальне",
        Gear: "Передача",
        Category: "Категорія",
        Link1: "Посилання 1",
        Link2: "Посилання 2",
        Betongbil: "Бетоновоз",
        Chassie: "Шасі",
        Henger: "Причіп",
        Kranbil: "Крановий автомобіль",
        Krokløft: "Гак",
        Planbil: "Платформний автомобіль",
        Skapbil: "Фургон",
        Spesialkjøretøy: "Спеціальний транспорт",
        Tankbil: "Автоцистерна",
        Tippbil: "Самоскид",
        Trekvogn: "Тягач",
        Tømmerbil: "Лісовоз",
        Buss: "Автобус",
        Annet: "Інше",
        Dumper: "Думпер",
        Gravemaskin: "Екскаватор",
        Hjullaster: "Колісний навантажувач",
        KnuseOgSiktemaskin: "Дробильне та сортувальне обладнання",
        Minigraver: "Міні екскаватор",
        Truck: "Навантажувач",
        KontainereOgArbeidsbrakker: "Контейнери та робочі бараки",
        Minibuss: "Мікроавтобус",
        Turbuss: "Туристичний автобус",
        TraktorOgRedskap: "Трактор та обладнання",
        Transportutstyr: "Транспортне обладнання"
    },
    gr: {
        Annonser : "Werbung",
        agricultural: "Landwirtschaft",
        construction: "Bauwesen",
        lightTruck: "Bus",
        heavyVehicles: "Schwere Fahrzeuge",
        Back:"Zurück zu Anzeigen",
        Question: "Haben Sie eine Frage?",
        ContactUs: "Kontaktieren Sie uns!",
        Terms: "Bedingungen",
        Year: "Jahr",
        Weight: "Gesamtgewicht",
        Fuel: "Kraftstoff",
        Gear: "Gänge",
        Category: "Kategorie",
        Link1: "Link 1",
        Link2: "Link 2",
        Betongbil: "Betonmischer",
        Chassie: "Chassis",
        Henger: "Anhänger",
        Kranbil: "Kranwagen",
        Krokløft: "Hakenschlepp",
        Planbil: "Plattformwagen",
        Skapbil: "Kastenwagen",
        Spesialkjøretøy: "Spezialfahrzeug",
        Tankbil: "Tankwagen",
        Tippbil: "Kipper",
        Trekvogn: "Sattelzugmaschine",
        Tømmerbil: "Holztransportwagen",
        Buss: "Bus",
        Annet: "Andere",
        Dumper: "Muldenkipper",
        Gravemaskin: "Bagger",
        Hjullaster: "Radlader",
        KnuseOgSiktemaskin: "Brech- und Siebanlage",
        Minigraver: "Minibagger",
        Truck: "Gabelstapler",
        KontainereOgArbeidsbrakker: "Container und Arbeitsbaracken",
        Minibuss: "Kleinbus",
        Turbuss: "Reisebus",
        TraktorOgRedskap: "Traktor und Ausrüstung",
        Transportutstyr: "Transportausrüstung"
    }
};

export default translations;
