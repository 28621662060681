const complianceText = `
<h3>User Data Privacy Notice</h3>
<p>Dear user</p>

<p>At VAMU AS, we are committed to protecting your privacy and ensuring the security of your personal data. As part of our compliance with the General Data Protection Regulation (GDPR), we want to inform you about how we collect, use, and protect your information.</p>

<h4>Information We Collect</h4>

<p>When you sign up to our services, you agree to the storing of the sumbited date to our database.</p>

<p>We have implemented appropriate technical and organizational measures to safeguard your personal data from unauthorized access, disclosure, alteration, and destruction. We regularly review and update our security practices to ensure the highest level of protection.</p>

<h4>Your Rights</h4>

<p>Under GDPR, you have the following rights regarding your personal data:</p>
<ul>
<li>The right to access your data and receive a copy of it</li>
<li>The right to correct inaccuracies in your data</li>
<li>The right to request the deletion of your data</li>
<li>The right to restrict or object to the processing of your data</li>
<li>The right to data portability</li>
<li>Legal Basis for Processing</li>
</ul>
<p>We process your personal data based on one or more of the following legal grounds:</p>

<ul>
<li>Your consent</li>
<li>Contractual necessity</li>
<li>Compliance with legal obligations</li>
<li>Legitimate interests pursued by us or a third party</li>
</ul>
<h4>Contact Us</h4>

<p>If you have any questions, concerns, or requests regarding your personal data or our privacy practices, please contact us at post@vamu.no.</p>

<p>Thank you for trusting VAMU AS with your personal information. We are committed to protecting your privacy and will continue to uphold the highest standards of data protection.</p>

<p>Sincerely,
IT department 
VAMU AS</p>`;

export default complianceText ;