const translations = 
{
  no: {
    Sold:"Solgt",
    Filter: "Filter",
    ForSale: "Annonser",
    Search: "Søk...",
    Types: "Kategorier",
    Agricultural: "Landbruk",
    Construction: "Anlegg",
    LightTruck: "Buss",
    HeavyVehicles: "Tunge Kjøretøy",
    County: "Velg fylke",
    Brand: "Merke",
    Price: "Pris",
    Other: "Annet",
    Minimum: "Minimum",
    Maximum: "Maximum",
    Årsmodell:"Årsmodell",
    From: "Fra",
    To: "Til",
    Search: "Søk",
    NoHits: "Ingen Treff",
    Agru1:"Traktor og redskap",
    Agru2: "Transportutstyr",
    Agru3:"Annet",
    Const1: "Dumper",
    Const2: "Gravemaskin",
    Const3: "Hjullaster",
    Const4: "Knuse og Sikteutstyr",
    Const5: "Minigraver",
    Const6: "Truck",
    Const7: "Kontainere og arbeidsbrakker",
    Const8: "Annet",
    Ligh1: "Minibuss",
    Ligh2: "Turbuss",
    Heav1: "Betongbil",
    Heav2: "Chassie",
    Heav3: "Henger",
    Heav4: "Kranbil",
    Heav5: "Krokløft",
    Heav6: "Planbil",
    Heav7: "Skapbil",
    Heav8: "Spesialkjøretøy",
    Heav9: "Tankbil",
    Heav10: "Tippbil",
    Heav11: "Trekkvogn",
    Heav12: "Tømmerbil",
    Heav13: "Buss",
    Heav14: "Annet",
  },
  en: {
    Sold:"Sold",
    Filter: "Filter",
    ForSale: "Ads",
    Search: "Search...",
    Types: "Types",
    Agricultural: "Agricultural",
    Construction: "Construction",
    LightTruck: "Bus",
    HeavyVehicles: "Heavy Vehicles",
    County: "County",
    Brand: "Brand",
    Price: "Price",
    Other: "Other",
    Minimum: "Minimum",
    Maximum: "Maximum",
    Årsmodell:"Model Year",
    From: "From",
    To: "To",
    Search: "Search",
    NoHits: "No Hits",
    Agru1: "Tractor and Equipment",
    Agru2: "Transport Equipment",
    Agru3: "Other",
    Const1: "Dumpers",
    Const2: "Excavators",
    Const3: "Wheel Loaders",
    Const4: "Crushing and Screening Equipment",
    Const5: "Mini Excavators",
    Const6: "Forklifts",
    Const7: "Containers and Site Huts",
    Const8: "Other",
    Ligh1: "Minibus",
    Ligh2: "Tour bus",
    Heav1: "Concrete Mixer",
    Heav2: "Chassis",
    Heav3: "Trailer",
    Heav4: "Crane Truck",
    Heav5: "Hooklift Truck",
    Heav6: "Flatbed Truck",
    Heav7: "Box Truck",
    Heav8: "Special Vehicles",
    Heav9: "Tank Truck",
    Heav10: "Tipper Truck",
    Heav11: "Semi-Trailer Truck",
    Heav12: "Timber Truck",
    Heav13: "Bus",
    Heav14: "Other",
  },
  pl: {
    Sold:"Sprzedane",
    Filter: "Filtr",
    ForSale: "Na sprzedaż",
    Search: "Szukaj...",
    Types: "Typy",
    Agricultural: "Rolnicze",
    Construction: "Budowlane",
    LightTruck: "Autobus",
    HeavyVehicles: "Ciężkie Pojazdy",
    County: "Województwo",
    Brand: "Marka",
    Price: "Cena",
    Other: "Inne",
    Minimum: "Minimum",
    Maximum: "Maksimum",
    Årsmodell:"Rocznik",
    From: "Od",
    To: "Do",
    Search: "Szukaj",
    NoHits: "Brak wyników",
    Agru1: "Traktory i wyposażenie",
    Agru2: "Sprzęt transportowy",
    Agru3: "Inne",
    Const1: "Wywrotki",
    Const2: "Koparki",
    Const3: "Ładowarki kołowe",
    Const4: "Urządzenia do kruszenia i sortowania",
    Const5: "Mini koparki",
    Const6: "Wózki widłowe",
    Const7: "Kontenery i baraki budowlane",
    Const8: "Inne",
    Ligh1: "Minibus",
    Ligh2: "Autobus wycieczkowy",
    Heav1: "Mieszalnik do betonu",
    Heav2: "Podwozie",
    Heav3: "Przyczepa",
    Heav4: "Samochód żuraw",
    Heav5: "Samochód ciężarowy z systemem hakowym",
    Heav6: "Samochód ciężarowy z plandeką",
    Heav7: "Samochód dostawczy",
    Heav8: "Specjalne pojazdy",
    Heav9: "Cysterna",
    Heav10: "Samochód ciężarowy z wywrotem",
    Heav11: "Ciężarówka naczepowa",
    Heav12: "Samochód ciężarowy do przewozu drewna",
    Heav13: "Autobus",
    Heav14: "Inne",
    },
  uk: {
    Sold:"Продано",
    Filter: "Фільтр",
    ForSale: "На продаж",
    Search: "Пошук...",
    Types: "Типи",
    Agricultural: "Сільськогосподарські",
    Construction: "Будівельні",
    LightTruck: "автобус",
    HeavyVehicles: "Важкі Транспортні Засоби",
    County: "Область",
    Brand: "Бренд",
    Price: "Ціна",
    Other: "Інше",
    Minimum: "Мінімум",
    Maximum: "Максимум",
    Årsmodell:"рік моделі ",
    From: "Від",
    To: "До",
    Search: "Пошук",
    NoHits: "Немає результатів",
    Agru1: "Трактори та обладнання",
    Agru2: "Транспортне обладнання",
    Agru3: "Інше",
    Const1: "Самоскиди",
    Const2: "Екскаватори",
    Const3: "Навантажувачі",
    Const4: "Обладнання для дроблення та сортування",
    Const5: "Мініекскаватори",
    Const6: "Вилкові навантажувачі",
    Const7: "Контейнери та будівельні будки",
    Const8: "Інше",
    Ligh1: "мікроавтобус",
    Ligh2: "екскурсійний автобус",
    Heav1: "Бетономішалка",
    Heav2: "Шасі",
    Heav3: "Причіп",
    Heav4: "Вантажівка з краном",
    Heav5: "Вантажівка з гаковим висувом",
    Heav6: "Вантажівка з плоскою платформою",
    Heav7: "Фургон",
    Heav8: "Спеціальні транспортні засоби",
    Heav9: "Цистерна",
    Heav10: "Вантажівка з вивантаженням",
    Heav11: "Напівпричіп",
    Heav12: "Вантажівка для перевезення деревини",
    Heav13: "Автобус",
    Heav14: "Інше",
  },
  gr: {
    Sold:"Verkauft",
    Filter: "Filter",
    ForSale: "Zu verkaufen",
    Search: "Suche...",
    Types: "Typen",
    Agricultural: "Landwirtschaft",
    Construction: "Bauwesen",
    LightTruck: "Bus",
    HeavyVehicles: "Schwere Fahrzeuge",
    County: "Landkreis",
    Brand: "Marke",
    Price: "Preis",
    Other: "Andere",
    Minimum: "Minimum",
    Maximum: "Maximal",
    Årsmodell:"Baujahr",
    From: "Von",
    To: "Bis",
    Search: "Suche",
    NoHits: "Keine Ergebnisse",
    Agru1: "Traktor und Ausrüstung",
    Agru2: "Transportausrüstung",
    Agru3: "Andere",
    Const1: "Kipper",
    Const2: "Bagger",
    Const3: "Radlader",
    Const4: "Brech- und Siebausrüstung",
    Const5: "Mini-Bagger",
    Const6: "Gabelstapler",
    Const7: "Container und Baubaracken",
    Const8: "Andere",
    Ligh1: "Kleinbus",
    Ligh2: "Tourbus",
    Heav1: "Betonomixer",
    Heav2: "Fahrgestell",
    Heav3: "Anhänger",
    Heav4: "Kranwagen",
    Heav5: "Abrollkipper",
    Heav6: "Pritschenwagen",
    Heav7: "Kastenwagen",
    Heav8: "Spezialfahrzeuge",
    Heav9: "Tankwagen",
    Heav10: "Kipper",
    Heav11: "Sattelzug",
    Heav12: "Holztransporter",
    Heav13: "Bus",
    Heav14: "Andere"
    }
};

export default translations;