import React from 'react';
import { Link } from 'react-router-dom';

const ForSaleLink = ({ onClick, label, count, imgSrc, imgAlt, type }) => {
  return (
    <Link 
      to={"/for-sale"} 
      className="sell-main-link" 
      //onClick={onClick}
      state = {{type: type}}
    >
      <div className="main-item">
        <p>{label}</p>
        <p className="main-count">({count})</p>
        <img src={imgSrc} className="icon-item" alt={imgAlt} />
      </div>
    </Link>
  );
};

export default ForSaleLink;