const translations = {
    no: {
      home: 'Hjem',
      ourEmployees: 'Våre Ansatte',
      aboutUs: 'Om Oss',
      login: 'Logg Inn',
      logout: 'Logg ut',
      headerText: 'VAMU Norway',
      splashText: 'Videreformidling av Maskin og Anleggsutstyr',
      settings: 'Innstillinger',
      Favs: 'Mine favoritter',
      Saves: 'Lagrede søk',
      MyAds: 'Mine Annonser'
    },
    en: {
      home: 'Home',
      ourEmployees: 'Our Employees',
      aboutUs: 'About Us',
      login: 'Log In',
      logout: 'Log out',
      headerText: 'VAMU Norway',
      splashText: 'Distribution of Machinery and Equipment',
      settings: 'Settings',
      Favs: 'My Favorites',
      Saves: 'Saved Searches',
      MyAds: 'My Ads'
    },
    pl: {
      home: 'Strona główna',
      ourEmployees: 'Nasi pracownicy',
      aboutUs: 'O nas',
      login: 'Zaloguj się',
      logout: 'Wyloguj się',
      headerText: 'VAMU Norwegia',
      splashText: 'Dystrybucja maszyn i sprzętu',
      settings: 'Ustawienia',
      Favs: 'Moje ulubione',
      Saves: 'Zapisane wyszukiwania',
      MyAds: 'Moje ogłoszenia'
    },
    uk: {
      home: 'Головна',
      ourEmployees: 'Наші працівники',
      aboutUs: 'Про нас',
      login: 'Увійти',
      logout: 'Вийти',
      headerText: 'VAMU Норвегія',
      splashText: 'Розподіл машин та обладнання',
      settings: 'Налаштування',
      Favs: 'Мої вподобання',
      Saves: 'Збережені пошуки',
      MyAds: 'Мої оголошення'
    },
    gr: {
      home: 'Heim',
      ourEmployees: 'Unsere Angestellte',
      aboutUs: 'Über uns',
      login: 'Anmeldung',
      logout: 'Ausloggen',
      headerText: 'VAMU Norwegen',
      splashText: 'Vertrieb von Maschinen und Geräten',
      settings: 'Einstellungen',
      Favs: 'Meine Favoriten',
      Saves: 'Gespeicherte Suchen',
      MyAds: 'Meine Anzeigen'
    }
  };
  
  export default translations;
  