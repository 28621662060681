const Terms_UK = `
<div>
    <h3>Загальні умови продажу та поставки</h3>
    <p>
        Ці загальні умови продажу та поставки застосовуються до всіх пропозицій, продажів і поставок, здійснюваних компанією VAMU AS.
        Це стосується незалежно від того, чи виступає VAMU AS як продавець/власник, посередник для продавця/власника або постачальник для продавця/власника.
        Якщо умови продажу стосуються "продукту", це означає як сам продукт, який продається, так і обладнання і аксесуари, які йдуть в комплекті.
    </p>
    <p>
        Будь-які відхилення від умов продажу повинні бути узгоджені письмово між сторонами.
        Згідно з угодою, ці умови продажу, підтвердження замовлення та договір купівлі з будь-якими додатками розуміються.
        У разі конфлікту між окремим замовленням та умовами продажу, перевага надається підтвердженню замовлення та/або договору купівлі.
        Якщо у цих умовах не зазначено інше, застосовується Закон про продаж товарів від 13 травня 1988 року, № 27.
        У разі розбіжностей між Законом про продаж товарів та цими умовами продажу перевага надається умовам продажу там, де закон не обов'язковий.
        Всі пропозиції, продажі та поставки від VAMU AS регулюються цими умовами, якщо не домовлено письмово про щось інше.
        Власні загальні або спеціальні умови покупця не застосовуються, якщо VAMU AS не затвердило їх письмово.
    </p>
</div>
`

export default Terms_UK;