const Info_NO = `

<h4>Viktig informasjon om kjøp hos Vamu AS</h4>

<p>Vær oppmerksom på at Vamu AS ikke er eieren av de objektene som annonseres. På generelt grunnlag anbefaler vi alle potensielle kjøpere å besiktige objektet personlig før en eventuell handel finner sted. Dette sikrer at du som kjøper får en fullstendig forståelse og tilfredsstillelse med ditt kjøp. Vi tar forbehold om eventuelle feil i annonsen.</p>

<h4>Hvorfor velge Vamu AS?</h4>

<p>Hos Vamu AS er vi dedikerte til å gjøre kjøp og salg av anleggsmaskiner, lastebiler, busser og annet tungt utstyr så enkelt og bekymringsfritt som mulig for våre kunder. Vårt team tar hånd om hele salgsprosessen – alt fra fotografering og annonsering til håndtering av henvendelser. Alt du trenger å gjøre er å informere oss om hva du ønsker å selge, eller hva du er på utkikk etter. La oss ta hånd om resten!</p>

<p>Sammen gjør vi kjøp og salg enklere.</p>

`
export default Info_NO