const translations = {
    no: {
      construction: 'Anlegg',
      heavyVehicles: 'Tunge Kjøretøy',
      lightTruck: 'Buss',
      agricultural: 'Landbruk',
      selltext: 'Har du noe du er interessert i å selge?',
      headtext: 'Ta en kikk på vårt utvalg av anleggsutstyr',
      search: "Søk",
      searchPlaceholder: "Ser du etter noe spesielt?"
    },
    en: {
      construction: 'Construction',
      heavyVehicles: 'Heavy Vehicles',
      lightTruck: 'Bus',
      agricultural: 'Agricultural',
      selltext: 'Do you have anything to sell?',
      headtext: 'Take a look at what we have to sell',
      search: "Search",
      searchPlaceholder: "Looking for something spesific?"
    },
    pl: {
      construction: 'Budowa',
      heavyVehicles: 'Ciężarówka',
      lightTruck: 'Autobus',
      agricultural: 'Rolnictwo',
      selltext: 'Czy masz coś do sprzedania?',
      headtext: 'Zobacz naszą ofertę maszyn budowlanych',
      search: 'Szukaj',
      searchPlaceholder: "Szukasz czegoś konkretnego?"
    },
    uk: {
      construction: 'Будівництво',
      heavyVehicles: 'Вантажівка',
      lightTruck: 'Автобус',
      agricultural: 'Сільське господарство',
      selltext: 'У вас є що-небудь для продажу?',
      headtext: 'Подивіться, що ми маємо для продажу',
      search: 'Пошук',
      searchPlaceholder: "Шукаєте щось конкретне?"
    },
    gr: {
        construction: 'Konstruktion',
        heavyVehicles: 'Schwere Fahrzeuge',
        lightTruck: 'Bus',
        agricultural: 'Landwirtschaftlich',
        selltext: 'Haben Sie etwas zu verkaufen?',
        headtext: 'Schauen Sie sich an, was wir zu verkaufen haben',
        search: 'Suchen',
        searchPlaceholder: "Suchen Sie etwas Bestimmtes?"    
    }
  };

export default translations;