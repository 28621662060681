import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const handleSubmit = async (event) => {
    event.preventDefault();
  
    const db = firebase.firestore(); // Get a reference to Firestore
    const form = event.target; // Reference to the form element
    const employee = form.elements.employee.value;
    const date = form.elements.date.value;
    const time = form.elements.time.value;
    const address = form.elements.address.value;
    const contactName = form.elements['contact-name'].value; // Use array notation for names with hyphens
    const contactNumber = form.elements['contact-number'].value;
    const freeText = form.elements.freeText.value;
    const OrgNr = form.elements["organisation-number"].value;

    try {
      await db.collection('Bookings').add({
        employee,
        date: date || null,
        time: time || null,
        address,
        contactName,
        contactNumber,
        freeText,
        OrgNr
      });
  
      // Clear form after successful submission
      form.reset();
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };

export default handleSubmit;