const complianceText = `
<h3>Personvernerklæring for brukerdata</h3>
<p>Kjære bruker,</p>

<p>Hos VAMU AS er vi forpliktet til å beskytte personvernet ditt og sikre sikkerheten til dine personlige data. Som en del av vår overholdelse av den generelle personvernforordningen (GDPR), ønsker vi å informere deg om hvordan vi samler inn, bruker og beskytter informasjonen din.</p>

<h4>Informasjon vi samler inn</h4>

<p>Når du registrerer deg for våre tjenester, samtykker du til lagring av de innsendte dataene til vår database.</p>

<p>Vi har implementert passende tekniske og organisatoriske tiltak for å beskytte dine personopplysninger mot uautorisert tilgang, offentliggjøring, endring og ødeleggelse. Vi gjennomgår og oppdaterer jevnlig våre sikkerhetspraksiser for å sikre den høyeste beskyttelsen.</p>

<h4>Dine rettigheter</h4>

<p>I henhold til GDPR har du følgende rettigheter angående dine personopplysninger:</p>
<ul>
<li>Retten til å få tilgang til dataene dine og motta en kopi av dem</li>
<li>Retten til å rette unøyaktigheter i dataene dine</li>
<li>Retten til å be om sletting av dataene dine</li>
<li>Retten til å begrense eller motsette deg behandlingen av dataene dine</li>
<li>Retten til dataoverførbarhet</li>
</ul>
<p>Juridisk grunnlag for behandling</p>

<ul>
<li>Ditt samtykke</li>
<li>Kontraktsmessig nødvendighet</li>
<li>Overholdelse av juridiske forpliktelser</li>
<li>Legitime interesser forfulgt av oss eller en tredjepart</li>
</ul>
<h4>Kontakt oss</h4>

<p>Hvis du har spørsmål, bekymringer eller forespørsler angående dine personopplysninger eller våre personvernpraksiser, vennligst kontakt oss på post@vamu.no.</p>

<p>Takk for at du stoler på VAMU AS med dine personlige opplysninger. Vi er forpliktet til å beskytte ditt personvern og vil fortsette å opprettholde de høyeste standarder for databeskyttelse.</p>

<p>Med vennlig hilsen,<br>IT-avdelingen<br>VAMU AS</p>
`;
export default complianceText ;