const Terms_PL = `
<div>
    <h3>Ogólne Warunki Sprzedaży i Dostawy</h3>
    <p>
        Niniejsze ogólne warunki sprzedaży i dostawy dotyczą wszystkich ofert, sprzedaży i dostaw dokonywanych przez VAMU AS.
        Dotyczy to niezależnie od tego, czy VAMU AS działa jako sprzedawca/właściciel, pośrednik dla sprzedawcy/właściciela, czy dostawca dla sprzedawcy/właściciela.
        Gdy warunki sprzedaży odnoszą się do "produktu", oznacza to zarówno sam produkt, który jest sprzedawany, jak i wyposażenie i akcesoria dołączone.
    </p>
    <p>
        Odstępstwa od warunków sprzedaży muszą być uzgodnione na piśmie między stronami.
        Na mocy umowy rozumie się te warunki sprzedaży, potwierdzenie zamówienia i umowę kupna z załączonymi załącznikami.
        W przypadku sprzeczności między pojedynczym zamówieniem a warunkami sprzedaży, pierwszeństwo mają potwierdzenie zamówienia i/lub umowa kupna.
        Chyba że w tych warunkach wyraźnie zaznaczono inaczej, stosuje się ustawę o sprzedaży towarów z dnia 13 maja 1988 r., nr 27.
        W przypadku rozbieżności między ustawą o sprzedaży towarów a tymi warunkami sprzedaży, warunki sprzedaży mają pierwszeństwo, jeśli prawo nie jest obowiązkowe.
        Wszystkie oferty, sprzedaże i dostawy od VAMU AS podlegają tym warunkom, chyba że strony uzgodniły pisemnie coś innego.
        Własne ogólne lub konkretne warunki zakupu nabywcy nie mają zastosowania, chyba że VAMU AS wyraziło na nie pisemną zgodę.
    </p>
</div>
`

export default Terms_PL;