import translations from './translations.js';

export const getPriceNumber = (priceString) => {
    // Remove non-numeric characters such as commas and spaces
    const numericString = priceString.replace(/[^0-9.]/g, '');
    // Parse the numeric string to a floating-point number
    return parseFloat(numericString);
};

export const showHoursOrKm = (Obj) => {
    if (Obj.kmtimer === "") {
        return "";
    } else if (Obj.kmtimer === "km") {
        return `${Obj.kmtimerValue} km`;
    } else if (Obj.kmtimer === "brukstimer") {
        return `${Obj.kmtimerValue} t`;
    } else {
        // Handle other cases if needed
        return "Invalid value for kmtimer";
    }
};

export const translateText = (textKey, currentLanguage) => {
    // Split the textKey by '.' to access nested properties
    const keys = textKey.split('.');
    // Get the translated text based on the current language
    let translatedText = translations[currentLanguage];
    for (const key of keys) {
        translatedText = translatedText[key];
    }
    return translatedText;
};

export const mapFolderIdToIndex = (folderId) => {
    // Remove leading zeros and convert to an integer
    return parseInt(folderId, 10) - 1; // Subtract 1 to make it 0-based
  };

  export const formatPrice = (priceString) => {
    // Remove any non-numeric characters (except spaces)
    let cleanedString = priceString.replace(/[^\d\s]/g, '');
  
    // Remove any existing spaces
    cleanedString = cleanedString.replace(/\s+/g, '');
  
    // Add spaces as thousand separators
    const formattedString = cleanedString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  
    return formattedString;
  };