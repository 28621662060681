import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import './ForgottenPwd.css';

const ForgottenPwd = () => {
    const [email, setEmail] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        // Check if the email is a registered user
        const users = await firebase.auth().fetchSignInMethodsForEmail(email);
  
        if (users.length === 0) {
          // Email is not registered
          alert('Email is not registered. Please enter a valid email address.');
          return;
        }
  
        // Send password reset email
        await firebase.auth().sendPasswordResetEmail(email);
  
        // Clear the form field and show success message
        setEmail('');
        alert('Password reset email sent. Please check your email to reset your password.');
  
      } catch (error) {
        // Handle error during password reset
        console.log('Error sending password reset email:', error);
        // Show error message to the user
        alert('Error sending password reset email. Please try again later.');
      }
    };
  
    return (
      <div>
        <h1>Get Password to Mail</h1>
        <form className="get-password-email" onSubmit={handleSubmit}>
          <input
            type="email"
            className="email-input-get-password"
            placeholder="email@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input className="btn-get-password" type="submit" value="Get Password" />
        </form>
      </div>
    );
  }
  
  export default ForgottenPwd;