import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import qr_img from '../../imgs/NewUserQR.png';
import './qrKode.css';

const QrKode = ({ currentLanguage, loggedInUser, firebaseConfig }) => {
  const navigate = useNavigate();
  firebase.initializeApp(firebaseConfig);
  

  return (
    <div>
      <h1 className="header-qr">Qr Kode</h1>
      <div>
        <p>Scan for å registrere ny bruker.</p>
        <img className="qr-img" src={qr_img} alt="QR Code" />
      </div>
    </div>
  );
};

export default QrKode;