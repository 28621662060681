const complianceText = `
<h3>Повідомлення про конфіденційність особистих даних користувача</h3>
<p>Шанована(ий) користувачко, користувачу,</p>

<p>У компанії VAMU AS ми зобов'язані захищати вашу конфіденційність та забезпечувати безпеку вашої особистої інформації. В рамках виконання загального регламенту про захист даних (GDPR) ми хочемо повідомити вас про те, як ми збираємо, використовуємо та захищаємо вашу інформацію.</p>

<h4>Інформація, яку ми збираємо</h4>

<p>Під час реєстрації на наші послуги ви погоджуєтеся на зберігання наданих даних в нашій базі даних.</p>

<p>Ми впровадили відповідні технічні та організаційні заходи для захисту вашої особистої інформації від несанкціонованого доступу, розголошення, зміни та знищення. Ми регулярно переглядаємо та оновлюємо наші практики безпеки для забезпечення найвищого рівня захисту.</p>

<h4>Ваші права</h4>

<p>Згідно з GDPR, у вас є певні права щодо ваших особистих даних:</p>
<ul>
<li>Право на доступ до ваших даних та отримання їх копії</li>
<li>Право на виправлення неточностей в ваших даних</li>
<li>Право на вимогу видалення ваших даних</li>
<li>Право на обмеження або супротив обробці ваших даних</li>
<li>Право на перенос даних</li>
</ul>
<p>Правові підстави для обробки</p>

<ul>
<li>Ваша згода</li>
<li>Контрактна необхідність</li>
<li>Виконання юридичних обов'язків</li>
<li>Обгрунтовані інтереси, які переслідуються нами або третьою стороною</li>
</ul>
<h4>Контакт</h4>

<p>Якщо у вас є питання, сумніви або запити щодо ваших особистих даних або наших практик щодо конфіденційності, будь ласка, зв'яжіться з нами за адресою post@vamu.no.</p>

<p>Дякуємо вам за довіру, яку ви надаєте компанії VAMU AS, передаючи нам вашу особисту інформацію. Ми зобов'язані захищати вашу конфіденційність і продовжуватимемо дотримуватися найвищих стандартів захисту даних.</p>

<p>З повагою,<br>Відділ IT<br>VAMU AS</p>
`;

export default complianceText ;