const translations = {
    no: {
        MineFavs: "Mine Favoritter",
        RefNr: 'Ref Nr: ',
        NoFavs: "Du har ingen favoritter",
      },
      en: {
        MineFavs: "My Favorites",
        RefNr: 'Ref No: ',
        NoFavs: "You have no favorites",
      },
      pl: {
        MineFavs: "Moje ulubione",
        RefNr: 'Nr referencyjny: ',
        NoFavs: "Nie masz ulubionych",
      },
      uk: {
        MineFavs: "Мої улюблені",
        RefNr: '№ посилання: ',
        NoFavs: "У вас немає улюблених",
      },
      gr: {
        MineFavs: "Meine Favoriten",
        RefNr: 'Ref Nr: ',
        NoFavs: "Sie haben keine Favoriten",
      }
  };

export default translations;