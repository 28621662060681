const translations = {
    no: {
      Header: 'Mine Annonser',
      NoAds: "Du har ingen annonser hos VAMU.no",
    },
    en: {
      Header: 'My Ads',
      NoAds: "You have no ads on VAMU.no",
    },
    pl: {
      Header: 'Moje Ogłoszenia',
      NoAds: "Nie masz żadnych ogłoszeń na VAMU.no",
    },
    uk: {
      Header: 'Мої Оголошення',
      NoAds: "У вас немає оголошень на VAMU.no",
    },
    gr: {
      Header: 'Meine Anzeigen',
      NoAds: "Sie haben keine Anzeigen auf VAMU.no",
    }
  };

  export default translations;