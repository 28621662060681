import React, { useState, useEffect } from 'react';
import './Bookinger.css';
import data from "../../Ansatte/Ansatte.json";
import 'react-calendar/dist/Calendar.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import getHeaderColor from "./getHeaderColor";
import getOrgName from "./getOrgName";
import toastStyle from "./toastStyle";
import handleSubmit from "./handleSubmit";


const firebaseConfig = {
    apiKey: "AIzaSyCgoN9PO-AKgNl4zhEp0DErNWDH7wCyL8A",
    authDomain: "vamu-c709e.firebaseapp.com",
    projectId: "vamu-c709e",
    storageBucket: "vamu-c709e.appspot.com",
    messagingSenderId: "796944835166",
    appId: "1:796944835166:web:c5681719aefc40dde94acf",
    measurementId: "G-58CBKM6QMD"
  };

  firebase.initializeApp(firebaseConfig);

const Bookinger = () => {
  const [selectedChoice, setSelectedChoice] = useState('Alle bookinger');
  const [bookings, setBookings] = useState([]);
  const [orgNames, setOrgNames] = useState({});
  const [expandedBookingId, setExpandedBookingId] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [fetchAll, setFetchAll] = useState(false);
  const [liveUpdate, setLiveUpdate] = useState(false);
  let previousDate = null;

  const [showToast, setShowToast] = useState(false);
  const [filters, setFilters] = useState({
    notContacted: false,
    contacted: false,
    visited: false,
    sale: false,
    notSale: false
  });



  useEffect(() => {
    // Listen for messages from the opener window
    const handleMessage = (event) => {
      const { data } = event;
      console.log(data)
      // Check if the message contains the expected data
      if (data && data.setSelectedChoice) {
        // Set the state in the Bookinger component
        setSelectedChoice(data.setSelectedChoice);
      }
    };
  
    // Add event listener
    window.addEventListener('message', handleMessage);
  
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setSelectedChoice]);

  const [showEditModal, setEditModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    contactNumber: null,
    address: null,
    freeText: null,
    date: null,
    time: null,
    employee: null,
    OrgNr: null
  });

  const showAlert = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 1000); // Adjust the duration as needed (in milliseconds)
  };

  const handleStatusClick = async (bookingId, status) => {
  try {
    const db = firebase.firestore();
    const bookingRef = db.collection('Bookings').doc(bookingId);

    // Check if the booking exists
    const bookingDoc = await bookingRef.get();
    if (!bookingDoc.exists) {
      throw new Error(`Booking with ID ${bookingId} does not exist.`);
    }

    // Update the status field
    await bookingRef.set(
      {
        status: status
      },
      { merge: true }
    );

    console.log(`Status for booking with ID ${bookingId} set to ${status}.`);
    setLiveUpdate(!liveUpdate)
    {/*showAlert();*/}
  } catch (error) {
    console.error('Error updating booking status:', error);
    // Handle error, show an alert, etc.
  }
};

  const handleEditClick = (booking) => {
    setInputValues({
      contactNumber: booking.contactNumber,
      address: booking.address,
      freeText: booking.freeText,
      date: booking.date,
      time: booking.time,
      employee: booking.employee,
      OrgNr: booking.OrgNr,
      contactName: booking.contactName
    });
    setEditModal(true);
  };

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const closeModal = () => {
    setEditModal(false);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const db = firebase.firestore();
      const bookingsCollection = db.collection('Bookings');

      let query;
      if (fetchAll) {
        // Fetch all bookings if fetchAll is true
        query = bookingsCollection;
      } else {
        // Calculate the date two months ago
        const now = new Date();
        const oneMonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
        const formattedTwoMonthsAgo = oneMonthsAgo.toISOString().split('T')[0]; // Convert to 'yyyy-MM-dd'

        // Query the bookings that are after the calculated date
        query = bookingsCollection.where('date', '>', formattedTwoMonthsAgo);
      }
      const snapshot = await query.get();
      const bookingsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setBookings(bookingsData);
    };

    fetchBookings();
    
  }, [liveUpdate]); // Fetch bookings when the component mounts

  const handleFetchAll = () => {
    setFetchAll(true);
    setLiveUpdate(!liveUpdate)
  };

  useEffect (() => {
    const fetchOrgNames = async () => {
      const names = {};
      for (const booking of bookings) {
        const cleanedOrgNr = booking.OrgNr.replace(/\s/g, ''); // Remove spaces from OrgNr
        if (!names[cleanedOrgNr]) {
          const orgName = await getOrgName(cleanedOrgNr);
          names[cleanedOrgNr] = orgName;
        }
      }
      setOrgNames(names);
    };

    fetchOrgNames();
  }, [bookings]);


  const renderBookings = () => {
    const handleBookingClick = (bookingId) => {
      if (expandedBookingId === bookingId) {
        setExpandedBookingId(null);
      } else {
        setExpandedBookingId(bookingId);
      }
    };
  
    const filteredBookings = selectedEmployeeId
      ? bookings.filter((booking) => booking.employee === selectedEmployeeId)
      : bookings;
  
    const groupedBookings = filteredBookings.reduce((grouped, booking) => {
      const date = new Date(booking.date).toDateString();
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(booking);
      return grouped;
    }, {});
  
    const sortedDates = Object.keys(groupedBookings).sort(
      (a, b) => new Date(a) - new Date(b)
    );
  
    return sortedDates.map((date) => (
      <div key={date}>
        {groupedBookings[date]
        .filter(booking => {
          if (
            (filters.notContacted && booking.status === undefined) ||
            (filters.contacted && booking.status === 'contacted') ||
            (filters.visited && booking.status === 'visited') ||
            (filters.wait && booking.status === 'wait') ||
            (filters.sale && booking.status === 'positiv') ||
            (filters.sold && booking.status === 'positiv-sold') ||
            (filters.notSale && booking.status === 'negativ') ||
            (!filters.notContacted && !filters.contacted && !filters.visited && !filters.wait && !filters.sale && !filters.sold && !filters.notSale)
          ) {
            return true; // Include the booking if no filters are selected or the booking's status doesn't match the selected filters
          }
          return false; // Exclude the booking if any filter is selected and the booking's status matches the filter
        })
        .map((booking) => {
          const employeeInfo = data.Employees.find(
            (employee) => employee.userId === booking.employee
          );
          if (employeeInfo) {
            const fullName = `${employeeInfo.firstName} ${employeeInfo.lastName}`;
            const isExpanded = expandedBookingId === booking.id;
            const shouldRenderDate = date !== previousDate;
            previousDate = date;
            return (
              <div key={booking.id}>
                {shouldRenderDate && <h2>{date}</h2>}
                <div
                  className={`booking-element ${isExpanded ? 'expanded' : ''}`}
                  onClick={() => handleBookingClick(booking.id)}
                  style={{
                    background: booking.status === 'called' ? 'yellow' : (booking.status === 'completed' ? 'green' : '')
                  }}
                >
                  <p className={`booking-status-headerline ${getHeaderColor(booking.status)}`}></p>
                  <p>{fullName}</p>
                  <p>{booking.time}</p>
                  <p>{orgNames[booking.OrgNr.replace(/\s/g, '')] || 'Loading...'}</p>
                  <p>{booking.contactName}</p>
                  <p>{booking.contactNumber}</p>
                  <p className="booking-element-address">{booking.address}</p>
                </div>
                {isExpanded && (
                  <div className="additional-info">
                    <div className="booking-status-container">
                      <p 
                        className="status-contact-btn"
                        onClick={() => handleStatusClick(booking.id, "contacted")}
                      >
                        Kontaktet
                      </p>
                      <p 
                        className="status-visit-btn"
                        onClick={() => handleStatusClick(booking.id, "visited")}
                      > 
                        Besøkt
                      </p>
                      <p 
                        className="status-wait-btn"
                        onClick={() => handleStatusClick(booking.id, "wait")}
                      > 
                        Venter
                      </p>
                      <p 
                        className="status-sale-btn"
                        onClick={() => handleStatusClick(booking.id, "positiv")}
                      >
                        Til salg
                      </p>
                      <p 
                        className="status-sold-btn"
                        onClick={() => handleStatusClick(booking.id, "positiv-sold")}
                      >
                        Solgt
                      </p>
                      <p 
                        className="status-not-sale-btn"
                        onClick={() => handleStatusClick(booking.id, "negativ")}
                      >
                        Ikke salg
                      </p>
                    </div>
                    <p 
                      className="edit-btn"
                      onClick={() => handleEditClick(booking)}
                    >
                      Edit Booking
                    </p>
                    <div className="additional-info-sub-container">
                      <div className="name-org-phone-container">
                        <h4>{booking.contactName}</h4>
                        <p>Org.nr: {booking.OrgNr}</p>
                        <div className="booking-phone-container">
                          <p className="phone-icon" role="img" aria-label="Phone">
                            &#128222;
                          </p>
                          <p>: {booking.contactNumber}</p>
                        </div>
                      </div>
                      <div>
                        {booking.freeText.split('\n').map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                      </div>
                    </div>
                    
                    {/* Modal */}
                    {showEditModal && (
                      <div className="modal-edit">
                        {/* Modal Content */}
                        <h3>Endre Booking info</h3>
                        <div className="modal-edit-element-container">
                          <p>Kontaktperson: </p>
                          <input
                            type="text"
                            value={inputValues.contactName}
                            onChange={(e) => handleInputChange('contactName', e.target.value)}
                          />
                        </div>
                        <div className="modal-edit-element-container">
                          <p>Org.Nr: </p>
                          <input
                            type="text"
                            value={inputValues.OrgNr}
                            onChange={(e) => handleInputChange('OrgNr', e.target.value)}
                          />
                        </div>
                        <div className="modal-edit-element-container">
                          <p>Tlf: </p>
                          <input
                            type="text"
                            value={inputValues.contactNumber}
                            onChange={(e) => handleInputChange('contactNumber', e.target.value)}
                          />
                        </div>
                        <div className="modal-edit-element-container">
                          <p>Adresse: </p>
                          <input
                            type="text"
                            value={inputValues.address}
                            onChange={(e) => handleInputChange('address', e.target.value)}
                          />
                        </div>
                        <div className="modal-edit-element-container-text">
                          <p>InfoText: </p>
                          <textarea
                            rows="10"
                            value={inputValues.freeText}
                            onChange={(e) => handleInputChange('freeText', e.target.value)}
                          />
                        </div>
                        <div className="modal-edit-element-container">
                          <p>Tidspunkt:</p>
                          <input
                            type="date"
                            value={inputValues.date}
                            onChange={(e) => handleInputChange('date', e.target.value)}
                          />
                          <input
                            type="time"
                            value={inputValues.time}
                            onChange={(e) => handleInputChange('time', e.target.value)}
                          />
                        </div>
                        
                        {/* Close Button */}
                        <p 
                          className="edit-modal-submit-btn"
                          onClick={() => handleSubmitModal(booking)}
                        >
                          Lagre Endring
                        </p>
                        <button 
                          onClick={closeModal}
                          className="modal-close-btn"
                        >
                          Lukk
                        </button>
                      </div>
                    )}
                  </div> 
                )}
                
              </div>
            );
          }
          return null;
        })}
      </div>
    ));
  };

  const handleSubmitModal = async (booking) => {
    const db = firebase.firestore();
    const date = inputValues.date;
    const time = inputValues.time;
    const address = inputValues.address;
    const contactNumber = inputValues.contactNumber;
    const freeText = inputValues.freeText;
    const employee = inputValues.employee;
    const OrgNr = inputValues.OrgNr;
    const contactName = inputValues.contactName
  
    try {
      // Get a reference to the existing booking document
      const bookingDocRef = db.collection('Bookings').doc(booking.id);
  
      // Update the document with the new values
      await bookingDocRef.update({
        date: date,
        time: time,
        address: address,
        contactNumber: contactNumber,
        freeText: freeText,
        employee: employee,
        OrgNr: OrgNr,
        contactName: contactName
      });
  
      console.log('Booking document updated successfully.');
      setLiveUpdate(!liveUpdate)
      {/*alert('Booking er oppdatert!');*/}
      closeModal()
    } catch (error) {
      console.error('Error updating booking document:', error);
    }
  };

  const renderContent = () => {
    if (selectedChoice === 'Ny booking') {
      return (
        <div className="booking-container">
          <h2>Ny booking</h2>
          <div>
            <form className="new-booking-container" onSubmit={handleSubmit}>

                <select id="employee" name="employee" className="new-booking-element-scroll" required>
                <option value="" disabled selected>Velg Ansatt</option>
                {data.Employees.map((employee) => (
                  <option key={employee.userId} value={employee.userId}>
                    {employee.firstName} {employee.lastName}
                  </option>
                ))}
                </select>

                <input type="date" id="date" name="date" className="new-booking-element"/>
                <input type="time" id="time" name="time" className="new-booking-element"/>
                <input type="text" id="address" name="address" placeholder="Adresse" required className="new-booking-element"/>
                <input type="text" id="contact-name" name="contact-name" placeholder="Kontaktperson" required className="new-booking-element"/>
                <input type="text" id="contact-number" name="contact-number" placeholder="Telefonnummer kontaktperson" required className="new-booking-element"/>
                <input type="text" id="organisation-number" name="organisation-number" placeholder="Org.nr (xxxxxxxxx)" className="new-booking-element"/>
                <textarea id="freeText" name="freeText" placeholder="Info til ansatt" className="new-booking-element-free-text"></textarea>

              <button type="submit" className="new-booking-submit-btn">Submit</button>
            </form>
          </div>
        </div>
      );
    } else if (selectedChoice === 'Alle bookinger') {
      return (
        <div className="booking-container">
          <h2>Alle bookinger</h2>
            <div className="calanderinfo-container">
              <select
                id="employee"
                name="employee"
                className="all-booking-element-scroll"
                required
                onChange={(e) => setSelectedEmployeeId(e.target.value)}
              >
                <option value="" selected>
                  Alle Ansatte
                </option>
                {data.Employees.map((employee) => (
                  <option key={employee.userId} value={employee.userId}>
                    {employee.firstName} {employee.lastName}
                  </option>
                ))}
              </select>
              <div className="booking-status-filter">
                <div>
                  <input 
                    id="not-contacted-filter" 
                    type="checkbox" 
                    checked={filters.notContacted}
                    onChange={(e) => setFilters({ ...filters, notContacted: e.target.checked })}
                  />
                  <label htmlFor="not-contacted-filter" className="status-not-contact-btn">.</label>
                  <label htmlFor="not-contacted-filter">Ikke kontaktet</label>
                </div>
                <div>
                  <input 
                    id="contacted-filter" 
                    type="checkbox"
                    checked={filters.contacted}
                    onChange={(e) => setFilters({ ...filters, contacted: e.target.checked })}
                  />
                  <label htmlFor="contacted-filter" className="status-contact-btn">.</label>
                  <label htmlFor="contacted-filter">Kontaktet</label> 
                </div>
                <div>
                  <input 
                    id="visited-filter" 
                    type="checkbox"
                    checked={filters.visited}
                    onChange={(e) => setFilters({ ...filters, visited: e.target.checked })}
                  />
                  <label htmlFor="visited-filter" className="status-visit-btn">.</label>
                  <label htmlFor="visited-filter">Besøkt</label>
                </div>
                <div>
                  <input 
                    id="wait-filter" 
                    type="checkbox"
                    checked={filters.wait}
                    onChange={(e) => setFilters({ ...filters, wait: e.target.checked })}
                  />
                  <label htmlFor="wait-filter" className="status-wait-btn">.</label>
                  <label htmlFor="wait-filter">Venter</label>
                </div>
                <div>
                  <input 
                    id="sale-filter" 
                    type="checkbox"
                    checked={filters.sale}
                    onChange={(e) => setFilters({ ...filters, sale: e.target.checked })}
                  />
                  <label htmlFor="sale-filter" className="status-sale-btn">.</label>
                  <label htmlFor="sale-filter">Til salg</label>
                </div>
                <div>
                  <input 
                    id="sold-filter" 
                    type="checkbox"
                    checked={filters.sold}
                    onChange={(e) => setFilters({ ...filters, sold: e.target.checked })}
                  />
                  <label htmlFor="sold-filter" className="status-sold-btn">.</label>
                  <label htmlFor="sold-filter">Solgt</label>
                </div>
                <div>
                  <input 
                    id="not-sale-filter" 
                    type="checkbox"
                    checked={filters.notSale}
                    onChange={(e) => setFilters({ ...filters, notSale: e.target.checked })}
                  />
                  <label htmlFor="not-sale-filter" className="status-not-sale-btn">.</label>
                  <label htmlFor="not-sale-filter">Ikke salg</label>
                </div>
              </div>
              <div>
                <p className="fetch-old-btn" onClick={handleFetchAll}>Hent alle tidligere bookinger</p>
              </div>
              {renderBookings()}
            </div>
        </div>
      );
    }

    return null; // No content to show
  };

  return (
    <div>
      {showToast && (
        <div style={toastStyle}>
          <p>Status endret</p>
        </div>
      )}
      <h1 className="header">Bookinger</h1>
      <div className="booking-choice-container">
        <p
          className="booking-choice"
          onClick={() => setSelectedChoice('Ny booking')}
        >
          Registrer Ny Booking
        </p>
        <p
          className="booking-choice"
          onClick={() => setSelectedChoice('Alle bookinger')}
        >
          Vis Alle Bookinger
        </p>
      </div>

      {renderContent()} {/* Render the selected content */}
    </div>
  );
};

export default Bookinger;