const translations = {
    no: {
      Header: "Lagrede søk",
      Info: "Her kan du lagre søk og motta e-post varsling når slike objekter kommer for salg på VAMU.no.",
      NoSave: "Du har ingen lagrede søk",
      Remove: "Fjern",
      Save: "Lagre søk"
    },
    en: {
      Header: "Saved Searches",
      Info: "Here you can save searches and receive email notifications when such objects are listed for sale on VAMU.no.",
      NoSave: "You have no saved searches",
      Remove: "Remove",
      Save: "Save Search"
    },
    pl: {
      Header: "Zapisane wyszukiwania",
      Info: "Tutaj możesz zapisać wyszukiwania i otrzymywać powiadomienia e-mail, gdy takie obiekty zostaną wystawione na sprzedaż na VAMU.no.",
      NoSave: "Nie masz zapisanych wyszukiwań",
      Remove: "Usuń",
      Save: "Zapisz wyszukiwanie"
    },
    uk: {
      Header: "Збережені пошуки",
      Info: "Тут ви можете зберегти пошук і отримувати електронні сповіщення, коли такі об'єкти будуть виставлені на продаж на VAMU.no.",
      NoSave: "У вас немає збережених пошуків",
      Remove: "Видалити",
      Save: "Зберегти пошук"
    },
    gr: {
      Header: "Gespeicherte Suchen",
      Info: "Hier können Sie Suchen speichern und E-Mail-Benachrichtigungen erhalten, wenn solche Objekte zum Verkauf auf VAMU.no angeboten werden.",
      NoSave: "Sie haben keine gespeicherten Suchen",
      Remove: "Entfernen",
      Save: "Suche speichern"
    }
  };
  
  export default translations;
  