const translations = {
  no: {
    contactUs: 'Kontakt Oss',
    mail: "E-post",
    phone: "Tlf",
    org: "Organisasjons Nr",
    follow: "Følg oss",
    logoInfo: "Videreformidling av maskiner og utstyr"
  },
  en: {
    contactUs: 'Contact us',
    mail: "E-mail",
    phone: "Tel",
    org: "Company No",
    follow: "Follow us",
    logoInfo: "Reselling of machines and equipment"
  },
  pl: {
    contactUs: 'Skontaktuj się z nami',
    mail: "E-mail",
    phone: "Tel",
    org: "Nr firmy",
    follow: "Śledź nas",
    logoInfo: "Odprzedaż maszyn i sprzętu"
  },
  uk: {
    contactUs: 'Зв язатися з нами',
    mail: "електронна пошта",
    phone: "тел",
    org: "№ компанії",
    follow: "Слідкуйте за нами",
    logoInfo: "Перепродаж машин та обладнання",
  },
  gr: {
    contactUs: "Kontaktiere uns",
    mail: "Email",
    phone: "Tel",
    org: "Organisationsnr",
    follow: "Folgen Sie uns",
    logoInfo: "Weiterverkauf von Maschinen und Ausrüstung"
  }
};

const translateText = (textKey, currentLanguage) => {
  return translations[currentLanguage][textKey];
};

export {translations, translateText};