const getOrgName = async (orgNr) => {
    const url  = `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${orgNr}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const orgName = data._embedded?.enheter[0]?.navn || "Bedriftsnavn ikke funnet";
      return orgName;
    } catch (error) {
      console.error('Error:', error);
      return "";
    }
  }

export default getOrgName;