import React from 'react';

const FooterElement = ({ label, value }) => {
  return (
    <div>
        <p className="footer-label">{label}</p>
        <p className="footer-value">{value}</p>
    </div>
  );
};

export default FooterElement;