
  const getHeaderColor = (status) => {
    switch (status) {
      case 'contacted':
        return 'status-contact-btn';
      case 'visited':
        return 'status-visit-btn';
      case 'positiv':
        return 'status-sale-btn';
      case 'positiv-sold':
        return 'status-sold-btn';
      case 'wait':
        return 'status-wait-btn';
      case 'negativ':
        return 'status-not-sale-btn';
      default:
        return ''; // Return default class if status doesn't match any case
    }
  };

  export default getHeaderColor;