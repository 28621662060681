import React from 'react';

const MobileCountyMap = ({ object}) => {
    return (
        <div className="mobile-county-map">
              {object.county === 'Nordland' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Norway_Counties_Nordland_Position.svg/1280px-Norway_Counties_Nordland_Position.svg.png"
                  alt="Nordland"
                  width="100"
                  height="100"
                />
              )}

              {object.county === 'Trøndelag' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Norway_Counties_Tr%C3%B8ndelag_Position.svg/1280px-Norway_Counties_Tr%C3%B8ndelag_Position.svg.png"
                  alt="Trøndelag"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Møre og Romsdal' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Norway_Counties_M%C3%B8re_og_Romsdal_Position.svg/1024px-Norway_Counties_M%C3%B8re_og_Romsdal_Position.svg.png"
                  alt="Møre og Romsdal"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Vestland' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/10/Norway_Counties_Vestland_Position.svg/1024px-Norway_Counties_Vestland_Position.svg.png"
                  alt="Vestland"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Sogn og Fjordane' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Norway_Counties_Sogn_og_Fjordane_Position.svg/1024px-Norway_Counties_Sogn_og_Fjordane_Position.svg.png"
                  alt="Sogn og Fjordane"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Innlandet' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Norway_Counties_Innlandet_Position.svg/1024px-Norway_Counties_Innlandet_Position.svg.png"
                  alt="Innlandet"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Hedmark' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Norway_Counties_Hedmark_Position.svg/1024px-Norway_Counties_Hedmark_Position.svg.png"
                  alt="Hedmark"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Oslo' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Norway_Counties_Oslo_Position.svg/1280px-Norway_Counties_Oslo_Position.svg.png"
                  alt="Oslo"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Vestfold' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Norway_Counties_Vestfold_Position.svg/1280px-Norway_Counties_Vestfold_Position.svg.png"
                alt="Vestfold"
                width="100"
                height="100"
              />
              )}
              {object.county === 'Akershus' && (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Akershus_2024_%E2%80%93.svg/512px-Akershus_2024_%E2%80%93.svg.png"
                  alt="Akershus"
                  width="100"
                  height="100"
                />
              )}
              {object.county === 'Rogaland' && (
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Norway_Counties_Rogaland_Position.svg/1280px-Norway_Counties_Rogaland_Position.svg.png"
                    alt="Rogaland"
                    width="100"
                    height="100"
                />
                )}
            {object.county === 'Agder' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Norway_Counties_Agder_Position.svg/1280px-Norway_Counties_Agder_Position.svg.png"
                alt="Agder"
                width="100"
                height="100"
              />
            )}
            {object.county === 'Østfold' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Norway_Counties_%C3%98stfold_Position.svg/1280px-Norway_Counties_%C3%98stfold_Position.svg.png"
                alt="Østfold"
                width="100"
                height="100"
              />
            )}
            {object.county === 'Buskerud' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Norway_Counties_Buskerud_Position.svg/1280px-Norway_Counties_Buskerud_Position.svg.png"
                alt="Buskerud"
                width="100"
                height="100"
              />
            )}
            {object.county === 'Telemark' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Norway_Counties_Telemark_Position.svg/1280px-Norway_Counties_Telemark_Position.svg.png"
                alt="Telemark"
                width="100"
                height="100"
              />
            )}
            {object.county === 'Troms' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Norway_Counties_Troms_Position.svg/1280px-Norway_Counties_Troms_Position.svg.png"
                alt="Troms"
                width="100"
                height="100"
              />
            )}
            {object.county === 'Finnmark' && (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Norway_Counties_Finnmark_Position.svg/240px-Norway_Counties_Finnmark_Position.svg.png"
                alt="Finnmark"
                width="100"
                height="100"
              />
            )}
        </div>
    );
};

export default MobileCountyMap;