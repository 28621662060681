
  const getResponsStyle = (respons, kontaktLogg) => {
    switch (respons) {
      case 'Positiv':
        return 'positive-style';
        case 'Nøytral':
          if (kontaktLogg.length === 0) {
            return 'not-called-style';
          } else {
            return 'neutral-style';
          }
      case 'Negativ':
        return 'negative-style';
      case 'IkkeSvar':
        return 'no-answer-style';
      default:
        return '';
    }
  };

export default getResponsStyle;