const translations = 
{
  no: {
    SaleInfoHeader: "Informasjon om Salg",
    HowItWorks: "Hvordan fungerer det?",
    StartInfo: "Vamu ønsker å hjelpe deg med å få mest mulig ut av dine salgsobjekter. Enten du har anleggsmaskiner, lastebiler, busser, tilbehør eller annet utstyr du ønsker å selge, er vi her for å bistå deg gjennom hele prosessen. Vi forstår at det å selge utstyr kan være en tidkrevende og utfordrende oppgave. Derfor har vi skapt en enkel og effektiv løsning for deg. Ved å samarbeide med Vamu, kan du dra nytte av vårt nettverk og vår ekspertise innen salg og markedsføring.",
    HowItWorksInfo1: {
      Title: "Ta kontakt med oss",
      Description: "Besøk vår kontaktside og gi oss beskjed om hvilke objekter du ønsker å selge. Vi vil sette opp en avtale for å diskutere detaljene rundt salget."
    },
    HowItWorksInfo2: {
      Title: "Objektvurdering",
      Description: "Våre salgsrådgivere vil inspisere og vurdere objektene dine nøye. Vi tar hensyn til faktorer som tilstand, markedsetterspørsel og konkurransesituasjonen for å gi deg en realistisk og konkurransedyktig prisvurdering."
    },
    HowItWorksInfo3: {
      Title: "Utarbeidelse av markedsføringsmateriell",
      Description: "Vi tar oss av alle detaljer knyttet til markedsføring av objektene dine. Vårt team vil utarbeide tiltalende og informative annonser, inkludert bilder, beskrivelser og tekniske spesifikasjoner, for å tiltrekke potensielle kjøpere."
    },
    HowItWorksInfo4: {
      Title: "Markedsføring og promotering",
      Description: "Vi bruker vårt nettverk og vår ekspertise innen markedsføring for å sørge for at dine salgsobjekter når ut til en bred målgruppe. Vi benytter oss av ulike kanaler, både nasjonalt og internasjonalt, for å maksimere eksponeringen og øke sjansene for en rask og lønnsom salgsprosess."
    },
    HowItWorksInfo5: {
      Title: "Kvalifisering av potensielle kjøpere",
      Description: "Vi håndterer forespørsler og kommunikasjon fra potensielle kjøpere. Vi kvalifiserer interesserte parter og sørger for at kun seriøse og potensielle kjøpere får tilgang til dine salgsobjekter."
    },
    HowItWorksInfo6: {
      Title: "Forhandlinger og avslutning",
      Description: "Våre meglere tar seg av forhandlingene med potensielle kjøpere for å oppnå den beste prisen for dine objekter. Vi sørger også for å håndtere kontrakter og alle formelle aspekter ved salget for å sikre en smidig og bekymringsfri avslutning."
    },
    HowItWorksInfo7: {
      Title: "Oppgjør",
      Description: "Når salget er gjennomført, tar vi oss av oppgjøret og sikrer at du mottar betalingen i henhold til avtalen."
    },
    CallToAction: "Ta det neste skrittet!",
    CallToActionInfo: "Har du noe å selge? Ta kontakt med Vamu i dag, og la oss hjelpe deg med å oppnå suksess i salgsprosessen. Vi er klare til å lytte, veilede og jobbe sammen med deg for å oppnå optimale resultater.",
    AdditionalInfo: "Vamu er dedikert til å gjøre salgsprosessen så enkel og problemfri som mulig for deg. Vi tar på oss arbeidet og holder deg oppdatert underveis, slik at du kan fokusere på din daglige drift og samtidig få mest mulig ut av dine salgsobjekter."
  },
  en: {
    SaleInfoHeader: "Sales Information",
    HowItWorks: "How It Works?",
    StartInfo: "Vamu wants to help you get the most out of your sales items. Whether you have construction machinery, trucks, buses, accessories, or other equipment you want to sell, we are here to assist you through the entire process. We understand that selling equipment can be a time-consuming and challenging task. Therefore, we have created a simple and effective solution for you. By collaborating with Vamu, you can benefit from our extensive network and expertise in sales and marketing.",
    HowItWorksInfo1: {
      Title: "Contact Us",
      Description: "Visit our contact page and let us know which items you want to sell. We will set up a meeting to discuss the details of the sale."
    },
    HowItWorksInfo2: {
      Title: "Object Evaluation",
      Description: "Our sales advisors will inspect and assess your items carefully. We take into account factors such as condition, market demand, and competitive situation to provide you with a realistic and competitive price assessment."
    },
    HowItWorksInfo3: {
      Title: "Preparation of Marketing Materials",
      Description: "We take care of all the details related to marketing your items. Our team will create appealing and informative ads, including images, descriptions, and technical specifications, to attract potential buyers."
    },
    HowItWorksInfo4: {
      Title: "Marketing and Promotion",
      Description: "We use our network and expertise in marketing to ensure that your sales items reach a wide audience. We utilize various channels, both nationally and internationally, to maximize exposure and increase the chances of a quick and profitable sales process."
    },
    HowItWorksInfo5: {
      Title: "Qualification of Potential Buyers",
      Description: "We handle inquiries and communication from potential buyers. We qualify interested parties and ensure that only serious and potential buyers gain access to your sales items."
    },
    HowItWorksInfo6: {
      Title: "Negotiation and Closing",
      Description: "Our brokers handle negotiations with potential buyers to achieve the best price for your items. We also take care of contracts and all formal aspects of the sale to ensure a smooth and worry-free closing."
    },
    HowItWorksInfo7: {
      Title: "Settlement",
      Description: "Once the sale is completed, we take care of the settlement and ensure that you receive payment according to the agreement."
    },
    CallToAction: "Take the Next Step!",
    CallToActionInfo: "Do you have something to sell? Contact Vamu today and let us help you achieve success in the sales process. We are ready to listen, guide, and work together with you to achieve optimal results.",
    AdditionalInfo: "Vamu is dedicated to making the sales process as simple and hassle-free as possible for you. We take care of the work and keep you updated along the way, allowing you to focus on your daily operations while getting the most out of your sales items."
  },
  pl: {
    SaleInfoHeader: "Informacje o Sprzedaży",
    HowItWorks: "Jak to działa?",
    StartInfo: "Vamu chce pomóc Ci w jak najlepszym wykorzystaniu Twoich przedmiotów na sprzedaż. Bez względu na to, czy masz maszyny budowlane, samochody ciężarowe, autobusy, akcesoria czy inne wyposażenie, które chcesz sprzedać, jesteśmy tutaj, aby Ci pomóc przez cały proces. Rozumiemy, że sprzedaż sprzętu może być czasochłonnym i trudnym zadaniem. Dlatego stworzyliśmy dla Ciebie prostą i skuteczną rozwiązanie. Współpracując z Vamu, możesz skorzystać z naszej szerokiej sieci kontaktów i naszej wiedzy w zakresie sprzedaży i marketingu.",
    HowItWorksInfo1: {
      Title: "Skontaktuj się z nami",
      Description: "Odwiedź naszą stronę kontaktową i daj nam znać, jakie przedmioty chcesz sprzedać. Umówimy się na spotkanie, aby omówić szczegóły sprzedaży."
    },
    HowItWorksInfo2: {
      Title: "Ocena przedmiotu",
      Description: "Nasi doradcy ds. sprzedaży dokładnie sprawdzą i ocenią Twoje przedmioty. Bierzemy pod uwagę takie czynniki jak stan, popyt na rynku i sytuacja konkurencyjna, aby dostarczyć Ci realistyczną i konkurencyjną ocenę cenową."
    },
    HowItWorksInfo3: {
      Title: "Przygotowanie materiałów marketingowych",
      Description: "Zajmujemy się wszystkimi szczegółami związanymi z marketingiem Twoich przedmiotów. Nasz zespół przygotuje atrakcyjne i informacyjne reklamy, w tym zdjęcia, opisy i specyfikacje techniczne, aby przyciągnąć potencjalnych klientów."
    },
    HowItWorksInfo4: {
      Title: "Marketing i promocja",
      Description: "Wykorzystujemy naszą sieć kontaktów i wiedzę z zakresu marketingu, aby zapewnić, że Twoje przedmioty sprzedaży trafią do szerokiej publiczności. Korzystamy z różnych kanałów, zarówno krajowych, jak i międzynarodowych, aby maksymalnie zwiększyć ich ekspozycję i szanse na szybką i dochodową sprzedaż."
    },
    HowItWorksInfo5: {
      Title: "Kwalifikacja potencjalnych klientów",
      Description: "Zajmujemy się zapytaniami i komunikacją od potencjalnych klientów. Kwalifikujemy zainteresowane strony i zapewniamy, że dostęp do Twoich przedmiotów sprzedaży uzyskują tylko poważni i potencjalni kupujący."
    },
    HowItWorksInfo6: {
      Title: "Negocjacje i zakończenie",
      Description: "Nasi brokerzy zajmują się negocjacjami z potencjalnymi klientami, aby uzyskać najlepszą cenę za Twoje przedmioty. Dbamy również o umowy i wszystkie formalności związane ze sprzedażą, aby zapewnić płynne i bezproblemowe zakończenie transakcji."
    },
    HowItWorksInfo7: {
      Title: "Rozliczenie",
      Description: "Po zakończeniu sprzedaży zajmujemy się rozliczeniem i zapewniamy, że otrzymujesz płatność zgodnie z umową."
    },
    CallToAction: "Przejdź do następnego kroku!",
    CallToActionInfo: "Masz coś do sprzedania? Skontaktuj się z Vamu już dziś, a pozwól nam pomóc Ci osiągnąć sukces w procesie sprzedaży. Jesteśmy gotowi słuchać, doradzać i współpracować z Tobą, aby osiągnąć optymalne wyniki.",
    AdditionalInfo: "Vamu jest dedykowane temu, aby proces sprzedaży był dla Ciebie jak najprostszy i bezproblemowy. Zajmujemy się pracą i informujemy Cię na bieżąco, pozwalając Ci skupić się na codziennych operacjach, jednocześnie maksymalizując korzyści ze sprzedawanych przedmiotów."
  },
  uk: {
    SaleInfoHeader: "Інформація про продаж",
    HowItWorks: "Як це працює?",
    StartInfo: "Vamu хоче допомогти вам отримати максимальну користь від вашого продажу. Незалежно від того, чи маєте ви будівельну техніку, вантажівки, автобуси, аксесуари чи інше обладнання, яке ви хочете продати, ми тут, щоб допомогти вам на протязі всього процесу. Ми розуміємо, що продаж обладнання може бути витратним часом і викликати складнощі. Тому ми створили просте і ефективне рішення для вас. Співпрацюючи з Vamu, ви можете скористатися нашою широкою мережею контактів та нашим досвідом в області продажу та маркетингу.",
    HowItWorksInfo1: {
      Title: "Зв'яжіться з нами",
      Description: "Відвідайте нашу сторінку контактів і повідомте нам, які предмети ви хочете продати. Ми заплануємо зустріч, щоб обговорити деталі продажу."
    },
    HowItWorksInfo2: {
      Title: "Оцінка об'єкта",
      Description: "Наші консультанти з продажу уважно перевірять і оцінять ваші предмети. Ми беремо до уваги такі чинники, як стан, попит на ринку та конкурентна ситуація, щоб надати вам реалістичну і конкурентоспроможну оцінку ціни."
    },
    HowItWorksInfo3: {
      Title: "Підготовка маркетингових матеріалів",
      Description: "Ми займаємося всіма деталями, пов'язаними з маркетингом ваших предметів. Наша команда підготує привабливі та інформативні рекламні матеріали, включаючи зображення, описи та технічні характеристики, щоб привернути потенційних покупців."
    },
    HowItWorksInfo4: {
      Title: "Маркетинг та просування",
      Description: "Ми використовуємо нашу мережу контактів та досвід в маркетингу, щоб забезпечити, що ваші предмети продажу дійшли до широкої аудиторії. Ми використовуємо різні канали, як національні, так і міжнародні, щоб максимізувати їх експозицію та збільшити шанси на швидку та прибуткову продаж."
    },
    HowItWorksInfo5: {
      Title: "Кваліфікація потенційних покупців",
      Description: "Ми займаємося запитами та комунікацією від потенційних покупців. Ми кваліфікуємо зацікавлені сторони та забезпечуємо, що доступ до ваших предметів продажу отримують лише серйозні та потенційні покупці."
    },
    HowItWorksInfo6: {
      Title: "Переговори та закриття",
      Description: "Наші брокери займаються переговорами з потенційними покупцями, щоб досягнути найкращої ціни за ваші предмети. Ми також дбаємо про угоди та всі формальні аспекти продажу, щоб забезпечити плавне і безпечне завершення угоди."
    },
    HowItWorksInfo7: {
      Title: "Розрахунок",
      Description: "Після завершення продажу ми займаємося розрахунками та забезпечуємо, що ви отримаєте платіж відповідно до угоди."
    },
    CallToAction: "Перейдіть до наступного кроку!",
    CallToActionInfo: "Маєте щось продати? Зверніться до Vamu вже сьогодні, і дозвольте нам допомогти вам досягти успіху в процесі продажу. Ми готові слухати, консультувати і співпрацювати з вами, щоб досягти оптимальних результатів.",
    AdditionalInfo: "Vamu присвячений тому, щоб процес продажу був для вас як найпростішим і безпечним. Ми беремо на себе роботу і тримаємо вас в курсі подій, щоб ви могли сконцентруватися на своїй повсякденній діяльності, отримуючи максимальну вигоду від продажу ваших товарів."
  },
  gr: {
    SaleInfoHeader: "Verkaufsinformationen",
    HowItWorks: "Wie funktioniert es?",
    StartInfo: "Vamu möchte Ihnen helfen, das Beste aus Ihren Verkaufsartikeln herauszuholen. Egal, ob Sie Baumaschinen, Lastwagen, Busse, Zubehör oder andere Geräte verkaufen möchten, wir sind hier, um Sie durch den gesamten Prozess zu unterstützen. Wir wissen, dass der Verkauf von Ausrüstung zeitaufwendig und herausfordernd sein kann. Daher haben wir für Sie eine einfache und effektive Lösung entwickelt. Durch die Zusammenarbeit mit Vamu können Sie von unserem umfangreichen Netzwerk und unserer Expertise im Vertrieb und Marketing profitieren.",
    HowItWorksInfo1: {
      Title: "Kontaktieren Sie uns",
      Description: "Besuchen Sie unsere Kontaktseite und lassen Sie uns wissen, welche Artikel Sie verkaufen möchten. Wir vereinbaren einen Termin, um die Einzelheiten des Verkaufs zu besprechen."
    },
    HowItWorksInfo2: {
      Title: "Objektbewertung",
      Description: "Unsere Verkaufsberater werden Ihre Artikel sorgfältig prüfen und bewerten. Wir berücksichtigen Faktoren wie Zustand, Marktnachfrage und Wettbewerbssituation, um Ihnen eine realistische und wettbewerbsfähige Preisbewertung zu geben."
    },
    HowItWorksInfo3: {
      Title: "Erstellung von Marketingmaterialien",
      Description: "Wir kümmern uns um alle Details im Zusammenhang mit der Vermarktung Ihrer Artikel. Unser Team wird ansprechende und informative Anzeigen erstellen, einschließlich Bilder, Beschreibungen und technische Spezifikationen, um potenzielle Käufer anzuziehen."
    },
    HowItWorksInfo4: {
      Title: "Marketing und Promotion",
      Description: "Wir nutzen unser Netzwerk und unser Know-how im Marketing, um sicherzustellen, dass Ihre Verkaufsartikel eine breite Zielgruppe erreichen. Wir nutzen verschiedene Kanäle, sowohl national als auch international, um die Reichweite zu maximieren und die Chancen auf einen schnellen und profitablen Verkaufsprozess zu erhöhen."
    },
    HowItWorksInfo5: {
      Title: "Qualifizierung potenzieller Käufer",
      Description: "Wir bearbeiten Anfragen und Kommunikation von potenziellen Käufern. Wir qualifizieren interessierte Parteien und stellen sicher, dass nur seriöse und potenzielle Käufer Zugang zu Ihren Verkaufsartikeln erhalten."
    },
    HowItWorksInfo6: {
      Title: "Verhandlung und Abschluss",
      Description: "Unsere Makler kümmern sich um Verhandlungen mit potenziellen Käufern, um den besten Preis für Ihre Artikel zu erzielen. Wir kümmern uns auch um Verträge und alle formalen Aspekte des Verkaufs, um einen reibungslosen und sorgenfreien Abschluss zu gewährleisten."
    },
    HowItWorksInfo7: {
      Title: "Abwicklung",
      Description: "Nach Abschluss des Verkaufs kümmern wir uns um die Abwicklung und stellen sicher, dass Sie gemäß der Vereinbarung bezahlt werden."
    },
    CallToAction: "Nehmen Sie den nächsten Schritt!",
    CallToActionInfo: "Haben Sie etwas zu verkaufen? Kontaktieren Sie Vamu noch heute und lassen Sie uns Ihnen helfen, Erfolg im Verkaufsprozess zu erzielen. Wir sind bereit zuzuhören, zu beraten und mit Ihnen zusammenzuarbeiten, um optimale Ergebnisse zu erzielen.",
    AdditionalInfo: "Vamu ist bestrebt, den Verkaufsprozess für Sie so einfach und stressfrei wie möglich zu gestalten. Wir kümmern uns um die Arbeit und halten Sie auf dem Laufenden, damit Sie sich auf Ihr tägliches Geschäft konzentrieren können, während Sie das Beste aus Ihren Verkaufsartikeln herausholen."
  }
};

export default translations;