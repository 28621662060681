const Terms_GR = `
<div>
    <h3>Allgemeine Geschäftsbedingungen</h3>
    <p>
        Diese allgemeinen Verkaufs- und Lieferbedingungen gelten für alle Angebote, Verkäufe und Lieferungen von VAMU AS.
        Dies gilt unabhängig davon, ob VAMU AS selbst als Verkäufer/Eigentümer, Vermittler für Verkäufer/Eigentümer oder Lieferant für Verkäufer/Eigentümer fungiert.
        Wenn Verkaufsbedingungen das "Produkt" betreffen, ist dies sowohl das eigentliche Produkt, das verkauft wird, als auch die enthaltenen Geräte und Zubehörteile.
    </p>
    <p>
        Abweichungen von den Verkaufsbedingungen müssen schriftlich zwischen den Parteien vereinbart werden.
        Durch Vereinbarung werden diese Verkaufsbedingungen, die Auftragsbestätigung und der Kaufvertrag mit etwaigen Anlagen verstanden.
        Im Falle eines Konflikts zwischen einer einzelnen Bestellung und den Verkaufsbedingungen haben die Auftragsbestätigung und/oder der Kaufvertrag Vorrang.
        Sofern in diesen Bedingungen nicht ausdrücklich anders angegeben, gilt das Kaufgesetz vom 13. Mai 1988, Nr. 27.
        Im Falle eines Widerspruchs zwischen dem Kaufgesetz und diesen Verkaufsbedingungen haben die Verkaufsbedingungen Vorrang, sofern das Gesetz nicht zwingend vorgeschrieben ist.
        Alle Angebote, Verkäufe und Lieferungen von VAMU AS unterliegen diesen Bedingungen, sofern nicht schriftlich etwas anderes vereinbart wurde.
        Die eigenen allgemeinen oder spezifischen Einkaufsbedingungen des Käufers gelten nicht, es sei denn, VAMU AS hat diesen schriftlich zugestimmt.
    </p>
</div>
`

export default Terms_GR;