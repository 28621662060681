import React, { useState, useEffect } from 'react';
import './OmOss.css';
import InfoBox from './info-box.js';

// Image import
import img_needs from "../../imgs/About/economic-safety.jpg";
import img_safety from "../../imgs/About/economic-safety.jpg";
import img_global from "../../imgs/About/global-network.jpg";
import img_financing from "../../imgs/About/financing.jpg";
import img_laws from "../../imgs/About/laws.jpg";
import img_sale from "../../imgs/About/sale-for-you.jpg";
import img_contact from "../../imgs/About/contact-us.jpg";
import img_economy from "../../imgs/About/economy.jpg";
import img_easy from "../../imgs/About/easy-process.jpg";

import translations from "./translations.js";

const OmOss = ({currentLanguage}) => {

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when this component mounts
  }, []);

  // Function to translate text based on the current language
  const translateText = (textKey) => {
    // Split the textKey by '.' to access nested properties
    const keys = textKey.split('.');
    // Get the translated text based on the current language
    let translatedText = translations[currentLanguage];
    for (const key of keys) {
        translatedText = translatedText[key];
    }
    return translatedText;
  };

  return (
    <div className="main-container">
      <h1 className="header-omoss">{translateText("Header")}</h1>
      <div className="info-container">
        <div className="top-info-container">
          <p>
            {translateText("TopInfo")}
          </p>
        </div>
        <InfoBox
          type="1"
          img={img_needs}
          Title={translateText("InfoBox1Title")}
          Description={translateText("InfoBox1Description")}
        />
        <InfoBox
          type="2"
          img={img_global}
          Title={translateText("InfoBox2Title")}
          Description={translateText("InfoBox2Description")}
        />
        <InfoBox
          type="1"
          img={img_economy}
          Title={translateText("InfoBox3Title")}
          Description={translateText("InfoBox3Description")}
        />
        <InfoBox
          type="2"
          img={img_easy}
          Title={translateText("InfoBox4Title")}
          Description={translateText("InfoBox4Description")}
        />
        <InfoBox
          type="1"
          img={img_sale}
          Title={translateText("InfoBox5Title")}
          Description={translateText("InfoBox5Description")}
        />
        <InfoBox
          type="2"
          img={img_contact}
          Title={translateText("InfoBox6Title")}
          Description = {translateText("InfoBox6Description")}
        />
        <p className="open-aktsomhet" onClick={toggleVisibility}>Les vår aktsomhetsvurdering i henhold til åpenhetsloven</p>
        {isVisible && (
          <div className="aktsomhet">
            <h2>Aktsomhetsvurdering i henhold til Åpenhetsloven</h2>
              <h4>1. Innledning</h4>
              <p>
              Denne aktsomhetsvurderingen er utarbeidet i henhold til kravene i Åpenhetsloven og gjelder VAMU AS, 
              en virksomhet som spesialiserer seg på videresalg av lastebiler og anleggsutstyr både i det norske markedet 
              og for eksport. Formålet med denne vurderingen er å sikre at virksomheten overholder menneskerettigheter og 
              sikrer anstendige arbeidsforhold i alle deler av verdikjeden.
              </p>
              <h4>2. Overordnet risikovurdering</h4>
              <p>Som en aktør i en global forsyningskjede, og spesielt som eksportør, står virksomheten overfor risikoer 
                knyttet til at utstyret kan brukes på måter som medfører brudd på menneskerettigheter. Dette kan omfatte 
                bruk av lastebiler og anleggsutstyr i konfliktområder, i prosjekter som fører til tvangsforflytning av mennesker, 
                eller i miljøer med alvorlige arbeidsforhold.
              </p>
              <h4>3.Vurdering av eksportmarkedene</h4>
              <p>Virksomheten har gjennomført en grundig vurdering av eksportmarkedene, med fokus på:</p>

              <p>
                <b>Mottakerlandets menneskerettighetssituasjon:</b> Analyse av landets status i internasjonale
                menneskerettighetsindekser og rapporter fra organisasjoner som FN og Amnesty International.
               </p>
               <p>
               <b>Kundevurdering:</b> Bakgrunnssjekker av mottakere, med vekt på deres tidligere praksis og
                eventuell tilknytning til brudd på menneskerettigheter.
               </p>
               <p>
               <b>Sluttbrukeranalyse:</b> Vurdering av sannsynlig bruk av utstyret, inkludert hvilke sektorer
                og prosjekter det skal benyttes i, med fokus på høy-risiko-prosjekter.
               </p>

               <h4>4. Tiltak for å redusere risiko</h4>
                <p>
                  For å håndtere identifiserte risikoer ved eksport har VAMU AS iverksatt følgende tiltak:
                </p>
                <p>
                <b>Strenge eksportkontroller:</b> Implementering av strenge retningslinjer for eksport, inkludert krav til
                 dokumentasjon av sluttbruk og forpliktelser om ikke å bruke utstyret i strid med menneskerettigheter.
                </p>
                <p>
                <b>Kundekontrakter:</b> Inkludering av klausuler i kontrakter som forbyr bruk av utstyr i brudd på 
                menneskerettigheter, og som gir rett til å kansellere salg ved mistanke om slik bruk.
                </p>

                <h4>5. Evaluering og kontinuerlig forbedring</h4>
                <p>
                Denne aktsomhetsvurderingen evalueres årlig eller ved vesentlige endringer i eksportaktiviteten. 
                Vi er forpliktet til kontinuerlig forbedring og oppdaterer våre tiltak basert på erfaringer, nye 
                risikovurderinger og endringer i internasjonale standarder.
                </p>
              <p className="open-aktsomhet" onClick={toggleVisibility}><span className="close-aktsomhet">Lukk</span></p>
          </div>
        )}
      </div>
    </div>
  );
}

export default OmOss;