import React, { useState} from 'react';
import './LogIn.css';
import { Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const LogIn = ({setLoggedInUser, firebaseConfig}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  const db = firebase.firestore();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
      setLoggedInUser(user)
      setLoginError(false);
      localStorage.setItem("loggedInUser", user.email)
      localStorage.setItem("loggedInId", user.uid);
      console.log(user.getIdTokenResult())
      // Fetch the user data from Firebase
      const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
      const userData = userDoc.data();

      const isAdmin = userData.isAdmin;
      if (isAdmin) {
        // Set admin access token using Firebase token
        const adminAccessToken = user._delegate.accessToken; // Replace with your actual admin access token
        localStorage.setItem('accessToken', adminAccessToken);
        localStorage.setItem("AdminDisplay","2")
        console.log("admin");
      } else {
        // Set regular user access token using Firebase token
        const userAccessToken = await user.getIdToken();
        localStorage.setItem('accessToken', userAccessToken);
        localStorage.setItem("AdminDisplay","1")
        console.log("user");
      }

      // Store refresh token if available (replace 'refreshToken' with your actual refresh token)
      const refreshToken = user._delegate.refreshToken;
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }

      navigate('/');
      window.location.reload();
    } catch (error) {
      // Handle login error
      setLoginError(true);
      console.log('Login error:', error);
    }
  };

  const showPassword = () => {
    const passwordInput = document.getElementById('password');
    passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
  };

  return (
    <div>
      <h1>Logg Inn</h1>
      <div className="login-full-form">
        <form className="logIn-form" onSubmit={handleSubmit}>
          <label className="logIn-label">
            <input
              className="logIn-input"
              type="text"
              name="email"
              placeholder="epost@epost.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="logIn-label">
            <input
              className="logIn-input"
              type="password"
              name="password"
              placeholder="passord"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <div className="shw-pwd-container">
            <input type="checkbox" onClick={showPassword} name="showpwd" />
            <label htmlFor="showpwd" className="showpwd">
              Show password
            </label>
          </div>
          <input className="logIn-btn" type="submit" value="Log In" />
        </form>
        {loginError && <p className="login-error">Feil passord eller bruker</p>}
      </div>
      {/* New user / Forgotten pwd */}
      <Link to="/forgotten-password" className="login-option">
        <p>Har du glemt password?</p>
      </Link>
      <Link to="/new-user" className="login-option">
        <p>Lag en ny bruker</p>
      </Link>
    </div>
  );
};

export default LogIn;