import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './MyAds.css';
import translations from "./translations";

import favorite_icon from '../../imgs/star.png';
import clicks_icon from '../../imgs/cursor.png';


const MyAds = ({currentLanguage}) => {
  const [myAds, setMyAds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMyAds = async () => {
      try {
        const loggedInId = localStorage.getItem('loggedInId');
        const doc = await firebase.firestore().collection('users').doc(loggedInId).get();
        if (doc.exists) {
          const userData = doc.data();
          setMyAds(userData.myAds || []);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching my ads:', error);
      }
    };
    fetchMyAds();
  }, []);

  const [adData, setAdData] = useState({});

  useEffect(() => {
    const fetchClickFavData = async () => {
      const data = {};
  
      // Loop over even indices only (0, 2, 4, etc.)
      for (let i = 0; i < myAds.length; i += 2) {
        const ad = myAds[i];
        if (ad && typeof ad === 'string') {
          const doc = await firebase.firestore().collection('clickFav').doc(ad).get();
  
          if (doc.exists) {
            data[ad] = doc.data();
          } else {
            data[ad] = { click: 0, fav: 0 };
          }
        }
      }
  
      setAdData(data);
    };


    fetchClickFavData();
  }, [myAds]);

  const translateText = (textKey) => {
    // Get the translated text based on the current language
    return translations[currentLanguage][textKey];
  };

  return (
    <div>
      <h1 className="header">{translateText("Header")}</h1>
      <div className="my-ads-container">
      {loading ? (
        <p>Loading...</p>
      ) : myAds.length === 0 ? (
        <p>{translateText("NoAds")}</p>
      ) : (
        <div>
          {myAds.map((ad, index) => (
            index % 2 === 0 && (
              <div className="my-ad-element" key={index}>
                <h4>{myAds[index + 1]}</h4>
                <div className="my-ads-info-element-container">
                  <div className="my-ads-info-element">
                      <img src={clicks_icon}/>
                      <p>{adData[ad]?.click || 0}</p>
                  </div>
                  <div className="my-ads-info-element">
                      <img src={favorite_icon}/>
                      <p>{adData[ad]?.fav || 0}</p>
                  </div>
                </div>
                <Link className="my-ad-link" to={`/for-sale/${ad}`}>
                  <p>Vis Annonse</p>
                </Link>
              </div>
            )
          ))}
        </div>
      )}
      </div>
    </div>
  );
};

export default MyAds;