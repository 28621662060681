import React from 'react';

const FooterElementBot = ({ label, value }) => {
  return (
    <div className="footer-bottom-info-container">
        <p className="footer-label">{label}</p>
        <p className="footer-value">{value}</p>
    </div>
  );
};

export default FooterElementBot;