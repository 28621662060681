import './frontMain.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ForSaleLink from './select-category-element.js';
import HeroVideo from './hero-video.js';
import SearchForm from './search-form.js';

import truck_img from '../../imgs/truck.png';
import bus_img from '../../imgs/bus.png';
import tractor_img from '../../imgs/tractor.png';
import excavator_img from '../../imgs/excavator.png';

import translations from "./frontMain_translations";

import header_video from "../../imgs/Header_VAMU.mp4";

export default function Main({ objects, currentLanguage, setSelectedTypes}) {
    const [filteredObjects, setFilteredObjects] = useState([]);
    
    const constructionCount = objects.Objects.filter(obj => obj.type === 'construction' && (obj.status === 'active' || obj.status === 'sold (Not Delivered)')).length;
    const heavyCount = objects.Objects.filter(obj => obj.type === 'heavyVehicles' && (obj.status === 'active' || obj.status === 'sold (Not Delivered)')).length;
    const lightCount = objects.Objects.filter(obj => obj.type === 'lightTruck' && (obj.status === 'active' || obj.status === 'sold (Not Delivered)')).length;
    const agriculturalCount = objects.Objects.filter(obj => obj.type === 'agricultural' && (obj.status === 'active' || obj.status === 'sold (Not Delivered)')).length;

    useEffect(() => {
        if (Array.isArray(objects)) {
          setFilteredObjects(objects);
        }
      }, [objects]);
  

    const translateText = (textKey) => {
        // Get the translated text based on the current language
        return translations[currentLanguage][textKey];
      };


    return (
        <main>
            <HeroVideo videoSrc={header_video} />
            <SearchForm translateText={translateText} objects={objects} />
            
            {/* Link boxes */}
            <div className="front-main-container">
                <ForSaleLink
                    onClick={() => setSelectedTypes(['construction'])}
                    type = {'construction'}
                    label={translateText('construction')}
                    count={constructionCount}
                    imgSrc={excavator_img}
                    imgAlt="Construction equipment for sale"
                />
                <ForSaleLink
                    onClick={() => setSelectedTypes(['heavyVehicles'])}
                    type = {'heavyVehicles'}
                    label={translateText('heavyVehicles')}
                    count={heavyCount}
                    imgSrc={truck_img}
                    imgAlt="Heavy vehicles for sale"
                />
                <ForSaleLink
                    onClick={() => setSelectedTypes(['lightTruck'])}
                    type = {'lightTruck'}
                    label={translateText('lightTruck')}
                    count={lightCount}
                    imgSrc={bus_img}
                    imgAlt="Light trucks for sale"
                />
                <ForSaleLink
                    onClick={() => setSelectedTypes(['agricultural'])}
                    type = {'agricultural'}
                    label={translateText('agricultural')}
                    count={agriculturalCount}
                    imgSrc={tractor_img}
                    imgAlt="Agricultural equipment for sale"
                />
            </div>

            {/* Sell info link */}
            <Link className="sell-main-link" to="/sale-info">
                <div className="main-sell">
                    {translateText('selltext')}
                </div>
            </Link>
            
        </main>
    )
}