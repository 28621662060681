import './Footer.css';
import FooterElement from './footer-info-element.js';
import FooterElementBot from './footer-info-element-bot.js';
import FooterIcon from './footer-icon-element.js';
import translations from "./footer_translations.js";
import {translateText} from "./footer_translations.js"
import facebook_icon from "../../imgs/facebook-circular-logo.png";
import logo from "../../imgs/Vamu-transparent-logo.png"

export default function Footer({currentLanguage}) {
    return (
        <div className = "footer">
          <div className="footer-top-container">
            <div className="footer-top-logo-container">
              <img className="footer-logo" src={logo}/>
              <p>{translateText('logoInfo', currentLanguage)}</p>
            </div>
            <div className="footer-top-container-info">
              <h3>{translateText('follow', currentLanguage)}</h3>
              <div className="footer-icons">
                <FooterIcon href="https://www.facebook.com/profile.php?id=61556793100114" src={facebook_icon} alt="Facebook Icon" />
              </div>
            </div>
            <div className="footer-top-container-info">
              <h3 >{translateText('contactUs', currentLanguage)}</h3>
              <div className = "footer-info">
                <FooterElement label={translateText('phone', currentLanguage)} value="+47 73 185 257" />
                <FooterElement label={translateText('mail', currentLanguage)} value="post@vamu.no" />
              </div>
            </div>
          </div>
          <div className="footer-bot-container">
            <FooterElementBot label={translateText('org', currentLanguage)} value="932 165 996" />
          </div>
        </div>
)
}