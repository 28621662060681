import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import './ObjectDetails.css';

import arrow_icon from "../../imgs/icon_arrow.png"
import arrow_icon_left from "../../imgs/icon_arrow_left.png"
import all_img from "../../imgs/digital-display.png"
import full_img from "../../imgs/display-frame.png"
import phone_icon from "../../imgs/phone-solid.svg";
import mail_icon from "../../imgs/envelope-solid.svg";

import MobileCountyMap from './mobileCountyMap';
import DesktopCountyMap from './desktopCountyMap';

import Terms_NO from "./Terms/Terms_NO";
import Terms_EN from "./Terms/Terms_EN";
import Terms_PL from "./Terms/Terms_PL";
import Terms_UK from "./Terms/Terms_UK";
import Terms_GR from "./Terms/Terms_GR";
import Info_NO from "./Info/Info_NO";
import translations from "./translations";

const ObjectDetails = ({ object, firebaseConfig, currentLanguage }) => {
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  const [egenandel, setEgenandel] = useState("10000");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showOriginalSize, setShowOriginalSize] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const adminDisplay = localStorage.getItem('AdminDisplay');
  const [openSection, setOpenSection] = useState(null);

  // Function to toggle the visibility of a section
  const handleToggle = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const languageToTerms = {
    'no': Terms_NO,
    'en': Terms_EN,
    'pl': Terms_PL,
    'uk': Terms_UK,
    'gr': Terms_GR
  };

  const CurrentTerms = languageToTerms[currentLanguage];

  const translateText = (textKey) => {
    // Get the translated text based on the current language
    return translations[currentLanguage][textKey];
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const updateClickFav = async (obj) => {
    const db = firebase.firestore();
    const docRef = db.collection('clickFav').doc(obj.ObjId);
  
    try {
      const docSnapshot = await docRef.get();
  
      if (docSnapshot.exists) {
        // Document exists, increment the "click" field by 1
        await docRef.update({
          click: firebase.firestore.FieldValue.increment(1),
        });
      } else {
        // Document doesn't exist, create it with "click" set to 1 and "fav" set to 0
        await docRef.set({
          click: 1,
          fav: 0,
        });
      }
  
      console.log('clickFav document successfully updated or created!');
    } catch (error) {
      console.error('Error updating or creating clickFav document:', error);
    }
  };

  useEffect(() => {
    if (object && object.ObjId) {
      updateClickFav(object);
    }
  }, []);

  const handleVilkårClick = () => {
    setModalOpen(!modalOpen);
  };
  
  const modalClassName = `modal ${modalOpen ? 'open' : ''}`;
  
  const modal = modalOpen ? (
    <div className={modalClassName}>
      <div className="modal-content">
        <p className="close-modal" onClick={handleVilkårClick}>
          X
        </p>
        <div className ="modal-text" dangerouslySetInnerHTML={{ __html: CurrentTerms }} />
      </div>
    </div>
  ) : null;

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const handleShowOriginalSize = () => {
    setShowOriginalSize(!showOriginalSize);
  };

  const handleShowAllImages = () => {
    setShowAllImages(!showAllImages);
  };

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    // Reference to the GCS folder
    const storageRef = firebase.storage().ref(object.ObjId); // Replace 'your-folder-name' with the actual folder name

    // List items (images) in the folder
    storageRef
      .listAll()
      .then((result) => {
        // Get the download URL for each image
        const promises = result.items.map((item) => item.getDownloadURL());

        // Resolve all the promises
        return Promise.all(promises);
      })
      .then((urls) => {
        setImageUrls(urls);
        console.log(urls)
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setEgenandel(newValue);
  };

  const calculateEstimatedValue = () => {
    const estimatedValue = (object.price - egenandel) * 0.05 + (object.price - egenandel) / (25 * 12);
    return Math.floor(estimatedValue); // Round the result to two decimal places
  };

  const formatPrice = (price) => {
    const parts = price.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?city=${object.place}&format=json&limit=1`);
        const data = await response.json();
        if (data.length > 0) {
          setCoordinates({
            latitude: parseFloat(data[0].lat),
            longitude: parseFloat(data[0].lon),
          });
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    fetchCoordinates();
  }, [object.county]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when this component mounts
  }, []);

  return (
    <div>
      <div className="back-links-chained">
        <Link to="../for-sale">
          <p>{translateText("Annonser")}/</p>
        </Link>
        <Link 
          to="../for-sale"
          state = {{ type: object.type }} 
        >
          <p>{translateText(object.type)}/</p>
        </Link>
        <Link 
          to="../for-sale"
          state = {{ 
            type: object.type, 
            subType: object.subType
          }}
        >
          <p>{translateText(object.subType)}</p>
        </Link>
      </div>
      <Link className="back-link" 
        to= "../for-sale"
        state = {{
          useLocalStorage: true 
        }
      }>
        <span >
          <img className="back-link-image" src={arrow_icon_left}></img>
        </span>{translateText('Back')}
      </Link>
      <div className="object-container">
        <div className="object-information-container">
        <div className="image-carousel">
          <div className="carousel-controls">
            <button className="image-btn-prev" onClick={handlePrevImage}>
                    <img className="icon-image" src={arrow_icon_left}/>
            </button>
          </div>
          <div className="image-index-container">
            <p className="image-index">{currentImageIndex+1}/{imageUrls.length}</p>
          </div>
          {showAllImages ? (
            <div className="carousel-images">
              {imageUrls.map((url, index) => (
                <img
                  key={index}
                  className={`carousel-image`}
                  src={url}
                  alt={`Object image ${index}`}
                  style={showOriginalSize ? {width: '150px', height: '150px'} : {width: '150px', height: '150px'}}
                />
              ))}
            </div>
          ) : (
            <img
              key={imageUrls[currentImageIndex]}
              className="object-image"
              src={imageUrls[currentImageIndex]}
              alt={`Object image ${currentImageIndex}`}
              style={showOriginalSize ? {
                                        width: 'auto', 
                                        height: '85vh' } : {}}
            />
          )}
          <div className="carousel-controls">
              <button className="image-btn-next"onClick={handleNextImage}>
                <img className="icon-image" src={arrow_icon}/>
              </button>
          </div>
          <div className="controls-size-all-container">
            <div className="carousel-controls-size">
              <button className="control-btn-image" onClick={handleShowOriginalSize}>
                <img className="full-icon" src={full_img}/>
              </button>
            </div>
            <div className="carousel-controls-all">
              <button className="control-btn-image" onClick={handleShowAllImages}>
              <img className="full-icon" src={all_img}/>
              </button>
            </div>
          </div>
        </div>
          <div className="header">
            <div>
              <h2>{object.title}<span className="object-status-title">{object.status === 'sold (Delivered)' || object.status === 'sold (Not Delivered)' ? '[Solgt]' : ""}</span></h2>
            </div>
            <div className="ref-dato-container">
              <p className="ref-dato">Refnr: {object.ObjId}</p>
              <p className="ref-dato">{object.created}</p>
              {adminDisplay === '2' && ( // Check if 'AdminDisplay' equals '2'
                <Link 
                  to={{
                    pathname: "/Annonser",
                  }}
                  state = {{ ObjId: object.ObjId }} 
                >
                  Adminside
                </Link>
              )}
            </div>
          </div>
          <div className="main-info">
            <div>
              {object.status !== "sold (Not Delivered)" && (
                <div>
                  <p className="price-container">{formatPrice(object.price)} NOK </p>
                  <p className="mva-span">{object.Mva}</p>
                </div>
              )}
              <p className="place-container">{object.place}, {object.county}, {object.country}</p>
            </div>
            <MobileCountyMap object={object} />
            
          </div>
          <div className="general-info-container-top">
            <div>
              {object.year && (
                <p><span className="general-info-item">{translateText('Year')}:</span> {object.year}</p>
              )} 
              {object.fuel && (
                <p><span className="general-info-item">{translateText('Fuel')}:</span> {object.fuel}</p>
              )} 
              {object.subType && (
                <p><span className="general-info-item">{translateText('Category')}:</span> {translateText(object.subType)}</p>
              )}    
              {object.LengdeBredde && (
                <p><span className="general-info-item">Lengde/Bredde:</span> {object.LengdeBredde} </p>
              )}
            </div>
            <div>
              {object.totalvekt && (
                <p><span className="general-info-item">{translateText('Weight')}:</span> {object.totalvekt} kg</p>
              )}
              {object.gear && (
                <p><span className="general-info-item">{translateText('Gear')}:</span> {object.gear} </p>
              )}
              {object.kmtimer && (
                <p><span className="general-info-item">{object.kmtimer[0].toUpperCase()}{object.kmtimer.substr(1, object.kmtimer.length - 1)}:</span> {object.kmtimerValue}</p>
              )}
            </div>
          </div>

          <div className="free-text-container">
          {object.type === "heavyVehicles" && object.eu && 
            <div className="info-dropdown-container">
              <div className="info-dropdown-element">
                <h3 onClick={() => handleToggle('euKontroll')}>EU-kontroll</h3>
                {openSection === 'euKontroll' && (
                  <div className="info-dropdown-list">
                    {object.eu.map((item, index) => (
                      index % 2 === 0 ? (
                        <h4 key={index}>{item}</h4>
                      ) : (
                        <p key={index}>{item}</p>
                      )
                    ))}
                  </div>
                )}
              </div>
              <div className="info-dropdown-element">
                <h3 onClick={() => handleToggle('registreringsdata')}>Registreringsdata</h3>
                {openSection === 'registreringsdata' && (
                  <div className="info-dropdown-list">
                    {object.regData.map((item, index) => (
                      index % 2 === 0 ? (
                        <h4 key={index}>{item}</h4>
                      ) : (
                        <p key={index}>{item}</p>
                      )
                    ))}
                  </div>
                )}
              </div>
              {object.målVekt && object.målVekt.length > 0 && (
                <div className="info-dropdown-element">
                  <h3 onClick={() => handleToggle('dimWeight')}>Mål og vekt</h3>
                  {openSection === 'dimWeight' && (
                    <div className="info-dropdown-list">
                      {object.målVekt.map((item, index) =>
                        index % 2 === 0 ? (
                          <h4 key={index}>{item}</h4>
                        ) : (
                          <p key={index}>{item}</p>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            }
            <div>
              {object.text.split('\n').map((paragraph, index) => (
                paragraph.trim() !== '' && (
                  <p 
                    key={index}
                    className="free-text"
                  >
                    <span className="bullet-ball">-</span>{paragraph}
                  </p>
                )
              ))}
            </div>
          </div>
          
          <div className="general-info-container">
            {object.utils.map((util, index) => (
              <p key={index}>{util}</p>
            ))}
          </div>
          <div className="open-street-map-container">
            {coordinates.latitude && coordinates.longitude ? (
              <iframe
                src={`https://www.openstreetmap.org/export/embed.html?bbox=${coordinates.longitude - 0.2},${coordinates.latitude - 0.2},${coordinates.longitude + 0.2},${coordinates.latitude + 0.2}&layer=mapnik`}
                width="350"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            ) : (
              <p>Map unavailable for the given city.</p>
            )}
          </div>
          <div className="standard-info-container" dangerouslySetInnerHTML={{ __html: Info_NO }} />
        </div>
        <div className="location-container">
          <DesktopCountyMap object={object}/>
          <div className="contact-us-container">
            <p>{translateText('Question')}</p>
            <p><em>{translateText('ContactUs')}</em></p>
            <div className="phone-container">
              <img src={phone_icon}/>
              <p>+47 452 56 737</p>
            </div>
            
            <p className="post-contact">
              <img src ={mail_icon}/>
              <a href="mailto:post@vamu.no">
                post@vamu.no
              </a>
            </p>
          </div>
          <div className="finans-container">
            <h3 className="finans-header">Lån</h3>
            <p className="estimate-month">Egenandel </p>
            <div className="slidecontainer">
              <input
                type="range"
                min="1"
                max={object.price}
                value={egenandel}
                className="slider"
                id="myRange"
                onChange={handleSliderChange}
              />
              <p className="egenandel">{egenandel} NOK</p>
              <p className="estimate-month">Estimert pr. måned</p>
              <p className="estimate-month-price">{calculateEstimatedValue()} NOK</p>
            </div>
          </div>
          <div className="link-term-container">
            <div className="link-container">
              <p className="link">{translateText('Link1')}</p>
              <p className="link">{translateText('Link2')}</p>
            </div>
            <div className="terms-container" onClick={handleVilkårClick}>
              <p>{translateText('Terms')}</p>
            </div>
            {modal}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectDetails;