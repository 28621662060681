import { useEffect, useState } from 'react';

const useInView = (ref, options = { root: null, rootMargin: '0px', threshold: 0.1 }) => {
    const [hasBeenViewed, setHasBeenViewed] = useState(false);
  
    useEffect(() => {
      if (!ref.current) return;
  
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setHasBeenViewed(true);
          }
        },
        options
      );
  
      observer.observe(ref.current);
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
  
    return hasBeenViewed;
  };
  
  export default useInView;