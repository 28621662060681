import React, { useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import './SavedSearches.css';
import translations from "./translations";


  const SavedSearches = ({currentLanguage}) => {
    const [savedSearches, setSavedSearches] = useState([]);
    const loggedInId = localStorage.getItem('loggedInId');
    const [inputText, setInputText] = useState('');
    const [loading, setLoading] = useState(true);

    const translateText = (textKey) => {
      // Get the translated text based on the current language
      return translations[currentLanguage][textKey];
    };
   
    {/* Load saved searches from firebase on load*/}
    useEffect(() => {
      const fetchSavedSearches = async () => {
        try {
          const userRef = firebase.firestore().collection('users').doc(loggedInId);
          const doc = await userRef.get();
          if (doc.exists) {
            const userData = doc.data();
            setSavedSearches(userData.savedSearches || []);
          }
        } catch (error) {
          console.error('Error fetching saved searches:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchSavedSearches();
    }, []);

    {/* Submits keyword to saved searches */}
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Check if inputText is not empty
      if (inputText.trim() !== '') {
        try {
          const loggedInId = localStorage.getItem("loggedInId");
  
          // Add the inputText to the savedSearches array in Firestore
          await firebase.firestore().collection('users').doc(loggedInId).update({
            savedSearches: firebase.firestore.FieldValue.arrayUnion(inputText)
          });
  
          // Clear the input field after adding to Firestore
          setInputText('');
          // Fetch the updated saved searches
          const userRef = firebase.firestore().collection('users').doc(loggedInId);
          const doc = await userRef.get();
          if (doc.exists) {
            const userData = doc.data();
            setSavedSearches(userData.savedSearches || []);
          }
        } catch (error) {
          console.error('Error adding saved search:', error);
        }
      }
    };

    {/* Deletes keyword from saved searches */}
    const handleDeleteSearch = async (index) => {
      try {
        const loggedInId = localStorage.getItem("loggedInId");
        const updatedSearches = [...savedSearches];
        updatedSearches.splice(index, 1);
    
        // Update the savedSearches array in Firestore
        await firebase.firestore().collection('users').doc(loggedInId).update({
          savedSearches: updatedSearches
        });
    
        // Update the state with the modified savedSearches array
        setSavedSearches(updatedSearches);
      } catch (error) {
        console.error('Error deleting saved search:', error);
      }
    };

    return (
      <div className="saved-searches-container">
        <h1 className="header">{translateText('Header')}</h1>
        <p className="sub-info">{translateText('Info')}</p>

        <div className="saved-searches-list-container">
          {loading ? (
            <p>Loading...</p>
          ) : savedSearches.length === 0 ? (
            <p>{translateText('NoSave')}</p>
          ) : (
            savedSearches.map((search, index) => (
              <div className="saved-searches-list-element" key={index}>
                <p>{search}</p>
                {savedSearches.length > 0 && ( // Conditionally render the button only when savedSearches.length > 0
                  <button onClick={() => handleDeleteSearch(index)}>
                    {translateText('Remove')}
                  </button>
                )}
              </div>
            ))
          )}
        </div>

        <form className="search-form" onSubmit={handleSubmit}>
          <input 
            type="text" 
            className="search-field" 
            name="inputText" 
            placeholder="Keyword..." 
            value={inputText} 
            onChange={(e) => setInputText(e.target.value)} 
          />
          <button type="submit" className="search-submit">
            {translateText('Save')}
          </button>
        </form>
      </div>
    );
  };
  
  export default SavedSearches;