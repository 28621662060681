// InfoBox.js
import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import useInView from './use-in-view'; // Adjust import path accordingly
import './infoBox.css'; // Ensure to import the CSS

const InfoBox = ({ type, img, Title, Description }) => {
    const boxRef = useRef(null);
    const hasBeenViewed = useInView(boxRef, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });
  
    const boxClassName = type === '2' ? 'info-box-2' : 'info-box';
    const inViewClass = hasBeenViewed ? 'in-view' : '';
  
    return (
      <div
        ref={boxRef}
        className={`${boxClassName} ${inViewClass}`}
      >
        {type === '2' ? (
          <>
            <div>
              <h3 className="info-box-header">{Title}</h3>
              <p>{Description}</p>
            </div>
            <img className="info-img" src={img} alt={Title} />
          </>
        ) : (
          <>
            <img className="info-img" src={img} alt={Title} />
            <div>
              <h3 className="info-box-header">{Title}</h3>
              <p>{Description}</p>
            </div>
          </>
        )}
      </div>
    );
  };
  
  InfoBox.propTypes = {
    type: PropTypes.oneOf(['1', '2']).isRequired,
    img: PropTypes.string.isRequired,
    Title: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
  };
  
  export default InfoBox;