const Terms_NO = `
    <div>
    <h2>Salgs- og leveringsvilkår</h2>

 

    <h4>1. Innledning</h4>
    <p>
    Disse generelle salgs- og leveringsvilkårene gjelder for alle tilbud, salg og leveranser fra Vamu AS (heretter "Vamu"). Dette gjelder uavhengig av om Vamu selv fungerer som selger/eier, megler for selger/eier eller leverandør for selger/eier.
    </p>
     
    <p>
    Begrepet "varen" omfatter både produktet som blir solgt og tilhørende utstyr samt tilbehør.
    </p>
     
    <p>
    Eventuelle avvik fra disse vilkårene må være skriftlig avtalt mellom partene. Avtalen omfatter disse vilkårene, ordrebekreftelsen og kjøpekontrakten med eventuelle vedlegg. Ved motstrid mellom en enkelt ordre og disse vilkårene, skal ordrebekreftelsen og/eller kjøpekontrakten ha forrang. Hvis ikke annet er spesifisert i disse vilkårene, gjelder kjøpsloven av 13. mai 1988 nr. 27. Ved uoverensstemmelse mellom kjøpsloven og disse vilkårene, skal disse vilkårene ha forrang der loven ikke er preseptorisk.
    </p>
     
    <p>
    Alle tilbud, salg og leveranser fra Vamu er underlagt disse vilkårene, med mindre annet er skriftlig avtalt. Kjøperens egne generelle eller spesielle kjøpsbetingelser gjelder ikke med mindre Vamu skriftlig har godkjent disse.
    </p>
     
    
    <h4>2. Tilbud, Aksept, Ordrebekreftelse, Kjøpekontrakt</h4>
    <p>
    Vamu's tilbud er gyldige innen den angitte fristen hvis de aksepteres av kjøper. Avtale mellom partene inngås når kjøper aksepterer Vamu's tilbud eller når Vamu aksepterer kjøpers tilbud. Muntlige tilbud fra Vamu's representanter blir bindende når de er skriftlig bekreftet av Vamu, for eksempel gjennom tilbudsbrev, e-post eller ordrebekreftelse.
    </p>
     
    <p>
    Hvis kjøper deltar i en budrunde, er kjøpers pristilbud bindende i 2 uker med mindre annet er avtalt.
    </p>
     
    <p>
    Vanligvis bekreftes avtalen om kjøp/salg gjennom en ordrebekreftelse som bekrefter inngåelsen av avtalen. Vamu vil normalt formidle dette og samtidig utforme en kjøpekontrakt som inkluderer disse vilkårene.
    </p>
     
    <p>
    Enhver bestilling som mottas av Vamu, bekreftes skriftlig via e-post, brev eller telefon. Ordrebekreftelsen er endelig og bindende. Disse vilkårene utgjør en del av den bekreftede ordren og avtalen mellom partene. Vamu vil korrigere eller utbedre feil som oppstår ved skriftlige eller elektroniske bestillinger som skyldes forhold hos Vamu. Vamu's generelle salgsbetingelser gjelder for enhver bestilling eller ordre uavhengig av om kunden henviser til egne betingelser i forbindelse med bestillingen.
    </p>
     
    
    <h4>3. Salgspris - Kostnader</h4>
    <p>
    Pris og betalingsbetingelser fremgår av ordrebekreftelsen og/eller kjøpekontrakten. Alle priser oppgis inkludert eller eksklusive merverdiavgift, miljøgebyrer og frakt. Alle kostnader knyttet til overtagelsen skal dekkes av kjøper, inkludert avgifter, gebyrer, forsikringer og transport.
    </p>
     
    <p>
    Vamu kan kreve kompensasjon/endring av kjøpesummen hvis kostnadene med å gjennomføre avtalen endres før betalingstidspunktet, for eksempel ved endringer i offentlige avgifter og skatter, miljøgebyrer eller valutakurser. Ved ønske om endringer i avtalen, kan Vamu kreve endret kjøpesum og eventuelt endret leveringstidspunkt.
    </p>
     
    <p>
    Kjøperen er ansvarlig for fortolling og alle nødvendige tiltak i forbindelse med import av varer, inkludert alle kostnader knyttet til dette.
    </p>
     
    
    <h4>4. Levering - Risikoens overgang</h4>
    <p>
    Levering skjer i henhold til nærmere avtale. Levering anses å ha funnet sted når varen er overlevert til selvstendig fraktør eller gjort tilgjengelig for kjøper.
    </p>
     
    <p>
    Når Vamu selv står for levering, skjer levering ved overlevering til kunden.
    </p>
     
    <p>
    Ved levering går risikoen for gjenstandens tilfeldige undergang over på kjøperen. Dette inkluderer ansvar for videre transport, forsikringer osv. Alle forsendelser etter levering foregår på kjøperens risiko.
    </p>
     
    
    <h4>5. Leveringstid</h4>
    <p>
    Levering av vare som Vamu er i stand til å levere fra egen varebeholdning, skal normalt skje omgående. Vamu forbeholder seg retten til å forlenge leveringstiden i rimelig utstrekning for å kunne oppfylle eventuelle særskilt avtalte transportforpliktelser eller hvis omfang, andre leveranser eller lignende forhold vanskeliggjør omgående levering.
    </p>
     
    <p>
    Angitt leveringstid i tilbud, ordrebekreftelse eller kontrakt for varer som befinner seg hos tredjepart, er å anse som veiledende, og Vamu kan fritt levere slike varer inntil 1 uke før eller etter den angitte leveringstiden.
    </p>
     
    
    <h4>6. Forsinkelse - Leveringshindring</h4>
    <p>
    Vamu er ikke ansvarlig for manglende oppfyllelse av avtalte forpliktelser i den grad avtalt oppfyllelse hindres eller i urimelig grad vanskeliggjøres av forhold utenfor Vamu sin kontroll. Dette inkluderer forhold som krig, mobilisering, terrorhandlinger, rekvirering, beslagleggelse, vesentlige endringer i valutamarkedet, eksport- eller importforbud, arbeidskonflikter, utilstrekkelig tilførsel av råmaterialer, mangel på transportmidler, brann, fabrikkuhell, mangler ved leveranser fra underleverandører eller forsinkelser fra slike.
    </p>
     
    <p>
    Levering skal skje i henhold til ordre, og leveringsforpliktelsen anses ikke som oppfylt før alle ordrelinjer i bestillingen er levert. Delleveringer aksepteres fullt ut, og Vamu har rett til å sørge for full levering innen rimelige tidsfrister. Vamu plikter imidlertid å underrette kjøperen dersom det er grunn til å anta at den avtalte leveringstiden ikke kan overholdes. Dersom ny leveringstid ikke er akseptabel for kjøperen, må dette umiddelbart meddeles skriftlig til Vamu.
    </p>
     
    <p>
    Varer som er spesialbestilt for kunden kan ikke avbestilles. Bortsett fra kundens rett til å avbestille som følge av vesentlig forsinket levering, er ethvert krav fra kjøperen som følge av Vamu's forsinkede levering utelukket.
    </p>
     
    <p>
    Ingen av partene skal anses for å ha misligholdt en forpliktelse etter bestillingen i den grad gjennomføring av avtalen er blitt forhindret av force majeure.
    </p>
     
    
    <h4>7. Returavtale - Avbruddsgebyr</h4>
    <p>
    Retur av varer kan kun skje etter skriftlig avtale med Vamu. Dersom kunden ikke oppfyller avtalen eller ikke ønsker å oppfylle avtalen etter bestilling og før levering, har Vamu rett til et avbruddsgebyr som kan belastes kunden. Avbruddsgebyret skal dekke arbeidsgodtgjørelse/tapt fortjeneste og er satt til 10 % av fakturabeløpet eksklusive merverdiavgift og omkostninger. Dersom varen må returneres, skal returfrakt dekkes av kunden i tillegg. Frakt i forbindelse med bestilling/levering krediteres ikke. Årsaken til returen må klargjøres i returavtalen. Alle returnerte varer må være uskadet/i samme stand som forut for kontraktinngåelsen/leveringen.
    </p>
     
    
    <h4>8. Betalingsbetingelser</h4>
    <p>
    Med mindre annet fremgår av kjøpekontrakten, skal kjøperen betale hele kjøpesummen til Vamu's oppgitte kontonummer før levering. Kjøpesummen vil deretter bli overført til leverandøren etter at det er gjort fradrag for provisjon, kostnader og utlegg.
    </p>
     
    <p>
    Garanti for betaling kan kun stilles dersom dette aksepteres av Vamu. Med mindre annet er avtalt, skal garantien stilles som selvskyldnerkausjon fra bank, forsikringsselskap eller annen kredittinstitusjon.
    </p>
     
    <p>
    Ved forsinket betaling skal kjøperen betale forsinkelsesrenter etter den til enhver tid gjeldende sats i henhold til forsinkelsesrenteloven av 17. desember 1976 nr. 100.
    </p>
     
    <p>
    Kjøperen fritas ikke for å betale eller stille garanti selv om han reklamerer på grunn av feil eller mangler. Kjøperen kan heller ikke foreta motregning fra andre kontraktsforhold dersom motregningen bestrides. Kjøperen mister likevel ikke retten til å kreve en prisregulering etter at han har betalt det som samsvarer med den feil eller mangel som måtte foreligge, jf. pkt 9.
    </p>
     
    <p>
    Dersom det er avtalt annen betalingsordning enn det som fremgår av dette punktet, har selgeren salgspant i den leverte varen inntil kjøpesummen med tillegg av renter og omkostninger er betalt fullt ut. Kjøperen har ikke rett til å pantsette varen før kjøpesummen med tillegg av renter og omkostninger er betalt fullt ut.
    </p>
     
    
    <h4>9. Reklamasjon</h4>
    <p>
    Leveransen skal oppfylle bestillingens spesifikasjoner og være i samsvar med angitte krav til ytelse og kvalitet. Kjøperen skal undersøke varene umiddelbart etter mottakelse og reklamere uten ugrunnet opphold dersom forsendelsen ikke samsvarer med ordrebekreftelsen/kjøpekontrakten.
    </p>
     
    <p>
    Reklamasjon som ikke er berettiget, krediteres ikke.
    </p>
     
    <p>
    Kjøperen mister sin rett til å gjøre en mangel gjeldende dersom han ikke innen én uke etter at han oppdaget eller burde ha oppdaget den, gir selgeren melding som angir hva slags mangel det gjelder.
    </p>
     
    <p>
    Kjøperen er klar over og aksepterer følgende:
    </p>

    <p>
    a) Vamu AS er ikke økonomisk ansvarlig for eventuelle reklamasjoner. Eventuelle reklamasjoner skal rettes direkte til eier av objektet, og alle krav som følge av reklamasjoner vil være en sak mellom eier av objektet og kjøper.    
    </p>
    <p>
    b) Vamu AS vil imidlertid være behjelpelig med å fasilitere kommunikasjon mellom kjøper og eier av objektet, med mål om å finne en best mulig løsning for alle parter.    
    </p>
    <p>
    c) Varen er brukt/slitt, og avtalen gjelder et bruktkjøp.
    </p>
    <p>
    d) Varen selges som den er ("as is").
    </p>
    <p>
    e) Vamu AS er mellommann mellom forrige eier og kjøperen, eller eventuelt ny midlertidig eier for å gjennomføre videresalg.
    </p>
    <p>
    f) Vamu AS har ingen kunnskap om varen utover det som er formidlet av forrige eier.
    </p>
    <p>
    g) Kjøperen er gitt anledning og oppfordret til:
    </p>
    <ul>
    <li>
       å undersøke varen før inngåelse av avtalen.
    </li>
    <li>
       å la seg bistå av fagkyndig bistand for å undersøke varen.
    </li>
    <li>
       å reise spørsmål om varen direkte til selgers leverandør og utbe nødvendig informasjon fra dem.
    </li>
    </ul>
    <p>
    h) Vamu AS er ikke ansvarlig for transportskader. Kjøperen er tilbudt og oppfordret til å tegne egen transportforsikring. Ved transportskader skal det straks reklameres til transportøren.
    </p>
    <p>
    i) Kjøperen skal medvirke til salget på ethvert trinn av salgs- og leveringsprosessen og gi opplysninger som kreves av selgeren/leverandøren.
    </p>
    <p>
    j) Kjøpesummen er akseptert av kjøperen og gjenspeiler hva kjøperen mener er riktig verdi for varen.
    </p>
     
    <p>
    Dersom leveransen lider av en mangel, har selgeren rett til å utbedre mangelen innen rimelig tid, herunder eventuelt ved å foreta omlevering.    
    </p>
    <p>
    Kjøperen mister enhver rett til å gjøre mangelen gjeldende dersom han ikke reklamerer i henhold til pkt. 9. Reklamasjon må skje skriftlig.
    </p>
     
    <p>
    Utover det som følger av ovenstående, har selgeren ikke ansvar for mangler. Dette gjelder for ethvert tap manglene måtte forårsake, som for eksempel driftstap, tapt fortjeneste og økonomiske konsekvenstap.    
    </p>
     
    <p>
    Kjøperen er kjent med og aksepterer at han ikke kan gjøre krav gjeldende mot selgeren i større utstrekning enn hva selgeren kan gjøre gjeldende mot sin leverandør/forrige eier/rettsforgjenger.
    </p>
     
    
    <h4>10. Tvistespørsmål</h4>
    <p>
    Avtalen er underlagt norsk rett. Eventuelle tvister mellom partene vedrørende en bestilling eller et salg avgjøres av de alminnelige domstolene. Sør-Trøndelag tingrett vedtas som verneting.
    </p>
     
    </div>



`
export default Terms_NO;