const complianceText = `
<h3>Informacja o Ochronie Danych Użytkownika</h3>
<p>Droga Użytkowniczko, Drogi Użytkowniku,</p>

<p>W VAMU AS zobowiązujemy się do ochrony Twojej prywatności i zapewnienia bezpieczeństwa Twoich danych osobowych. W ramach przestrzegania Ogólnego Rozporządzenia o Ochronie Danych (RODO) chcemy poinformować Cię, w jaki sposób zbieramy, używamy i chronimy Twoje informacje.</p>

<h4>Informacje, które zbieramy</h4>

<p>Przy rejestracji na nasze usługi zgadzasz się na przechowywanie przesłanych danych w naszej bazie danych.</p>

<p>Wdrożyliśmy odpowiednie środki techniczne i organizacyjne w celu zabezpieczenia Twoich danych osobowych przed nieautoryzowanym dostępem, ujawnieniem, zmianą i zniszczeniem. Regularnie przeglądamy i aktualizujemy nasze praktyki bezpieczeństwa, aby zapewnić najwyższy poziom ochrony.</p>

<h4>Twoje prawa</h4>

<p>Zgodnie z RODO masz określone prawa dotyczące Twoich danych osobowych:</p>
<ul>
<li>Prawo dostępu do Twoich danych i otrzymywania ich kopii</li>
<li>Prawo do poprawiania nieścisłości w Twoich danych</li>
<li>Prawo do żądania usunięcia Twoich danych</li>
<li>Prawo do ograniczenia lub sprzeciwu wobec przetwarzania Twoich danych</li>
<li>Prawo do przenoszenia danych</li>
</ul>
<p>Podstawy prawne przetwarzania</p>

<ul>
<li>Twoja zgoda</li>
<li>Konieczność wykonania umowy</li>
<li>Spełnienie obowiązków prawnych</li>
<li>Uprawnione interesy realizowane przez nas lub przez stronę trzecią</li>
</ul>
<h4>Kontakt</h4>

<p>Jeśli masz pytania, wątpliwości lub prośby dotyczące Twoich danych osobowych lub naszych praktyk dotyczących ochrony prywatności, skontaktuj się z nami pod adresem post@vamu.no.</p>

<p>Dziękujemy za zaufanie, jakim darzysz VAMU AS, powierzając nam swoje dane osobowe. Jest dla nas ważne zapewnić ochronę Twojej prywatności i będziemy nadal przestrzegać najwyższych standardów ochrony danych.</p>

<p>Z poważaniem,<br>Departament IT<br>VAMU AS</p>
`;

export default complianceText ;