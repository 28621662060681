import React, { useState, useRef, useEffect } from 'react';

const HeroVideo = ({ videoSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoPlay = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isPlaying) {
          handleVideoPlay();
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (observer && videoRef.current) {
        observer.disconnect();
      }
    };
  }, [isPlaying]);

  return (
    <div className="hero-video">
      <video ref={videoRef} autoPlay loop muted playsInline>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default HeroVideo;