const complianceText = `
<h3>Datenschutzerklärung für Benutzerdaten</h3>
<p>Liebe Benutzerin, lieber Benutzer,</p>

<p>Bei VAMU AS sind wir verpflichtet, Ihre Privatsphäre zu schützen und die Sicherheit Ihrer persönlichen Daten zu gewährleisten. Im Rahmen unserer Einhaltung der Datenschutz-Grundverordnung (DSGVO) möchten wir Sie darüber informieren, wie wir Ihre Informationen sammeln, verwenden und schützen.</p>

<h4>Informationen, die wir sammeln</h4>

<p>Wenn Sie sich für unsere Dienste anmelden, stimmen Sie der Speicherung der übermittelten Daten in unserer Datenbank zu.</p>

<p>Wir haben angemessene technische und organisatorische Maßnahmen implementiert, um Ihre personenbezogenen Daten vor unbefugtem Zugriff, Offenlegung, Änderung und Zerstörung zu schützen. Wir überprüfen und aktualisieren regelmäßig unsere Sicherheitspraktiken, um den höchsten Schutz zu gewährleisten.</p>

<h4>Ihre Rechte</h4>

<p>Gemäß der DSGVO haben Sie folgende Rechte in Bezug auf Ihre personenbezogenen Daten:</p>
<ul>
<li>Das Recht auf Zugang zu Ihren Daten und Erhalt einer Kopie davon</li>
<li>Das Recht auf Berichtigung von Unrichtigkeiten in Ihren Daten</li>
<li>Das Recht, die Löschung Ihrer Daten zu verlangen</li>
<li>Das Recht, die Verarbeitung Ihrer Daten einzuschränken oder ihr zu widersprechen</li>
<li>Das Recht auf Datenübertragbarkeit</li>
</ul>
<p>Rechtsgrundlage für die Verarbeitung</p>

<ul>
<li>Ihre Einwilligung</li>
<li>Vertragliche Notwendigkeit</li>
<li>Einhaltung rechtlicher Verpflichtungen</li>
<li>Berechtigte Interessen, die von uns oder einem Dritten verfolgt werden</li>
</ul>
<h4>Kontaktieren Sie uns</h4>

<p>Wenn Sie Fragen, Bedenken oder Anfragen bezüglich Ihrer personenbezogenen Daten oder unserer Datenschutzpraktiken haben, kontaktieren Sie uns bitte unter post@vamu.no.</p>

<p>Vielen Dank, dass Sie VAMU AS Ihr Vertrauen in Ihre persönlichen Informationen schenken. Wir sind verpflichtet, Ihre Privatsphäre zu schützen und werden weiterhin die höchsten Standards des Datenschutzes einhalten.</p>

<p>Mit freundlichen Grüßen,<br>IT-Abteilung<br>VAMU AS</p>
`;

export default complianceText ;