import './App.css';
import "./components/navbar"
import Navbar from './components/navbar.js';
import Main from "./components/FrontMain/frontMain.js";
import Footer from "./components/Footer/footer.js";
import Ansatte from './components/Ansatte/Ansatte.js'; 
import OmOss from './components/OmOss/OmOss.js'; 
import LogIn from "./components/LogIn/LogIn.js";
import SaleInfo from "./components/SaleInfo/SaleInfo.js";
import NewUser from "./components/NewUser/NewUser.js";
import ForSale from "./components/ForSale/ForSale.js";
import ForgottenPwd from "./components/ForgottenPwd/ForgottenPwd.js";
import MyFavourites from "./components/MyFavourites/myFavourites.js";
import Settings from "./components/Settings/Settings.js";
import SavedSearches from "./components/SavedSearches/SavedSearches.js";
import MyAds from "./components/MyAds/MyAds.js";
import Ringelister from "./components/Admin/Ringelister/Ringelister.js";
import Bookinger from "./components/Admin/Bookinger/Bookinger.js";
import QrKode from "./components/QrKode/qrKode.js";
import Objects from './ObjectsForSale.json';
import ObjectDetails from './components/ObjectDetails/ObjectDetails.js';
import NyAnnonse from './components/Admin/NyAnnonse/NyAnnonse.js';
import Annonser from './components/Annonser/Annonser.js';
import Arkiv from "./components/Arkiv/Arkiv.js";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyCgoN9PO-AKgNl4zhEp0DErNWDH7wCyL8A",
  authDomain: "vamu-c709e.firebaseapp.com",
  projectId: "vamu-c709e",
  storageBucket: "vamu-c709e.appspot.com",
  messagingSenderId: "796944835166",
  appId: "1:796944835166:web:c5681719aefc40dde94acf",
  measurementId: "G-58CBKM6QMD"
};



// Initialize Firestore
firebase.initializeApp(firebaseConfig); // Replace firebaseConfig with your Firebase configuration
const db = firebase.firestore();
const auth = firebase.auth();

const App = () => {
  const [currentLanguage, setCurrentLanguage] = useState('no');
  const [accessToken, setAccessToken] = useState('');
  const [loggedInUser, setLoggedInUser] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [objectList, setObjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [objectsData, setObjectsData] = useState({ Objects: [] });

  useEffect(() => {
    // Check for an existing user on app initialization
    const initApp = async () => {
      return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          unsubscribe(); // Remove the observer as we only need it once

          if (user) {
            // User is signed in
            setLoggedInUser(user);

            // Fetch additional user data if needed
            const userDoc = await db.collection('users').doc(user.uid).get();
            const userData = userDoc.data();
            // Additional logic here

            resolve(user);
          } else {
            // No user is signed in
            resolve(null);
          }
        }, reject);
      });
    };

    // Call initApp on component mount
    initApp().then((user) => {
      if (!user) {
        console.log('user not logged in');
        // No user is signed in, perform login or redirect to login page
      }
      setLoading(false); // Set loading to false once initialization is complete
    });
  }, []);

  useEffect(() => {
    const fetchObjects = async () => {
      console.log("start fetch")
      const saleObjectsRef = firebase.firestore().collection('saleObjects');
      let query = saleObjectsRef; // Initialize the query
  
      // If selectedTypes is not empty, add a filter to the query
      if (selectedTypes.length > 0) {
        query = query.where('type', 'in', selectedTypes);
      }
  
      const snapshot = await query.get();
  
      const objects = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        const ObjId = doc.id;
        objects.push({ ObjId, ...data });
      });
  
      //console.log("objects:", objects);
      setObjectsData({ Objects: objects });
      console.log(objects)
  
      // Introduce a delay here, for example, 1000 milliseconds (1 second)
      setTimeout(() => {
        setObjectList(objects);
        setLoading(false);
      }, 1000); // Adjust the delay time as needed
    };
  
    // Fetch objects on page load
    fetchObjects();
  }, []);

  useEffect(() => {
    setObjectList(objectsData.Objects);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Navbar 
            currentLanguage={currentLanguage} 
            setCurrentLanguage={setCurrentLanguage}
            accessToken={accessToken}
            setAccessToken={setAccessToken}
            setLoggedInUser= {setLoggedInUser}
            isMenuOpen ={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            firebaseConfig = {firebaseConfig}
            />
        </header>
        <Routes>
          <Route path="/" exact element={<Main 
              objects= {objectsData}
              currentLanguage={currentLanguage} 
              setSelectedTypes={setSelectedTypes}
              isMenuOpen={isMenuOpen} />} /> 
          <Route path="/employees" element={<Ansatte 
              currentLanguage={currentLanguage}
              firebaseConfig = {firebaseConfig}
              />} 
          />
          <Route path="/about" element={<OmOss currentLanguage={currentLanguage}/>} />
          <Route 
            path="/log-in" 
            element={<LogIn 
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              setAccessToken={setAccessToken}
              setLoggedInUser= {setLoggedInUser}
              loggedInUser= {loggedInUser}
              firebaseConfig = {firebaseConfig}
              />} 
           />
          <Route 
            path="/new-user" 
            element={<NewUser 
              currentLanguage={currentLanguage}
              firebaseConfig = {firebaseConfig}/>} />
          <Route path="/forgotten-password" element={<ForgottenPwd currentLanguage={currentLanguage}/>} />
          <Route path="/sale-info" element={<SaleInfo currentLanguage={currentLanguage}/>} />
          <Route 
            path="/for-sale" 
            element={<ForSale 
            currentLanguage={currentLanguage} 
            setSelectedTypes={setSelectedTypes} 
            selectedTypes={selectedTypes} 
            loggedInUser={loggedInUser}
            isMenuOpen={isMenuOpen}
            objects= {objectsData}
            firebaseConfig = {firebaseConfig}
            />} />
          <Route 
            path="/settings" 
            element={<Settings 
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              />} 
          />         
          <Route 
            path="/my-favourites" 
            element={<MyFavourites 
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              objects= {objectsData}
              />} 
          />
          <Route 
            path="/saved-searches" 
            element={<SavedSearches 
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              firebaseConfig={firebaseConfig}
              />} 
          />
          <Route 
            path="/my-ads" 
            element={<MyAds 
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              />} 
          />
          <Route 
            path="/Ringelister" 
            element={<Ringelister
            firebaseConfig={firebaseConfig}
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              />} 
          />
          <Route 
            path="/NyAnnonse" 
            element={<NyAnnonse
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              firebaseConfig = {firebaseConfig}
              />} 
          />
          <Route 
            path="/Annonser" 
            element={<Annonser
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              objects= {objectsData}
              firebaseConfig = {firebaseConfig}
              />} 
          />
          <Route 
            path="/Arkiv" 
            element={<Arkiv
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              objects= {objectsData}
              />} 
          />
          <Route 
            path="/Bookinger" 
            element={<Bookinger
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              />} 
          />
          <Route 
            path="/qr-code" 
            element={<QrKode
              currentLanguage={currentLanguage} 
              accessToken={accessToken}
              loggedInUser= {loggedInUser}
              firebaseConfig = {firebaseConfig}
              />} 
          />
          {objectList.map((objectsData) => (
            <Route
              key={objectsData.ObjId}
              path={`/for-sale/${objectsData.ObjId}`}
              element={<ObjectDetails 
                object={objectsData}
                firebaseConfig = {firebaseConfig}
                currentLanguage={currentLanguage} 
              />}
            />
          ))} 
        </Routes>
        <Footer currentLanguage={currentLanguage}/>
      </div>
    </Router> 
  );
}

export default App;



