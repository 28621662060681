import React, { useState, useEffect } from 'react';
import './navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import globe_img from '../imgs/globe-icon.png';
import no_img from '../imgs/norsk.png';
import en_img from '../imgs/English.png';
import pl_img from '../imgs/Polish.jpeg';
import uk_img from '../imgs/Ukraine.png';
import gr_img from '../imgs/Germany.png';
import logo_img from '../imgs/Vamu-transparent-logo.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import translations from "./navbar_translations.js";

export default function Navbar({ currentLanguage,
                                 setCurrentLanguage, 
                                 accessToken, 
                                 setAccessToken,
                                 isMenuOpen,
                                 setIsMenuOpen,
                                 firebaseConfig
                                }) {
 
  const firebase = require('firebase/app');
  require('firebase/compat/auth'); // Import the Firebase Auth module
  firebase.initializeApp(firebaseConfig);

  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(" ");
  const [adminDisplay, setAdminDisplay] = useState("0");
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleLanguageChange = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage);
    setIsLanguageDropdownOpen(false);
  };

  const translateText = (textKey) => {
    // Get the translated text based on the current language
    return translations[currentLanguage][textKey];
  };

  useEffect(() => {
    // Load access token from localStorage
    const storedToken = localStorage.getItem('accessToken');
    const storedUser = localStorage.getItem("loggedInUser");
    const adminDisplay = localStorage.getItem("AdminDisplay");
    
    if (storedToken) {
      setAccessToken(storedToken);
      setLoggedInUser(storedUser);
      setAdminDisplay(adminDisplay);
    } else {
      setAccessToken('');
    }
  }, []);

  const handleLogout = () => {
    setIsMenuOpen(false);

    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('refreshToken');

    setAccessToken("");
    navigate('/');
    window.location.reload();
  };
  
  return (
    <nav>
      {/* Navbar logo */}
        <div className="logo-item-wrapper">
          <Link to="/">
            <img src={logo_img} className="logo-item" alt="Logo ikon" />
          </Link>
        </div>
      {/* Top level dropdown menu items */}
        {accessToken === "" ? (
        null
      ) : <p className="user-id-header">{loggedInUser}</p>}
      <ul className={`menu ${isMenuOpen ? 'showMenu' : ''}`}>
        <li>
          <Link className="menuItem" to="/" onClick={closeMenu}>
            {translateText('home')}
          </Link>
        </li>
        <li>
          <Link className="menuItem" to="/employees" onClick={closeMenu}>
            {translateText('ourEmployees')}
          </Link>
        </li>
        <li>
          <Link className="menuItem" to="/about" onClick={closeMenu}>
            {translateText('aboutUs')}
          </Link>
        </li>
        <li>
        {accessToken !=="" ? (
          <Link className="menuItem-login" to="/" onClick={handleLogout}>
            {translateText('logout')}
          </Link>
        ) : (
          <Link className="menuItem-login" to="/log-in" onClick={closeMenu}>
            {translateText('login')}
          </Link>
        )}
        </li>
        {/*Admin dropdown menu items*/}
        {adminDisplay == "2" && (
          <>
            <li>
                <h2 className="admin-menu-header">Admin Menu</h2>
            </li>
            <li>
            <Link className="admin-menuItem" to="/NyAnnonse" onClick={closeMenu}>
                Ny Annonse
              </Link>
            </li>
            <li>
            <Link className="admin-menuItem" to="/Annonser" onClick={closeMenu}>
                Annonser
              </Link>
            </li>
            <li>
            <Link className="admin-menuItem" to="/Ringelister" onClick={closeMenu}>
                Ringelister
              </Link>
            </li>
            <li>
            <Link className="admin-menuItem" to="/Bookinger" onClick={closeMenu}>
                Bookinger
              </Link>
            </li>
            <li>
              <Link className="admin-menuItem" to="/qr-code" onClick={closeMenu}>
                QR kode
              </Link>
            </li>
          </>
        )}
        {/* User dropdown menu items */}
        {(adminDisplay == "1") && (
          <>
            <li>
                <h2 className="admin-menu-header">User Menu</h2>
            </li>
            <li>
              <Link className="admin-menuItem" to="/settings" onClick={closeMenu}>
                {translateText('settings')}
              </Link>
            </li>
            <li>
              <Link className="admin-menuItem" to="/my-favourites" onClick={closeMenu}>
                {translateText('Favs')}
              </Link>
            </li>
            <li>
              <Link className="admin-menuItem" to="/saved-searches"onClick={closeMenu}>
                {translateText('Saves')}
              </Link>
            </li>
            <li>
              <Link className="admin-menuItem"  to="/my-ads" onClick={closeMenu}>
                {translateText('MyAds')}
              </Link>
            </li>
          </>
        )}
      </ul>

      {/* Menu hamburger */}
      <button className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <i className="material-icons menuIcon"></i>
        <i className="material-icons closeIcon"></i>
      </button>

      {/* Language selection */}
      <button className="language-btn" onClick={toggleLanguageDropdown}>
      <img src={no_img} className="flagg-icon-item"alt="Flagg ikon"/>
      <img src={globe_img} className="globe-icon-item"alt="Globus ikon"/>
      </button>

      <button className="language-btn" onClick={toggleLanguageDropdown}>
        {currentLanguage === 'no' ? (
          <img src={no_img} className="flagg-icon-item" alt="Flagg ikon" />
        ) : currentLanguage ==="gr" ? (
          <img src={gr_img} className="flagg-icon-item" alt="Flagg ikon"/>
        ) : currentLanguage === 'en' ? (
          <img src={en_img} className="flagg-icon-item" alt="Flagg ikon" />
        ) : currentLanguage === 'pl' ? (
          <img src={pl_img} className="flagg-icon-item" alt="Flagg ikon" />
        ) : currentLanguage === 'uk' ? (
          <img src={uk_img} className="flagg-icon-item" alt="Flagg ikon" />
        ) : null}
        <img src={globe_img} className="globe-icon-item" alt="Globus ikon" />
        </button>

        {isLanguageDropdownOpen && (
        <div className="language-dropdown">
            <button className="language-option" onClick={() => handleLanguageChange('no')}>
              <img src={no_img} className="flagg-icon-item" alt="Flagg ikon" />
              <p className="language-dropdown-text">Norsk</p>
            </button>
            <button className="language-option" onClick={() => handleLanguageChange('en')}>
              <img src={en_img} className="flagg-icon-item" alt="Flagg ikon" />
              <p className="language-dropdown-text">English</p>
            </button>
            <button className="language-option" onClick={() => handleLanguageChange('pl')}>
              <img src={pl_img} className="flagg-icon-item" alt="Flagg ikon" />
              <p className="language-dropdown-text">Polski</p>
            </button>
            <button className="language-option" onClick={() => handleLanguageChange('uk')}>
              <img src={uk_img} className="flagg-icon-item" alt="Flagg ikon" />
              <p className="language-dropdown-text">українська</p>
            </button>
            <button className="language-option" onClick={() => handleLanguageChange('gr')}>
              <img src={gr_img} className="flagg-icon-item" alt="Flagg ikon" />
              <p className="language-dropdown-text">Deutsch</p>
            </button>
        </div>
        )}
    </nav>
  );
}