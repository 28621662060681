import React from 'react';
import '../ForSale.css';

const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

export const TypeCheckbox = ({Category, currentLanguage, setSelectedTypes, selectedTypes, translateText, filteredObjects}) => {
    const count = filteredObjects.filter((obj) => obj.type === Category).length;

    return(
        <label>
            <input
              className="filter-checkbox"
              type="checkbox"
              checked={selectedTypes.includes(Category)}
              onChange={() => {
                if (selectedTypes.includes(Category)) {
                  setSelectedTypes(selectedTypes.filter((type) => type !== Category));
                } else {
                  setSelectedTypes([...selectedTypes, Category]);
                }
              }}
            />
            {translateText(capitalizeFirstLetter(Category), currentLanguage)} ({count})
          </label>
    );
};

export const AdditionalCheckboxes = ({ subTypeKey, labelKey, subType, handleAdditionalCheckboxChange, translateText, currentLanguage, filteredObjects, objects }) => {
  const count = filteredObjects.filter((obj) => obj.subType === subTypeKey).length;
  const countAll = objects.Objects.filter((obj) => obj.subType === subTypeKey).length;
  if (countAll === 0) {
    return null;
  }

  return (
    <label>
      <input
        type="checkbox"
        checked={subType.includes(subTypeKey)}
        onChange={() => handleAdditionalCheckboxChange(subTypeKey)}
      />
      {translateText(labelKey, currentLanguage)} ({countAll})
    </label>
  );
};

export const CountyCheckbox = ({selectedCounties, setSelectedCounties, county, filteredObjects}) => {
    const count = filteredObjects.filter((obj) => obj.county === county).length;

    if (count === 0) {
        return null;
    }

    return(
        <label>
            <input
                type="checkbox"
                checked={selectedCounties.includes(county)}
                onChange={() => {
                    if (selectedCounties.includes(county)) {
                    setSelectedCounties(selectedCounties.filter((type) => type !== county));
                    } else {
                    setSelectedCounties([...selectedCounties, county]);
                    }
                }}
            />
            {county} ({count})
        </label>
    );
};

export const BrandCheckbox = ({Brand, setSelectedBrands, selectedBrands, filteredObjects}) => {
    const count = filteredObjects.filter((obj) => obj.brand === Brand).length;

    if (count === 0) {
        return null;
    }

    return(
        <label>
            <input
                type="checkbox"
                checked={selectedBrands.includes(Brand)}
                onChange={() => {
                    if (selectedBrands.includes(Brand)) {
                      setSelectedBrands(selectedBrands.filter((type) => type !== Brand));
                    } else {
                      setSelectedBrands([...selectedBrands, Brand]);
                    }
                  }}
            />
            {Brand} ({count})
          </label>
    );
};


