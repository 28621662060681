const getWeeksSince = (createdDate) => {
    // Parse the date string in the format "dd.mm.yy"
    const dateParts = createdDate.split('.');
    if (dateParts.length !== 3) {
      return 'Invalid date';
    }
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months are 0-based
    const year = parseInt(dateParts[2]);

    // Convert the two-digit year to a four-digit year (assumed to be in the 20th century)
    const fullYear = 2000 + year;

    // Create Date objects for the current date and the created date
    const currentDate = new Date();
    const createdDateObj = new Date(fullYear, month, day);

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - createdDateObj;

    // Calculate the number of weeks
    const weeksDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
    return weeksDifference;
  };

  export default getWeeksSince;
  